import styled from "@emotion/styled";

const CenteredContainer = styled.main`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: auto auto 5rem;
`;

export default CenteredContainer;
