import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import IconButton from "./IconButton";

export interface CollapseToggleButtonProps {
  className?: string;
  isCollapsed: boolean;
  onClick: () => void;
}

const CollapseToggleButton: FC<CollapseToggleButtonProps> = ({
  className,
  isCollapsed,
  onClick,
}) => (
  <IconButton className={className} onClick={onClick} type="button">
    {isCollapsed ? (
      <FontAwesomeIcon icon={faChevronDown} />
    ) : (
      <FontAwesomeIcon icon={faChevronUp} />
    )}
  </IconButton>
);

export default CollapseToggleButton;
