import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { gql } from "apollo-boost";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import CSAButton from "../components/CSAButton";
import { CSALinkStyles } from "../components/CSALink";
import FormItem from "../components/FormItem";
import Notification, { NotificationProps } from "../components/Notification";
import { CurrentUser } from "../queries";

const GET_PRACTICE_PARTICIPANTS = gql`
  query GetPracticeParticipants {
    practiceParticipants {
      id
      email
      verified
    }
  }
`;

export interface GetPracticeParticipantsData {
  practiceParticipants?: {
    id?: string;
    email?: string;
    verified?: boolean;
  }[];
}

const ADD_PRACTICE_PARTICIPANT = gql`
  mutation AddParticipant($email: String!) {
    addPracticeParticipant(email: $email) {
      code
      message
    }
  }
`;

export interface AddPracticeParticipantVariables {
  email: string;
}

const DELETE_PRACTICE_PARTICIPANT = gql`
  mutation DeletePracticeParticipant($id: ID!) {
    deletePracticeParticipant(id: $id) {
      code
      message
    }
  }
`;

export interface DeletePracticeParticipantVariables {
  id: string;
}

const RESEND_INVITE = gql`
  mutation ResendInvite($id: ID!) {
    resendInvite(id: $id) {
      code
      message
    }
  }
`;

export interface ResendInviteVariables {
  id: string;
}

const H2 = styled.h2`
  font-size: 1rem;
`;

const Participant = styled.li<{ verified: boolean }>`
  background: var(--csa-blue-2);
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem;

  ${({ verified }) => !verified && "background: var(--csa-bg-secondary);"}
`;
const ParticipantControlsButton = styled(CSALinkStyles.withComponent("button"))`
  margin-left: 1rem;
`;

const CancelButton = styled(CSAButton)`
  margin-left: 0.5rem;
`;

const Hr = styled.hr`
  margin-bottom: 1rem;
`;

const Aside = styled.aside`
  margin-top: 1rem; ;
`;

const Form: FC<{
  onCancel: () => void;
  sendInvite: (params: { email: string }) => void;
}> = ({ onCancel, sendInvite }) => {
  const { errors, handleSubmit, register } = useForm<{ email: string }>();

  return (
    <form
      onSubmit={handleSubmit((values) => {
        sendInvite(values);
        onCancel();
      })}
    >
      <FormItem
        ref={register({ required: "Please enter an email" })}
        error={errors.email?.message?.toString()}
        label="Participant Email Address"
        name="email"
        placeholder="name@company.com"
        required
        type="email"
      />
      <CSAButton type="submit">Send Invite</CSAButton>
      <CancelButton onClick={onCancel} type="reset" variant="tertiary">
        Cancel
      </CancelButton>
    </form>
  );
};

export interface ParticipantsProps {
  currentUser?: CurrentUser;
}

const Participants: FC<ParticipantsProps> = ({ currentUser }) => {
  const [isInputting, setIsInputting] = useState(false);
  const [notification, setNotification] = useState<NotificationProps>({});

  const { data } = useQuery<GetPracticeParticipantsData>(
    GET_PRACTICE_PARTICIPANTS,
  );

  const [addParticipant] = useMutation<
    Response,
    AddPracticeParticipantVariables
  >(ADD_PRACTICE_PARTICIPANT, {
    onCompleted: () =>
      setNotification({
        children: <p>Invitation sent</p>,
        type: "success",
      }),
    onError: () => {
      setNotification({
        children: <p>Sorry something went wrong</p>,
        type: "error",
      });
    },
    refetchQueries: [{ query: GET_PRACTICE_PARTICIPANTS }],
  });

  const [deleteParticipant] = useMutation<
    Response,
    DeletePracticeParticipantVariables
  >(DELETE_PRACTICE_PARTICIPANT, {
    onCompleted: () =>
      setNotification({
        children: <p>Participant removed</p>,
        type: "success",
      }),
    onError: () => {
      setNotification({
        children: <p>Sorry something went wrong</p>,
        type: "error",
      });
    },
    refetchQueries: [{ query: GET_PRACTICE_PARTICIPANTS }],
  });

  const [resendInvite] = useMutation<Response, ResendInviteVariables>(
    RESEND_INVITE,
    {
      onCompleted: () =>
        setNotification({
          children: <p>Invitation sent</p>,
          type: "success",
        }),
      onError: () => {
        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        });
      },
    },
  );

  return (
    <>
      <Notification
        fadeOut={notification.fadeOut ?? true}
        float
        type={notification.type}
        width="100%"
      >
        {notification.children}
      </Notification>

      <section>
        <h1>Participants</h1>

        <ul>
          <H2>Verified</H2>
          {data?.practiceParticipants
            ?.filter(({ verified }) => verified)
            .map(({ id, email }) => (
              <Participant key={id} verified>
                {email}
                <ParticipantControlsButton
                  disabled={!currentUser?.active}
                  onClick={() =>
                    deleteParticipant({ variables: { id: id ?? "" } })
                  }
                  type="button"
                >
                  Remove
                </ParticipantControlsButton>
              </Participant>
            ))}
        </ul>

        <ul>
          <H2>Not Verified</H2>
          {data?.practiceParticipants
            ?.filter(({ verified }) => !verified)
            .map(({ id, email, verified }) => (
              <Participant key={id} verified={false}>
                {email}
                {!verified && (
                  <span>
                    <ParticipantControlsButton
                      disabled={!currentUser?.active}
                      onClick={() =>
                        resendInvite({ variables: { id: id ?? "" } })
                      }
                      type="button"
                    >
                      Resend Invite
                    </ParticipantControlsButton>
                    <ParticipantControlsButton
                      disabled={!currentUser?.active}
                      onClick={() =>
                        deleteParticipant({ variables: { id: id ?? "" } })
                      }
                      type="button"
                    >
                      Remove
                    </ParticipantControlsButton>
                  </span>
                )}
              </Participant>
            ))}
        </ul>

        <Hr />

        {isInputting ? (
          <Form
            onCancel={() => setIsInputting(false)}
            sendInvite={(variables) => addParticipant({ variables })}
          />
        ) : (
          <CSAButton
            disabled={!currentUser?.active}
            onClick={() => setIsInputting(true)}
            type="button"
          >
            Invite a Participant
          </CSAButton>
        )}

        <Aside>
          <small>
            Participants will have read and write access to the Dashboard except
            for&nbsp;
            <b>Practice Analytics</b>
            &nbsp;and&nbsp;
            <b>Marketing Budget</b>.
          </small>
        </Aside>
      </section>
    </>
  );
};

export default Participants;
