import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import FullCalendar, {
  DateSelectArg,
  EventApi,
  EventClickArg,
  EventSourceInput,
} from "@fullcalendar/react"; // must go before plugins
import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router";
import CSAButton from "../../components/CSAButton";
import Modal from "../../components/Modal";
import Notification, { NotificationProps } from "../../components/Notification";
import fetchApi from "../../fetchApi";
import { isGraphQLErrorCode } from "../../helpers/graphql";
import toFullCalendarEvent from "../../helpers/to-full-calendar-event";
import useCancelOnEsc from "../../hooks/use-cancel-on-esc";
import GoogleSignInButton from "../../images/google-signin-button.png";
import { GRAPHQL_API_URI } from "../../index";
import { CurrentUser } from "../../queries";
import CalendarEventsForm from "./CalendarEventsForm";
import ConfirmButton from "./ConfirmButton";
import {
  ADD_CALENDAR_EVENT,
  AddCalendarEventVariables,
  DELETE_CALENDAR_EVENT,
  DELETE_RECURRING_CALENDAR_EVENT,
  DeleteCalendarEventVariables,
  DeleteRecurringCalendarEventVariables,
  EDIT_CALENDAR_EVENT,
  EDIT_RECURRING_CALENDAR_EVENT,
  EditCalendarEventVariables,
  EditRecurringCalendarEventVariables,
  GENERATE_GOOGLE_AUTH_URL,
  GOOGLE_OAUTH,
  GOOGLE_SIGN_OUT,
  GenerateGoogleAuthUrlData,
  GetCalendarEvents,
  GoogleOAuthData,
} from "./types";
import "react-datepicker/dist/react-datepicker.css";

// eslint-disable-next-line import/order
import dayGridPlugin from "@fullcalendar/daygrid";
// eslint-disable-next-line import/order
import interactionPlugin from "@fullcalendar/interaction";
// eslint-disable-next-line import/order
import rrulePlugin from "@fullcalendar/rrule";
// eslint-disable-next-line import/order
import timeGridPlugin from "@fullcalendar/timegrid";

const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const H1 = styled.h1`
  font-size: 1.5rem;
  text-align: left;
`;

const Button = styled.button`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const SignOutButton = styled(CSAButton)`
  margin-left: 0.5rem;
`;

const ConfirmContainer = styled.div`
  text-align: center;
`;

export interface EventsCalendarProps {
  currentUser?: CurrentUser;
}

const EventsCalendar: FC<EventsCalendarProps> = ({ currentUser }) => {
  const history = useHistory();
  const location = useLocation<{ googleAuthorized?: boolean }>();

  const calendarRef: RefObject<FullCalendar> = useRef(null);
  const [notification, setNotification] = useState<NotificationProps>({});
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [confirmRecurringEdit, setConfirmRecurringEdit] = useState<
    ((updateOnlyThisEvent: boolean) => Promise<void>) | undefined
  >(undefined);

  const [controlledStartDate, setControlledStartDate] = useState(new Date());
  const [controlledEndDate, setControlledEndDate] = useState<Date | null>(null);
  const [controlledAllDay, setControlledAllDay] = useState(false);

  const [clickedEvent, setClickedEvent] = useState<EventApi | undefined>(
    undefined,
  );

  const { data: googleOAuthData, loading } =
    useQuery<GoogleOAuthData>(GOOGLE_OAUTH);

  const [generateGoogleAuthUrl] = useMutation<GenerateGoogleAuthUrlData>(
    GENERATE_GOOGLE_AUTH_URL,
    {
      onCompleted: (res) => window.open(res.generateGoogleAuthUrl.url, "_self"),
      onError: () =>
        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        }),
    },
  );

  const [googleSignOut] = useMutation(GOOGLE_SIGN_OUT, {
    onCompleted: () => {
      setNotification({
        children: <p>Successfully signed out</p>,
        type: "success",
      });
    },
    onError: () =>
      setNotification({
        children: <p>Sorry something went wrong</p>,
        type: "error",
      }),
    refetchQueries: [{ query: GOOGLE_OAUTH }],
  });

  const [addCalendarEvent] = useMutation<Response, AddCalendarEventVariables>(
    ADD_CALENDAR_EVENT,
    {
      onError: (error) => {
        if (isGraphQLErrorCode(error, "GOOGLE_API_ERROR")) {
          setNotification({
            children: (
              <p>
                Unable to sync with google calendar. Try refreshing calendar.
              </p>
            ),
            duration: 5000,
            type: "warning",
          });
          return;
        }

        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        });
      },
    },
  );

  const [editCalendarEvent] = useMutation<Response, EditCalendarEventVariables>(
    EDIT_CALENDAR_EVENT,
    {
      onError: (error) => {
        if (isGraphQLErrorCode(error, "GOOGLE_API_ERROR")) {
          setNotification({
            children: (
              <p>
                Unable to sync with google calendar. Try refreshing calendar.
              </p>
            ),
            duration: 5000,
            type: "warning",
          });
          return;
        }

        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        });
      },
    },
  );

  const [editRecurringCalendarEvent] = useMutation<
    Response,
    EditRecurringCalendarEventVariables
  >(EDIT_RECURRING_CALENDAR_EVENT, {
    onError: (error) => {
      if (isGraphQLErrorCode(error, "GOOGLE_API_ERROR")) {
        setNotification({
          children: (
            <p>Unable to sync with google calendar. Try refreshing calendar.</p>
          ),
          duration: 5000,
          type: "warning",
        });
        return;
      }

      setNotification({
        children: <p>Sorry something went wrong</p>,
        type: "error",
      });
    },
  });

  const [deleteCalendarEvent] = useMutation<
    Response,
    DeleteCalendarEventVariables
  >(DELETE_CALENDAR_EVENT, {
    onError: () =>
      setNotification({
        children: <p>Sorry something went wrong</p>,
        type: "error",
      }),
  });

  const [deleteRecurringCalendarEvent] = useMutation<
    Response,
    DeleteRecurringCalendarEventVariables
  >(DELETE_RECURRING_CALENDAR_EVENT, {
    onError: () =>
      setNotification({
        children: <p>Sorry something went wrong</p>,
        type: "error",
      }),
  });

  const clearForm = () => {
    setConfirmRecurringEdit(undefined);
    setIsAdding(false);
    setIsEditing(false);
    setClickedEvent(undefined);

    if (calendarRef.current) calendarRef.current.getApi().refetchEvents();
  };

  const onDateSelect = ({
    allDay: selectedAllDay,
    start,
    end,
    view,
  }: DateSelectArg) => {
    if (view.type === "dayGridMonth") {
      const fifteenMin = 15 * 60 * 1000;
      const roundedFifteenMin = new Date(
        Math.round(Date.now() / fifteenMin) * fifteenMin,
      );

      const adjustedStart = new Date(start.getTime());
      adjustedStart.setHours(roundedFifteenMin.getHours());
      adjustedStart.setMinutes(roundedFifteenMin.getMinutes());
      adjustedStart.setSeconds(roundedFifteenMin.getSeconds());

      setControlledStartDate(adjustedStart);
      setControlledEndDate(new Date(adjustedStart.getTime() + 60 * 60 * 1000));
      setControlledAllDay(false);
    } else {
      setControlledStartDate(start);
      setControlledEndDate(end);
      setControlledAllDay(selectedAllDay);
    }
    setIsAdding(true);
  };

  const onEventClick = (e: EventClickArg) => {
    if (!e.event.start || !currentUser?.active) return;

    setClickedEvent(e.event);

    if (
      e.event.extendedProps.repeatFreq !== "NONE" &&
      e.event.extendedProps.duration
    ) {
      setControlledStartDate(e.event.start);
      setControlledEndDate(
        new Date(e.event.start.getTime() + e.event.extendedProps.duration),
      );
    } else {
      setControlledStartDate(e.event.start);
      setControlledEndDate(e.event.end);
    }

    setControlledAllDay(e.event.allDay);
    setIsEditing(true);
  };

  const fetchEvents: EventSourceInput = useCallback(
    (info, successCallback, failureCallback) => {
      fetchApi(GRAPHQL_API_URI, {
        body: JSON.stringify({
          query: `
             query GetCalendarEvents {
               calendarEvents(start: "${info.startStr}", end: "${info.endStr}") {
                  id
                  allDay
                  details
                  duration
                  exdate
                  pointPerson
                  repeatFreq
                  repeatUntil
                  startDate
                  title
               }
             }`,
        }),
        method: "POST",
      })
        .then((res) => res.json())
        .then(({ data }: GetCalendarEvents) => {
          successCallback(data.calendarEvents.map(toFullCalendarEvent));
        })
        .catch(failureCallback);
    },
    [],
  );

  useCancelOnEsc({ onCancel: clearForm });

  useEffect(() => {
    if (location.state?.googleAuthorized) {
      setNotification({
        children: <p>Google calendar successfully synced</p>,
        type: "success",
      });

      // Clear location.state
      history.replace(location.pathname, {});
    }
  }, [location, history]);

  return (
    <>
      <Notification
        duration={notification.duration}
        fadeOut
        float
        type={notification.type}
        width="100%"
      >
        {notification.children}
      </Notification>

      <Header>
        <H1>Events Calendar</H1>
        {currentUser?.userType === "owner" && !loading && (
          <div>
            {googleOAuthData?.googleOAuth?.authorized ? (
              <>
                <CSAButton
                  disabled={!currentUser?.active}
                  onClick={async () => generateGoogleAuthUrl()}
                  type="button"
                >
                  Refresh Google Calendar
                </CSAButton>

                <SignOutButton
                  disabled={!currentUser?.active}
                  onClick={async () => googleSignOut()}
                  type="button"
                  variant="danger"
                >
                  Unsync Google
                </SignOutButton>
              </>
            ) : (
              <Button
                disabled={!currentUser?.active}
                onClick={async () => generateGoogleAuthUrl()}
                type="button"
              >
                <img alt="Sign in with Google" src={GoogleSignInButton} />
              </Button>
            )}
          </div>
        )}
      </Header>

      <FullCalendar
        ref={calendarRef}
        editable={currentUser?.active}
        eventChange={async (arg) => {
          if (!arg.event?.end || !arg.event?.start || !arg.oldEvent?.start)
            return;

          const variables = {
            allDay: arg.event.allDay,
            details: arg.event.extendedProps.details,
            duration: Math.abs(
              arg.event.end.getTime() - arg.event.start.getTime(),
            ),
            exdate: arg.event.extendedProps.exdateRef,
            pointPerson: arg.event.extendedProps.pointPerson,
            repeatFreq: arg.event.extendedProps.repeatFreq,
            repeatUntil: arg.event.extendedProps.repeatUntil,
            startDate: arg.event.start.toISOString(),
            title: arg.event.title,
          };

          if (arg.event.extendedProps.repeatFreq !== "NONE") {
            setConfirmRecurringEdit(
              () => async (updateOnlyThisEvent: boolean) => {
                try {
                  await editRecurringCalendarEvent({
                    variables: {
                      ...variables,
                      id: arg.event.id,
                      targetStartDate: arg.oldEvent.start?.toISOString() ?? "",
                      updateOnlyThisEvent,
                    },
                  });
                } finally {
                  clearForm();
                }
              },
            );
          } else if (arg.event.extendedProps.repeatFreq === "NONE") {
            try {
              await editCalendarEvent({
                variables: { ...variables, id: arg.event.id },
              });
            } finally {
              clearForm();
            }
          }
        }}
        eventClick={onEventClick}
        eventResizableFromStart
        events={fetchEvents}
        forceEventDuration
        headerToolbar={{
          center: "title",
          left: "prev,next today",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView="dayGridMonth"
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          rrulePlugin,
        ]}
        select={onDateSelect}
        selectable={currentUser?.active}
        selectMirror
      />

      <Modal onClose={clearForm} show={isAdding || isEditing}>
        <CalendarEventsForm
          clearForm={clearForm}
          clickedEvent={clickedEvent}
          controlledAllDay={controlledAllDay}
          controlledEndDate={controlledEndDate}
          controlledStartDate={controlledStartDate}
          isAdding={isAdding}
          isEditing={isEditing}
          onAdd={async (variables) => {
            try {
              await addCalendarEvent({ variables });
            } finally {
              clearForm();
            }
          }}
          onDelete={async (variables) => {
            await deleteCalendarEvent({ variables });
          }}
          onDeleteRecurringCalendarEvent={async (variables) => {
            await deleteRecurringCalendarEvent({
              variables,
            });
          }}
          onEdit={async (variables) => {
            try {
              await editCalendarEvent({ variables });
            } finally {
              clearForm();
            }
          }}
          onEditRecurringEvent={async (variables) => {
            setConfirmRecurringEdit(
              () => async (updateOnlyThisEvent: boolean) => {
                try {
                  await editRecurringCalendarEvent({
                    variables: { ...variables, updateOnlyThisEvent },
                  });
                } finally {
                  clearForm();
                }
              },
            );
          }}
          setControlledAllDay={setControlledAllDay}
          setControlledEndDate={setControlledEndDate}
          setControlledStartDate={setControlledStartDate}
        />
      </Modal>

      <Modal onClose={() => clearForm()} show={!!confirmRecurringEdit}>
        <ConfirmContainer>
          <h3>
            You&apos;re changing a repeating event. Do you want to change?
          </h3>

          <div>
            <ConfirmButton
              marginBottom="1rem"
              onClick={async () => {
                await confirmRecurringEdit?.(true);
                clearForm();
              }}
              type="button"
            >
              Only This Event
            </ConfirmButton>

            <ConfirmButton
              onClick={async () => {
                await confirmRecurringEdit?.(false);
                clearForm();
              }}
              type="button"
              variant="tertiary"
            >
              All Future Events
            </ConfirmButton>
          </div>
        </ConfirmContainer>
      </Modal>
    </>
  );
};

export default EventsCalendar;
