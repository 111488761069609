import { useQuery } from "@apollo/react-hooks";
import { ApolloError } from "apollo-boost";
import React, { FC } from "react";
import {
  Redirect,
  Route,
  RouteProps,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import LoggedInSidebar from "./layouts/LoggedInSidebar";
import About from "./pages/About";
import ActivateParticipant from "./pages/ActivateParticipant";
import AlliedHealthNetwork from "./pages/AlliedHealthNetwork";
import Analytics from "./pages/Analytics";
import Dashboard from "./pages/Dashboard";
import EditorialCalendar from "./pages/EditorialCalendar";
import EventsCalendar from "./pages/EventsCalendar";
import ForgotPassword from "./pages/ForgotPassword";
import GoogleOAuthCallback from "./pages/GoogleOAuthCallback";
import IndicatorScorecard from "./pages/IndicatorScorecard";
import Login from "./pages/Login";
import MCMRoadmapChecklists from "./pages/MCMRoadmapChecklists";
import MarketingBudget from "./pages/MarketingBudget";
import MarketingPlanner from "./pages/MarketingPlanner";
import MyNetwork from "./pages/MyNetwork";
import NewPatientLog from "./pages/NewPatientLog";
import Participants from "./pages/Participants";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ReferraralDirectory from "./pages/ReferralDirectory";
import ResetPassword from "./pages/ResetPassword";
import Resources from "./pages/Resources";
import Settings from "./pages/Settings";
import Signup from "./pages/Signup";
import VerifyEmail from "./pages/VerifyEmail";
import { CURRENT_USER, CurrentUserData } from "./queries";

const PrivateRoute: FC<
  RouteProps & {
    currentUser?: { active?: boolean; id?: number; userType?: string };
    error?: ApolloError;
    isOwnerOnly?: boolean;
    loading: boolean;
  }
> = ({ children, currentUser, error, isOwnerOnly, loading, ...props }) => {
  if (loading) {
    return null;
  }

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      render={({ location }): React.ReactNode => {
        if (isOwnerOnly && currentUser?.userType !== "owner") {
          return <Redirect to="/" />;
        }

        if (loading || currentUser?.id || error?.networkError) {
          return children;
        }

        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

const App: FC = () => {
  const { data, loading, error } = useQuery<CurrentUserData>(CURRENT_USER);

  return (
    <Router>
      <Switch>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>

        <Route path="/reset-password">
          <ResetPassword />
        </Route>

        <Route path="/activate-participant">
          <ActivateParticipant />
        </Route>

        <Route path="/verify-email">
          <VerifyEmail />
        </Route>

        <Route path="/login">
          <Login currentUser={data?.currentUser} />
        </Route>

        <Route path="/signup">
          <Signup />
        </Route>

        <Route path="/about">
          <About />
        </Route>

        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          loading={loading}
          path="/dashboard"
        >
          <LoggedInSidebar
            currentUser={data?.currentUser}
            isAdmin={data?.isAdmin}
          >
            <Dashboard currentUser={data?.currentUser} />
          </LoggedInSidebar>
        </PrivateRoute>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          isOwnerOnly
          loading={loading}
          path="/analytics"
        >
          <LoggedInSidebar
            currentUser={data?.currentUser}
            isAdmin={data?.isAdmin}
          >
            <Analytics currentUser={data?.currentUser} />
          </LoggedInSidebar>
        </PrivateRoute>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          isOwnerOnly
          loading={loading}
          path="/marketing-budget"
        >
          <LoggedInSidebar
            currentUser={data?.currentUser}
            isAdmin={data?.isAdmin}
          >
            <MarketingBudget currentUser={data?.currentUser} />
          </LoggedInSidebar>
        </PrivateRoute>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          loading={loading}
          path="/settings"
        >
          <LoggedInSidebar
            currentUser={data?.currentUser}
            isAdmin={data?.isAdmin}
          >
            <Settings currentUser={data?.currentUser} />
          </LoggedInSidebar>
        </PrivateRoute>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          isOwnerOnly
          loading={loading}
          path="/participants"
        >
          <LoggedInSidebar
            currentUser={data?.currentUser}
            isAdmin={data?.isAdmin}
          >
            <Participants currentUser={data?.currentUser} />
          </LoggedInSidebar>
        </PrivateRoute>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          loading={loading}
          path="/resources"
        >
          <LoggedInSidebar
            currentUser={data?.currentUser}
            isAdmin={data?.isAdmin}
          >
            <Resources />
          </LoggedInSidebar>
        </PrivateRoute>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          loading={loading}
          path="/marketing-planner"
        >
          <LoggedInSidebar
            currentUser={data?.currentUser}
            isAdmin={data?.isAdmin}
          >
            <MarketingPlanner currentUser={data?.currentUser} />
          </LoggedInSidebar>
        </PrivateRoute>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          loading={loading}
          path="/indicator-scorecard"
        >
          <LoggedInSidebar
            currentUser={data?.currentUser}
            isAdmin={data?.isAdmin}
          >
            <IndicatorScorecard currentUser={data?.currentUser} />
          </LoggedInSidebar>
        </PrivateRoute>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          loading={loading}
          path="/editorial-calendar"
        >
          <LoggedInSidebar
            currentUser={data?.currentUser}
            isAdmin={data?.isAdmin}
          >
            <EditorialCalendar currentUser={data?.currentUser} />
          </LoggedInSidebar>
        </PrivateRoute>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          loading={loading}
          path="/referral-directory"
        >
          <LoggedInSidebar
            currentUser={data?.currentUser}
            isAdmin={data?.isAdmin}
          >
            <ReferraralDirectory currentUser={data?.currentUser} />
          </LoggedInSidebar>
        </PrivateRoute>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          loading={loading}
          path="/new-patient-log"
        >
          <LoggedInSidebar
            currentUser={data?.currentUser}
            isAdmin={data?.isAdmin}
          >
            <NewPatientLog currentUser={data?.currentUser} />
          </LoggedInSidebar>
        </PrivateRoute>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          loading={loading}
          path="/mcm-roadmap-checklists"
        >
          <LoggedInSidebar
            currentUser={data?.currentUser}
            isAdmin={data?.isAdmin}
          >
            <MCMRoadmapChecklists currentUser={data?.currentUser} />
          </LoggedInSidebar>
        </PrivateRoute>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          isOwnerOnly
          loading={loading}
          path="/google-oauth2-callback"
        >
          <GoogleOAuthCallback />
        </PrivateRoute>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          loading={loading}
          path="/events-calendar"
        >
          <LoggedInSidebar
            currentUser={data?.currentUser}
            isAdmin={data?.isAdmin}
          >
            <EventsCalendar currentUser={data?.currentUser} />
          </LoggedInSidebar>
        </PrivateRoute>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          loading={loading}
          path="/my-network"
        >
          <LoggedInSidebar
            currentUser={data?.currentUser}
            isAdmin={data?.isAdmin}
          >
            <MyNetwork currentUser={data?.currentUser} />
          </LoggedInSidebar>
        </PrivateRoute>

        <PrivateRoute
          currentUser={data?.currentUser}
          error={error}
          loading={loading}
          path="/allied-health-network"
        >
          <LoggedInSidebar
            currentUser={data?.currentUser}
            isAdmin={data?.isAdmin}
          >
            <AlliedHealthNetwork currentUser={data?.currentUser} />
          </LoggedInSidebar>
        </PrivateRoute>

        <Redirect to="/login" />
      </Switch>
    </Router>
  );
};

export default App;
