import styled from "@emotion/styled";
import React, { FC } from "react";
import { CurrentUser } from "../../queries";

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export interface PracticeLogoProps {
  currentUser?: CurrentUser;
}

const PracticeLogo: FC<PracticeLogoProps> = ({ currentUser }) => (
  <Container>
    {currentUser?.logoUrl && (
      <img alt={currentUser?.practiceName} src={currentUser?.logoUrl} />
    )}
  </Container>
);

export default PracticeLogo;
