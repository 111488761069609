import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import CSAButton from "../components/CSAButton";
import CSALink from "../components/CSALink";
import CenteredContainer from "../components/CenteredContainer";
import FormItem from "../components/FormItem";
import HomeImageLink from "../components/HomeImageLink";
import PasswordHelper from "../components/PasswordHelper";
import { isGraphQLErrorCode } from "../helpers/graphql";
import { Response } from "../types";

const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!, $password: String!, $token: String!) {
    resetPassword(email: $email, password: $password, token: $token) {
      code
      message
    }
  }
`;

interface ResetPasswordData {
  resetPassword: Response;
}

interface ResetPasswordVariables {
  email: string;
  password: string;
  token: string;
}

const LinkButton = CSAButton.withComponent(CSALink);

const ExpiredBody: FC = () => (
  <CenteredContainer>
    <HomeImageLink />
    <h1>Sorry your request has expired</h1>
    <p>You can try again through the forgot password page</p>
    <LinkButton to="/forgot-password">Forgot Password</LinkButton>
  </CenteredContainer>
);

const FailureBody: FC = () => (
  <CenteredContainer>
    <HomeImageLink />
    <h1>Sorry we couldn&apos;t reset your password</h1>
    <p>Please try again later</p>
    <LinkButton to="/forgot-password">Forgot Password</LinkButton>
  </CenteredContainer>
);

const Submit = FormItem.withComponent(CSAButton);

interface FormValues {
  password: string;
  "password-confirmation": string;
}

const ResetPassword: FC = () => {
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const { errors, getValues, handleSubmit, register, watch } =
    useForm<FormValues>({
      mode: "onBlur",
    });
  const history = useHistory();
  const [resetPassword, { error }] = useMutation<
    ResetPasswordData,
    ResetPasswordVariables
  >(RESET_PASSWORD);

  const params = new URLSearchParams(useLocation().search);
  const email = decodeURIComponent(params.get("email") ?? "");
  const token = params.get("token") ?? "";

  const onSubmit = handleSubmit(async ({ password }) => {
    await resetPassword({ variables: { email, password, token } });

    history.push("/login", { passwordReset: true });
  });

  if (error) {
    if (isGraphQLErrorCode(error, "EXPIRED_TOKEN")) {
      return <ExpiredBody />;
    }

    return <FailureBody />;
  }

  return (
    <CenteredContainer>
      <HomeImageLink />
      <h1>Reset Password</h1>
      <form onSubmit={onSubmit}>
        <FormItem
          ref={register({
            required: "Please enter a password",
            validate: () =>
              isPasswordValid || "Password needs to match the rules below",
          })}
          error={errors.password?.message?.toString()}
          label="Password"
          maxLength={40}
          name="password"
          placeholder="********"
          required
          type="password"
        />

        <PasswordHelper
          password={watch("password")}
          setIsValid={setIsPasswordValid}
        />

        <FormItem
          ref={register({
            required: "Please confirm your password",
            validate: (v) =>
              getValues("password") === v || "Passwords need to match",
          })}
          error={errors["password-confirmation"]?.message?.toString()}
          label="Password Confirmation"
          maxLength={40}
          name="password-confirmation"
          placeholder="********"
          required
          type="password"
        />

        <Submit type="submit">Reset Password</Submit>
      </form>
    </CenteredContainer>
  );
};

export default ResetPassword;
