import formatMonth from "./format-month";

export interface Options {
  showDate?: boolean;
  showMonth?: boolean;
  showYear?: boolean;
}

export default (
  date: Date | undefined | null,
  { showDate, showMonth, showYear }: Options,
): string => {
  if (!date) {
    return "";
  }

  const formattedYear = date.getFullYear();
  const formattedMonth = formatMonth(date);
  const formattedDate =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  let final = "";

  if (showMonth) {
    final += formattedMonth;
  }

  if (showDate) {
    final += `-${formattedDate}`;
  }

  if (showYear) {
    final += `-${formattedYear}`;
  }

  return final;
};
