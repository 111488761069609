import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export interface CSALinkProps {
  disabled?: boolean;
  selected?: boolean;
}

const unselectedColor = "var(--csa-darkgrey)";
const unselectedOpacity = "0.5";

export const CSALinkStyles = styled.a<CSALinkProps>`
  appearance: none;
  background: none;
  border: none;
  color: ${unselectedColor};

  ${({ selected }) => selected && "color: var(--csa-blue);"}
  cursor: pointer;
  font-size: 1rem;
  outline: none;
  padding: 0;
  text-decoration: none;

  &:hover {
    color: var(--csa-blue);
    opacity: ${({ selected }) => (selected ? "" : unselectedOpacity)};
  }

  ${({ disabled }) =>
    disabled &&
    `
    font-style: italic;
    opacity: ${unselectedOpacity};

    &:hover {
      color: ${unselectedColor};
      cursor: default;
      opacity: ${unselectedOpacity};
    }
    `}
`;

export default CSALinkStyles.withComponent(Link);
