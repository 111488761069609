import { RefObject, useEffect } from "react";

export default ({
  onClick,
  ref,
}: {
  onClick: () => void;
  ref: RefObject<HTMLElement>;
}): void => {
  useEffect(() => {
    function closeOnOffClick(e: MouseEvent): void {
      if (e.target && !ref?.current?.contains(e.target as HTMLDivElement)) {
        onClick();
      }
    }

    document.addEventListener("mousedown", closeOnOffClick, false);

    return (): void => {
      document.removeEventListener("mousedown", closeOnOffClick, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
