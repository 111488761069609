import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import CSAButton from "../components/CSAButton";
import CSADescription from "../components/CSADescription";
import CSALink from "../components/CSALink";
import CenteredContainer from "../components/CenteredContainer";
import FormItem from "../components/FormItem";
import HomeImageLink from "../components/HomeImageLink";
import Notification, { NotificationProps } from "../components/Notification";
import { graphQLErrorsToParagraphs } from "../helpers/graphql";
import { CURRENT_USER, CurrentUser } from "../queries";
import { Response } from "../types";

const LOGIN = gql`
  mutation Login($email: String!, $ownerId: ID, $password: String!) {
    login(email: $email, ownerId: $ownerId, password: $password) {
      code
      message
    }
  }
`;

interface LoginData {
  login: Response;
}

interface LoginVariables {
  email: string;
  ownerId?: string;
  password: string;
}

const FormNotification = FormItem.withComponent(Notification);

const Submit = FormItem.withComponent(CSAButton);

export interface LoginProps {
  currentUser?: CurrentUser;
}

const Login: FC<LoginProps> = ({ currentUser }) => {
  const [notification, setNotification] = useState<NotificationProps>({});
  const history = useHistory();
  const location = useLocation<{
    loggedOut?: boolean;
    passwordReset?: boolean;
    verifyEmailFailure?: boolean;
    from?: { pathname?: string };
  }>();

  const from =
    location.state?.from && location.state?.from?.pathname !== "/login"
      ? location.state.from
      : { pathname: "/dashboard" };

  const [login] = useMutation<LoginData, LoginVariables>(LOGIN, {
    refetchQueries: [{ query: CURRENT_USER }],
  });

  const { register, handleSubmit, errors } = useForm<LoginVariables>({
    mode: "onBlur",
  });

  const onSubmit = handleSubmit(async (values) => {
    try {
      await login({
        variables: {
          ...values,
          ownerId: localStorage.getItem("last-practice-id")
            ? (localStorage.getItem("last-practice-id") as string)
            : undefined,
        },
      });

      history.push(from);
    } catch (e) {
      setNotification({
        children: <>{graphQLErrorsToParagraphs(e.graphQLErrors)}</>,
        type: "error",
      });
    }
  });

  useEffect(() => {
    if (currentUser?.id) {
      history.replace(from);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (location.state?.loggedOut) {
      setNotification({
        children: <p>Successfully logged out</p>,
        type: "success",
      });

      // Clear location.state
      history.replace(location.pathname, {});
    }

    if (location.state?.passwordReset) {
      setNotification({
        children: <p>Successfully updated password</p>,
        type: "success",
      });

      // Clear location.state
      history.replace(location.pathname, {});
    }

    if (location.state?.verifyEmailFailure) {
      setNotification({
        children: (
          <p>Sorry email couldn&apos;t be verified. Please try again.</p>
        ),
        type: "error",
      });

      // Clear location.state
      history.replace(location.pathname, {});
    }
  }, [location, history]);

  return (
    <CenteredContainer>
      <HomeImageLink />

      <FormNotification type={notification.type}>
        {notification.children}
      </FormNotification>

      <form onSubmit={onSubmit}>
        <FormItem
          ref={register({ required: "Please enter your email" })}
          error={errors?.email?.message?.toString()}
          label="Email Address"
          name="email"
          placeholder="name@company.com"
          required
          type="email"
        />
        <FormItem
          ref={register({ required: "Please enter your password" })}
          error={errors?.password?.message?.toString()}
          label="Password"
          maxLength={40}
          name="password"
          placeholder="Password"
          required
          type="password"
        />
        <Submit type="submit">Log In</Submit>
      </form>

      <CSALink to="/forgot-password">Forgot Password?</CSALink>

      <p>
        Don&apos;t have an account?&nbsp;
        <CSALink to="/signup">Sign up</CSALink>
      </p>

      <CSADescription />
      <CSALink to="/privacy-policy">Privacy Policy</CSALink>
    </CenteredContainer>
  );
};

export default Login;
