import styled from "@emotion/styled";
import {
  faEllipsisH,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Dispatch, FC, SetStateAction } from "react";
import IconButton from "./IconButton";

const Controls = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export interface EditControlsProps {
  adding: boolean;
  editing: boolean;
  setAdding: Dispatch<SetStateAction<boolean>>;
  setEditing: Dispatch<SetStateAction<boolean>>;
}

const EditControls: FC<EditControlsProps> = ({
  adding,
  editing,
  setAdding,
  setEditing,
}) => (
  <Controls>
    {!adding && (
      <IconButton
        onClick={() => {
          setAdding(!adding);
          setEditing(false);
        }}
        type="button"
      >
        <FontAwesomeIcon icon={faPlus} />
      </IconButton>
    )}
    <IconButton
      onClick={() => {
        setEditing(!editing);
        setAdding(false);
      }}
      type="button"
    >
      {editing ? (
        <FontAwesomeIcon icon={faTimes} />
      ) : (
        <FontAwesomeIcon icon={faEllipsisH} />
      )}
    </IconButton>
  </Controls>
);

export default EditControls;
