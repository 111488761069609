import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { gql } from "apollo-boost";
import merge from "lodash.merge";
import React, { FC, useEffect, useState } from "react";
import Notification, { NotificationProps } from "../../components/Notification";
import YearSelector from "../../components/YearSelector";
import YearlyMarketingExpenses from "../../components/YearlyMarketingExpenses";
import currencyFormatter from "../../helpers/currency-formatter";
import { isGraphQLErrorCode } from "../../helpers/graphql";
import stringToNumber from "../../helpers/string-to-number";
import useRecallLastView from "../../hooks/use-recall-last-view";
import { CurrentUser } from "../../queries";
import { Response } from "../../types";
import Table from "./Table";
import { GET_BUDGET, GetBudgetData, GetBudgetVariables } from "./graphql";

const Section = styled.section`
  position: relative;
`;

const Budget = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 2rem;

  @media (min-width: 768px) {
    align-items: flex-start;
    flex-flow: row;
    justify-content: space-between;
  }

  & > * {
    display: flex;
    flex-basis: 25%;
    flex-flow: column;
    min-height: 40rem;

    & > *:last-child {
      flex-grow: 1;
    }

    & > * {
      margin-bottom: 2rem;
    }
  }
`;

const FirstCol = styled.div`
  @media (min-width: 768px) {
    max-width: 24rem;
  }
`;

const MiddleCol = styled.div`
  flex-basis: 50%;

  @media (min-width: 768px) {
    margin: 0 2rem;
  }
`;

const BudgetYearSelector = styled(YearSelector)`
  position: absolute;
  right: 0;
  top: 0;
`;

interface UpdateDataVariables {
  data: UpdateData;
  year: number;
}

interface UpdateDataItem {
  amount: number;
  name: string;
}

interface UpdateDataMonth {
  id: number;
  project: string;
}

interface UpdateDataYearlyOneOffExpenseItem {
  amount: number;
  date: string;
  name: string;
}

interface UpdateData {
  fourWalls: {
    items: UpdateDataItem[];
  };
  marketingExpenses: {
    items: UpdateDataItem[];
  };
  marketingSinkingFund: {
    months: UpdateDataMonth[];
  };
  year: {
    budgeted: number;
  };
  yearlyOneOffExpenses: {
    items: UpdateDataYearlyOneOffExpenseItem[];
  };
}

const UPDATE_BUDGET = gql`
  mutation UpdateBudget($data: UpdateMarketingBudgetData!, $year: Int!) {
    updateMarketingBudget(data: $data, year: $year) {
      code
      message
    }
  }
`;

const toUpdateItem = ({
  amount,
  name,
}: {
  amount: number;
  name: string;
}): UpdateDataItem => ({
  amount,
  name,
});

const toUpdateMonth = ({
  id,
  project,
}: {
  id: number;
  project: string;
}): UpdateDataMonth => ({
  id,
  project,
});

const toUpdateYearlyOneOffExpense = ({
  amount,
  date,
  name,
}: {
  amount: number;
  date: string;
  name: string;
}): UpdateDataYearlyOneOffExpenseItem => ({
  amount,
  date,
  name,
});

const toMonth = (id: number) =>
  [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][id - 1];

export interface MarketingBudgetProps {
  currentUser?: CurrentUser;
}

const MarketingBudget: FC<MarketingBudgetProps> = ({ currentUser }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [notification, setNotification] = useState<NotificationProps>({});

  useRecallLastView("marketing-budget-view", { year }, { setYear });

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery<GetBudgetData, GetBudgetVariables>(GET_BUDGET, {
    variables: { year },
  });

  const [updateBudgetMutation, { loading: mutationLoading }] = useMutation<
    Response,
    UpdateDataVariables
  >(UPDATE_BUDGET);

  const loading = queryLoading || mutationLoading;

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  const updateBudget = async (newData: Partial<UpdateData>) => {
    const updateData = merge(
      {
        fourWalls: {
          items:
            newData?.fourWalls?.items ??
            data?.marketingBudget?.month?.items?.map(toUpdateItem),
        },
        marketingExpenses: {
          items:
            newData?.marketingExpenses?.items ??
            data?.marketingBudget?.marketingExpenses?.items?.map(toUpdateItem),
        },
        marketingSinkingFund: {
          months:
            newData?.marketingSinkingFund?.months ??
            data?.marketingBudget?.marketingSinkingFund?.months?.map(
              toUpdateMonth,
            ),
        },
        year: { budgeted: data?.marketingBudget?.year?.budgeted },
        yearlyOneOffExpenses: {
          items:
            newData?.yearlyOneOffExpenses?.items ??
            data?.marketingBudget?.yearlyOneOffExpenses?.items?.map(
              toUpdateYearlyOneOffExpense,
            ),
        },
      },
      newData,
    );

    try {
      await updateBudgetMutation({
        variables: { data: updateData, year },
      });
      refetch();
    } catch (e) {
      if (isGraphQLErrorCode(e, "BAD_USER_INPUT")) {
        setNotification({
          children: <p>Data is invalid</p>,
          type: "error",
        });
      } else {
        setNotification({
          children: <p>Please try again.</p>,
          type: "error",
        });
      }
    }
  };

  const fourWallsData =
    data?.marketingBudget?.month?.items?.map(toUpdateItem) ?? [];
  const yearlyOneOffExpensesData =
    data?.marketingBudget?.yearlyOneOffExpenses?.items?.map(
      toUpdateYearlyOneOffExpense,
    ) ?? [];
  const marketingSinkingFundData =
    data?.marketingBudget?.marketingSinkingFund?.months?.map(toUpdateMonth) ??
    [];
  const marketingExpensesData =
    data?.marketingBudget?.marketingExpenses?.items?.map(toUpdateItem) ?? [];

  return (
    <>
      <Notification fadeOut float type={notification.type} width="100%">
        {notification.children}
      </Notification>

      <Section>
        <h1>Marketing Budget</h1>

        <BudgetYearSelector
          availableYears={data?.marketingBudget?.availableYears ?? []}
          setYear={setYear}
          year={year}
        />

        <Budget>
          <FirstCol>
            <YearlyMarketingExpenses
              budgeted={currencyFormatter(
                data?.marketingBudget?.year?.budgeted,
                0,
              )}
              onBudgetedUpdate={(budgeted) =>
                updateBudget({ year: { budgeted } })
              }
              readOnly={currentUser && !currentUser.active}
              title="Yearly Marketing Expenses"
              total={currencyFormatter(data?.marketingBudget?.year?.total, 0)}
            />

            <Table
              caption="Four Walls"
              data={fourWallsData.map(({ amount, name }, index) => ({
                amount: {
                  editable: true,
                  value: currencyFormatter(amount, 0),
                },
                id: {
                  editable: false,
                  value: index.toString(),
                },
                name: {
                  editable: true,
                  value: name,
                },
              }))}
              headers={[
                { key: "name", name: "Items" },
                { key: "amount", name: "Amount" },
              ]}
              loading={loading}
              onAdd={({ amount, name }) => {
                updateBudget({
                  fourWalls: {
                    items: [
                      ...fourWallsData,
                      { amount: stringToNumber(amount) * 100 || 0, name },
                    ],
                  },
                });
              }}
              onDelete={(index) => {
                updateBudget({
                  fourWalls: {
                    items: [
                      ...fourWallsData.slice(0, index),
                      ...fourWallsData.slice(index + 1),
                    ],
                  },
                });
              }}
              onEdit={(index, key, value) => {
                let v;
                if (key === "amount") {
                  v = stringToNumber(value) * 100 || 0;
                } else {
                  v = value;
                }

                updateBudget({
                  fourWalls: {
                    items: [
                      ...fourWallsData.slice(0, index),
                      { ...fourWallsData[index], [key]: v },
                      ...fourWallsData.slice(index + 1),
                    ],
                  },
                });
              }}
              readOnly={!currentUser?.active}
              readOnlyAppendedData={[
                {
                  amount: currencyFormatter(
                    data?.marketingBudget?.month?.monthlyMarketingExpenses
                      ?.amount,
                    0,
                  ),
                  id: "monthly-marketing-expenses",
                  name:
                    data?.marketingBudget?.month?.monthlyMarketingExpenses
                      ?.name ?? "",
                },
              ]}
              total={currencyFormatter(
                data?.marketingBudget?.month?.total ?? 0,
                0,
              )}
              year={year}
            />
          </FirstCol>

          <MiddleCol>
            <Table
              caption="Yearly One-Off Expenses"
              data={yearlyOneOffExpensesData.map(
                ({ amount, date, name }, index) => ({
                  amount: {
                    editable: true,
                    value: currencyFormatter(amount, 0),
                  },
                  date: {
                    editable: true,
                    value: date,
                  },
                  id: {
                    editable: false,
                    value: index.toString(),
                  },
                  name: {
                    editable: true,
                    value: name,
                  },
                }),
              )}
              headers={[
                { key: "name", name: "Items" },
                { key: "amount", name: "Estimated Cost" },
                { key: "date", name: "Due Date" },
              ]}
              loading={loading}
              onAdd={({ amount, date, name }) => {
                updateBudget({
                  yearlyOneOffExpenses: {
                    items: [
                      ...yearlyOneOffExpensesData,
                      {
                        amount: stringToNumber(amount) * 100 || 0,
                        date,
                        name,
                      },
                    ],
                  },
                });
              }}
              onDelete={(index) => {
                updateBudget({
                  yearlyOneOffExpenses: {
                    items: [
                      ...yearlyOneOffExpensesData.slice(0, index),
                      ...yearlyOneOffExpensesData.slice(index + 1),
                    ],
                  },
                });
              }}
              onEdit={(index, key, value) => {
                let v;
                if (key === "amount") {
                  v = stringToNumber(value) * 100 || 0;
                } else {
                  v = value;
                }

                updateBudget({
                  yearlyOneOffExpenses: {
                    items: [
                      ...yearlyOneOffExpensesData.slice(0, index),
                      { ...yearlyOneOffExpensesData[index], [key]: v },
                      ...yearlyOneOffExpensesData.slice(index + 1),
                    ],
                  },
                });
              }}
              readOnly={!currentUser?.active}
              total={currencyFormatter(
                data?.marketingBudget?.yearlyOneOffExpenses?.total ?? 0,
                0,
              )}
              year={year}
            />

            <Table
              caption="Marketing Sinking Fund"
              data={marketingSinkingFundData.map(({ id, project }) => ({
                funds: {
                  editable: false,
                  value: currencyFormatter(
                    data?.marketingBudget?.marketingSinkingFund?.monthlyBudget,
                    0,
                  ),
                },
                id: {
                  editable: false,
                  value: id.toString(),
                },
                month: {
                  editable: false,
                  value: toMonth(id),
                },
                project: {
                  editable: true,
                  value: project,
                },
              }))}
              editable={false}
              headers={[
                { key: "month", name: "Month" },
                { key: "project", name: "Project" },
                { key: "funds", name: "Funds" },
              ]}
              loading={loading}
              onEdit={(index, key, value) => {
                updateBudget({
                  marketingSinkingFund: {
                    months: [
                      ...marketingSinkingFundData.slice(0, index),
                      { ...marketingSinkingFundData[index], [key]: value },
                      ...marketingSinkingFundData.slice(index + 1),
                    ],
                  },
                });
              }}
              readOnly={!currentUser?.active}
              year={year}
            />
          </MiddleCol>

          <div>
            <Table
              caption="Marketing Expenses"
              data={marketingExpensesData.map(({ amount, name }, index) => ({
                amount: {
                  editable: true,
                  value: currencyFormatter(amount, 0),
                },
                id: {
                  editable: false,
                  value: index.toString(),
                },
                name: {
                  editable: true,
                  value: name,
                },
              }))}
              headers={[
                { key: "name", name: "Items" },
                { key: "amount", name: "Amount" },
              ]}
              loading={loading}
              onAdd={({ amount, name }) => {
                updateBudget({
                  marketingExpenses: {
                    items: [
                      ...marketingExpensesData,
                      { amount: stringToNumber(amount) * 100 || 0, name },
                    ],
                  },
                });
              }}
              onDelete={(index) => {
                updateBudget({
                  marketingExpenses: {
                    items: [
                      ...marketingExpensesData.slice(0, index),
                      ...marketingExpensesData.slice(index + 1),
                    ],
                  },
                });
              }}
              onEdit={(index, key, value) => {
                let v;
                if (key === "amount") {
                  v = stringToNumber(value) * 100 || 0;
                } else {
                  v = value;
                }

                updateBudget({
                  marketingExpenses: {
                    items: [
                      ...marketingExpensesData.slice(0, index),
                      { ...marketingExpensesData[index], [key]: v },
                      ...marketingExpensesData.slice(index + 1),
                    ],
                  },
                });
              }}
              readOnly={!currentUser?.active}
              readOnlyAppendedData={[
                {
                  amount: currencyFormatter(
                    data?.marketingBudget?.marketingExpenses?.monthlySinkingFund
                      ?.amount,
                    0,
                  ),
                  id: "sinking-fund-monthly",
                  name:
                    data?.marketingBudget?.marketingExpenses?.monthlySinkingFund
                      ?.name ?? "",
                },
              ]}
              total={currencyFormatter(
                data?.marketingBudget?.marketingExpenses?.total,
                0,
              )}
              year={year}
            />
          </div>
        </Budget>
      </Section>
    </>
  );
};

export default MarketingBudget;
