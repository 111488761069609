import styled from "@emotion/styled";
import { IconDefinition, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, Suspense, lazy, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import CSAButton from "../../components/CSAButton";
import CopyToClipboard from "../../components/CopyToClipboard";
import Menu from "../../components/Menu";
import ModalFormInput from "../../components/ModalFormInput";
import ModalFormLabel from "../../components/ModalFormLabel";
import ModalFormTitleInput from "../../components/ModalFormTitleInput";
import StatusLabel from "../../components/StatusLabel";
import StatusOption from "../../components/StatusOption";
import StatusSelect from "../../components/StatusSelect";
import parseDate from "../../helpers/parse-date";
import { AddNewPatientVariables, NewPatient, NewPatientStatus } from ".";
import "react-datepicker/dist/react-datepicker.css";

const CreatableSelect = lazy(() => import("react-select/creatable"));

const Form = styled.form`
  font-size: 0.875rem;
  padding: 4rem;
  position: relative;

  @media (min-width: 576px) {
    min-width: 36rem;
  }
`;

const DatePickerFormLabel = styled(ModalFormLabel)`
  height: 1.5rem;
`;

const CheckboxFormLabel = styled(ModalFormLabel)`
  justify-items: start;
`;

const StatusIcon = styled(FontAwesomeIcon)<{
  icon: IconDefinition;
  status?: NewPatientStatus;
}>`
  color: ${({ status }) => {
    switch (status) {
      case "NONE":
        return "var(--csa-grey)";

      case "SUCCESS":
        return "var(--csa-green)";

      case "DID_NOT_FOLLOW_PLAN":
        return "var(--csa-red)";

      case "REFERRED_OUT":
        return "var(--csa-blue)";

      case "AS_NEEDED":
        return "var(--csa-orange)";

      default:
        return "var(--csa-grey)";
    }
  }};
`;

const Textarea = styled(ModalFormInput.withComponent("textarea"))`
  font-size: 0.75rem;
`;

const Submit = styled(CSAButton)`
  margin-bottom: 0.5rem;
  width: 8rem;
`;

export interface EditPatientFormProps {
  onDelete?: () => void;
  onSubmit: (variables: AddNewPatientVariables) => void;
  patient?: NewPatient;
  readOnly?: boolean;
  referralTypeOptions: string[];
}

const EditPatientForm: FC<EditPatientFormProps> = ({
  onDelete,
  onSubmit,
  patient,
  readOnly,
  referralTypeOptions,
}) => {
  const [date, setDate] = useState(patient?.date ?? new Date().toISOString());
  const [dateOfBirth, setDateOfBirth] = useState(patient?.dateOfBirth ?? "");
  const [referralType, setReferralType] = useState(
    patient?.referralType
      ? { label: patient.referralType, value: patient.referralType }
      : undefined,
  );

  const { errors, handleSubmit, register, watch } =
    useForm<AddNewPatientVariables>({
      defaultValues: patient,
    });

  return (
    <Form
      onSubmit={handleSubmit((variables) =>
        onSubmit({
          ...variables,
          date,
          dateOfBirth,
          referralType: referralType?.value ?? "",
        }),
      )}
    >
      <StatusLabel htmlFor="status">
        Status:&nbsp;
        <StatusIcon
          icon={faCircle}
          status={watch("status") as NewPatientStatus}
        />
        <StatusSelect ref={register} disabled={readOnly} name="status">
          <StatusOption value="NONE">None</StatusOption>
          <StatusOption value="SUCCESS">Success</StatusOption>
          <StatusOption value="DID_NOT_FOLLOW_PLAN">
            Did not follow treatment plan
          </StatusOption>
          <StatusOption value="REFERRED_OUT">Referred out</StatusOption>
          <StatusOption value="AS_NEEDED">As Needed</StatusOption>
        </StatusSelect>
      </StatusLabel>

      {!readOnly && onDelete && (
        <Menu ellipsisOrientation="horizontal" onDelete={onDelete} />
      )}

      <ModalFormTitleInput
        ref={register({ required: true })}
        error={!!errors.name}
        maxLength={80}
        name="name"
        placeholder="Name of Patient"
        readOnly={readOnly}
        type="text"
      />

      <DatePickerFormLabel htmlFor="date">
        Initial Visit Date
        <DatePicker
          customInput={<ModalFormInput error={false} />}
          dateFormat="MM-dd-yyyy"
          onChange={(d) => {
            if (d) setDate(d.toISOString());
          }}
          readOnly={readOnly}
          selected={parseDate(date)}
        />
      </DatePickerFormLabel>

      <ModalFormLabel htmlFor="referralType">
        Referral Type
        <Suspense fallback={<div />}>
          <CreatableSelect
            isDisabled={readOnly}
            onChange={(v) =>
              setReferralType(
                (v as { label: string; value: string }) ?? undefined,
              )
            }
            options={referralTypeOptions
              .filter((value) => !!value)
              .map((value) => ({ label: value, value }))}
            placeholder="Add Referral Type..."
            value={referralType}
          />
        </Suspense>
      </ModalFormLabel>

      <ModalFormLabel htmlFor="referralSource">
        Referral Source
        <ModalFormInput
          ref={register}
          error={!!errors.referralSource}
          maxLength={80}
          name="referralSource"
          placeholder="No Referral Source"
          readOnly={readOnly}
          type="text"
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="doctor">
        Treating Doctor
        <ModalFormInput
          ref={register}
          error={!!errors.doctor}
          maxLength={80}
          name="doctor"
          placeholder="No Treating Doctor"
          readOnly={readOnly}
          type="text"
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="specialNotes">
        Special Notes
        <Textarea
          ref={register}
          error={!!errors.specialNotes}
          maxLength={500}
          name="specialNotes"
          placeholder="No Special Notes"
          readOnly={readOnly}
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="patientEmail">
        Patient&apos;s Email
        <CopyToClipboard text={watch("patientEmail")}>
          <ModalFormInput
            ref={register}
            error={!!errors.patientEmail}
            name="patientEmail"
            placeholder="No Email"
            readOnly={readOnly}
            type="email"
          />
        </CopyToClipboard>
      </ModalFormLabel>

      <DatePickerFormLabel htmlFor="dateOfBirth">
        Date of Birth
        <DatePicker
          customInput={<ModalFormInput error={false} />}
          dateFormat="MM-dd-yyyy"
          isClearable
          onChange={(d) => {
            if (!d) setDateOfBirth("");
            else if (d) setDateOfBirth(d.toISOString());
          }}
          placeholderText="No Date of Birth"
          readOnly={readOnly}
          selected={parseDate(dateOfBirth)}
        />
      </DatePickerFormLabel>

      <CheckboxFormLabel htmlFor="welcomeLetterSent">
        Welcome Letter Sent?
        <ModalFormInput
          ref={register}
          disabled={readOnly}
          error={!!errors.welcomeLetterSent}
          name="welcomeLetterSent"
          type="checkbox"
        />
      </CheckboxFormLabel>

      <ModalFormLabel htmlFor="primaryDoctor">
        Primary Care Physician
        <ModalFormInput
          ref={register}
          error={!!errors.primaryDoctor}
          maxLength={80}
          name="primaryDoctor"
          placeholder="No Primary Doctor"
          readOnly={readOnly}
          type="text"
        />
      </ModalFormLabel>

      <CheckboxFormLabel htmlFor="primaryDoctorInitialLetterSent">
        PCP Initial Letter and initial notes sent
        <ModalFormInput
          ref={register}
          disabled={readOnly}
          error={!!errors.primaryDoctorInitialLetterSent}
          name="primaryDoctorInitialLetterSent"
          type="checkbox"
        />
      </CheckboxFormLabel>

      <CheckboxFormLabel htmlFor="referrerThankYouSent">
        Thank You Sent to Referer
        <ModalFormInput
          ref={register}
          disabled={readOnly}
          error={!!errors.referrerThankYouSent}
          name="referrerThankYouSent"
          type="checkbox"
        />
      </CheckboxFormLabel>

      <CheckboxFormLabel htmlFor="primaryDoctorFinalLetterSent">
        Final Letter Sent to PCP?
        <ModalFormInput
          ref={register}
          disabled={readOnly}
          error={!!errors.primaryDoctorFinalLetterSent}
          name="primaryDoctorFinalLetterSent"
          type="checkbox"
        />
      </CheckboxFormLabel>

      <CheckboxFormLabel htmlFor="googleReviewRequestSent">
        Google Review Request Sent?
        <ModalFormInput
          ref={register}
          disabled={readOnly}
          error={!!errors.googleReviewRequestSent}
          name="googleReviewRequestSent"
          type="checkbox"
        />
      </CheckboxFormLabel>

      {!readOnly && <Submit type="submit">Save</Submit>}
    </Form>
  );
};

export default EditPatientForm;
