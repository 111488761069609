import React, { Dispatch, FC, SetStateAction } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AddColumn from "./AddColumn";
import Column from "./Column";
import ContentContainer from "./ContentContainer";
import Task from "./Task";
import {
  AddTaskVariables,
  EditColumnTitleVariables,
  EditTaskVariables,
  GetMarketingYearPlanData,
  MoveTaskVariables,
} from "./graphql";

export interface YearViewProps {
  addColumn: (variables: { title: string }) => Promise<void>;
  addTask: (variables: AddTaskVariables) => Promise<void>;
  availableYears: number[];
  copyColumns: (variables: { copyYear: number }) => Promise<void>;
  data: GetMarketingYearPlanData;
  deleteColumn: (variables: { id: string }) => Promise<void>;
  deleteTask: (variables: { id: string }) => Promise<void>;
  editColumnTitle: (variables: EditColumnTitleVariables) => Promise<void>;
  editTask: (variables: EditTaskVariables) => Promise<void>;
  moveTask: (variables: MoveTaskVariables) => Promise<void>;
  readOnly?: boolean;
  setData: Dispatch<SetStateAction<GetMarketingYearPlanData>>;
  updateYearPlan: (yearPlan: { columnOrder: string[] }) => Promise<void>;
}

const YearView: FC<YearViewProps> = ({
  addColumn,
  addTask,
  availableYears,
  copyColumns,
  data,
  deleteColumn,
  deleteTask,
  editColumnTitle,
  editTask,
  moveTask,
  readOnly,
  setData,
  updateYearPlan,
}) => (
  <DragDropContext
    onDragEnd={async ({ destination, source, draggableId, type }) => {
      if (!destination) {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      if (type === "column") {
        const newColOrder = Array.from(
          data?.marketingYearPlan?.columnOrder ?? [],
        );
        newColOrder.splice(source.index, 1);
        newColOrder.splice(destination.index, 0, draggableId);
        setData({
          ...data,
          marketingYearPlan: {
            ...data.marketingYearPlan,
            columnOrder: newColOrder,
          },
        });

        await updateYearPlan({ columnOrder: newColOrder });

        return;
      }

      const taskDraggableIdParts = draggableId.split("-");
      const taskDraggableId = taskDraggableIdParts
        .slice(0, taskDraggableIdParts.length - 1)
        .join("-");

      const start = (data?.marketingYearPlan?.columns ?? {})[
        source.droppableId
      ];
      const finish = (data?.marketingYearPlan?.columns ?? {})[
        destination.droppableId
      ];

      if (start && finish && start === finish && finish.id) {
        const newTaskOrder = Array.from(start?.taskOrder ?? []);
        newTaskOrder.splice(source.index, 1);
        newTaskOrder.splice(destination.index, 0, taskDraggableId);

        setData({
          ...data,
          marketingYearPlan: {
            ...data.marketingYearPlan,
            columns: {
              ...data?.marketingYearPlan?.columns,
              [finish.id]: { ...finish, taskOrder: newTaskOrder },
            },
          },
        });
      } else if (start.id && finish.id) {
        const newStartTaskOrder = Array.from(start?.taskOrder ?? []);
        newStartTaskOrder.splice(source.index, 1);

        const newFinishTaskOrder = Array.from(finish?.taskOrder ?? []);
        newFinishTaskOrder.splice(destination.index, 0, taskDraggableId);
        setData({
          ...data,
          marketingYearPlan: {
            ...data.marketingYearPlan,
            columns: {
              ...data.marketingYearPlan?.columns,
              [start.id]: { ...start, taskOrder: newStartTaskOrder },
              [finish.id]: { ...finish, taskOrder: newFinishTaskOrder },
            },
          },
        });
      }

      await moveTask({
        destination: {
          colId: destination.droppableId,
          index: destination.index,
        },
        id: taskDraggableId,
        source: {
          colId: source.droppableId,
          index: source.index,
        },
      });
    }}
  >
    <Droppable direction="horizontal" droppableId="columns" type="column">
      {({ droppableProps, innerRef, placeholder }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ContentContainer ref={innerRef} {...droppableProps}>
          {data?.marketingYearPlan?.columnOrder?.map((colId, colIndex) => (
            <Column
              key={colId}
              canDelete={
                (data?.marketingYearPlan?.columnOrder?.length ?? 0) > 1
              }
              editable
              id={colId}
              index={colIndex}
              onAdd={(task) => addTask({ ...task, colId })}
              onDeleteColumn={() => deleteColumn({ id: colId })}
              onEditColumnTitle={(title) =>
                editColumnTitle({ id: colId, title })
              }
              readOnly={readOnly}
              title={
                (data?.marketingYearPlan?.columns ?? {})[colId].title ?? ""
              }
            >
              {(data?.marketingYearPlan?.columns ?? {})[colId].taskOrder?.map(
                (taskId, taskIndex) => (
                  <Task
                    key={taskId}
                    description={
                      (data?.marketingYearPlan?.tasks ?? {})[taskId]
                        ?.description ?? ""
                    }
                    dueDate={
                      (data?.marketingYearPlan?.tasks ?? {})[taskId]?.dueDate ??
                      ""
                    }
                    id={taskId}
                    index={taskIndex}
                    onDelete={deleteTask}
                    onEdit={editTask}
                    readOnly={readOnly}
                    repeat={
                      (data?.marketingYearPlan?.tasks ?? {})[taskId]?.repeat ??
                      "NONE"
                    }
                    status={
                      (data?.marketingYearPlan?.tasks ?? {})[taskId]?.status ??
                      "NEW"
                    }
                    title={
                      (data?.marketingYearPlan?.tasks ?? {})[taskId]?.title ??
                      ""
                    }
                  />
                ),
              )}
            </Column>
          ))}
          {placeholder}
          <AddColumn
            availableYears={availableYears}
            onAdd={(title) => addColumn({ title })}
            onCopyYear={async (copyYear) => copyColumns({ copyYear })}
            readOnly={readOnly}
          />
        </ContentContainer>
      )}
    </Droppable>
  </DragDropContext>
);

export default YearView;
