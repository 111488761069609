import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gql } from "apollo-boost";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import CSAButton from "../components/CSAButton";
import CSAInput, { Label } from "../components/CSAInput";
import DeleteButton from "../components/DeleteButton";
import EditControls from "../components/EditControls";
import Modal from "../components/Modal";
import { Response } from "../types";

const GET_RESOURCES = gql`
  query GetResources {
    isAdmin
    resources {
      videos {
        embeded
        id
        title
      }
    }
  }
`;

interface GetResourcesData {
  isAdmin?: boolean;
  resources?: {
    videos?: Array<{
      embeded: string;
      id: string;
      title: string;
    }>;
  };
}

const UPDATE_RESOURCES = gql`
  mutation UpdateResources($resources: JSON!) {
    updateResources(resources: $resources) {
      code
      message
    }
  }
`;

interface UpdateResourcesVariables {
  resources: {
    videos: Array<{
      embeded: string;
      id: string;
      title: string;
    }>;
  };
}

const Section = styled.section`
  position: relative;
`;

const List = styled.ul`
  display: grid;
  grid-gap: 1.5rem;

  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Li = styled.li`
  align-items: center;
  align-self: start;
  display: flex;
  justify-content: space-between;
`;

const Card = styled.div`
  flex: 1 0 auto;
  max-width: 100%;
  position: relative;
`;

const MoveResource = styled(FontAwesomeIcon)`
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

const DeleteResource = styled(DeleteButton)`
  left: 0;
  top: 0.5rem;
  z-index: 1;
`;

const Video = styled.div`
  margin-bottom: 0.5rem;
`;

const Form = styled.form`
  padding: 3rem;
  width: 25rem;
`;

const Input = styled(CSAInput)`
  margin-bottom: 1rem;
`;

const TextArea = styled.textarea`
  display: block;
  height: 10rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  width: 100%;
`;

const Resources: FC = () => {
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(false);
  const { errors, handleSubmit, register } = useForm<{
    embeded: string;
    title: string;
  }>();

  const { data, refetch } = useQuery<GetResourcesData>(GET_RESOURCES);
  const [updateResources] = useMutation<Response, UpdateResourcesVariables>(
    UPDATE_RESOURCES,
  );

  const deleteVideo = async (id: string) => {
    if (data?.resources?.videos?.length) {
      const videos = data?.resources?.videos?.filter(
        ({ id: videoId }) => videoId !== id,
      );

      await updateResources({
        variables: {
          resources: {
            ...data?.resources,
            videos: videos ?? [],
          },
        },
      });
      refetch();
    }
  };

  const moveVideo = async (index: number, direction: "left" | "right") => {
    if (data?.resources?.videos?.length) {
      const newVideos = Array.from(data.resources.videos);
      let newIndex = direction === "left" ? index - 1 : index + 1;
      if (newIndex < 0) {
        newIndex = 0;
      } else if (newIndex > newVideos.length - 1) {
        newIndex = newVideos.length - 1;
      }

      const [video] = newVideos.splice(index, 1);
      newVideos.splice(newIndex, 0, video);

      await updateResources({
        variables: {
          resources: {
            ...data?.resources,
            videos: newVideos,
          },
        },
      });
      refetch();
    }
  };

  return (
    <>
      <Section>
        <h1>Resources</h1>

        {data?.isAdmin && (
          <EditControls
            adding={adding}
            editing={editing}
            setAdding={setAdding}
            setEditing={setEditing}
          />
        )}

        <hr />

        <List>
          {data?.resources?.videos?.map(({ embeded, id, title }, index) => (
            <Li key={id}>
              {editing && (
                <MoveResource
                  icon={faChevronLeft}
                  onClick={() => moveVideo(index, "left")}
                />
              )}
              <Card>
                {editing && <DeleteResource onClick={() => deleteVideo(id)} />}
                <Video
                  dangerouslySetInnerHTML={{
                    __html: embeded,
                  }}
                />
                {title}
              </Card>
              {editing && (
                <MoveResource
                  icon={faChevronRight}
                  onClick={() => moveVideo(index, "right")}
                />
              )}
            </Li>
          ))}
        </List>
      </Section>

      <Modal onClose={() => setAdding(false)} show={adding}>
        <Form
          onSubmit={handleSubmit(async ({ embeded, title }) => {
            try {
              if (embeded && title) {
                await updateResources({
                  variables: {
                    resources: {
                      ...data?.resources,
                      videos: [
                        ...(data?.resources?.videos ?? []),
                        {
                          embeded: embeded ?? "",
                          id: `${Date.now()}`,
                          title: title ?? "",
                        },
                      ],
                    },
                  },
                });
                refetch();
              }
            } finally {
              setAdding(false);
            }
          })}
        >
          <h2>Add Resource</h2>
          <Input
            ref={register({ required: true })}
            error={!!errors.title}
            label="Title"
            name="title"
            placeholder="Title"
            required
            type="text"
          />

          <Label>
            Embeded
            <TextArea
              ref={register({ required: true })}
              name="embeded"
              required
            />
          </Label>

          <CSAButton type="submit">Upload</CSAButton>
        </Form>
      </Modal>
    </>
  );
};

export default Resources;
