import styled from "@emotion/styled";
import React, { FC } from "react";
import { Label } from "../../components/CSAInput";

const Container = styled.div`
  width: 25rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
`;

const P = styled.p`
  margin: 0;
`;

const Button = styled.button`
  background: none;
  border: 0;
  color: var(--csa-blue);
  font-size: 0.75rem;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`;

export interface ChangePasswordFormItemProps {
  disabled?: boolean;
  onClick: () => void;
}

const ChangePasswordFormItem: FC<ChangePasswordFormItemProps> = ({
  disabled,
  onClick,
}) => (
  <Label>
    Password
    <Container>
      <P>Change the password for your account</P>
      <Button disabled={disabled} onClick={onClick} type="button">
        Change Password
      </Button>
    </Container>
  </Label>
);

export default ChangePasswordFormItem;
