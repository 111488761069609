import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import React, { FC, useEffect, useState } from "react";
import Notification, { NotificationProps } from "../../components/Notification";
import YearSelector from "../../components/YearSelector";
import useRecallLastView from "../../hooks/use-recall-last-view";
import { CurrentUser } from "../../queries";
import Nav, { Views } from "./Nav";
import QuarterView from "./QuarterView";
import YearView from "./YearView";
import {
  ADD_COLUMN,
  ADD_TASK,
  AddColumnVariables,
  AddTaskVariables,
  COPY_COLUMNS,
  CopyColumnsVariables,
  DELETE_COLUMN,
  DELETE_TASK,
  DeleteColumnVariables,
  DeleteTaskVariables,
  EDIT_COLUMN_TITLE,
  EDIT_TASK,
  EditColumnTitleVariables,
  EditTaskVariables,
  GET_MARKETING_YEAR_PLAN,
  GetMarketingYearPlanData,
  GetMarketingYearPlanVariables,
  MOVE_TASK,
  MoveTaskVariables,
  UPDATE_YEAR_PLAN,
  UpdateYearPlanVariables,
} from "./graphql";

const Section = styled.section`
  position: relative;
`;

const NavContainer = styled.div`
  position: absolute;
  align-items: start;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const PlannerYearSelector = styled(YearSelector)`
  margin-left: 5rem;
`;

const PlannerNav = styled(Nav)`
  flex-grow: 1;
`;

const emptyMonths = {
  1: {
    tasks: [],
  },
  2: {
    tasks: [],
  },
  3: {
    tasks: [],
  },
  4: {
    tasks: [],
  },
  5: {
    tasks: [],
  },
  6: {
    tasks: [],
  },
  7: {
    tasks: [],
  },
  8: {
    tasks: [],
  },
  9: {
    tasks: [],
  },
  10: {
    tasks: [],
  },
  11: {
    tasks: [],
  },
  12: {
    tasks: [],
  },
};

export interface LocalMarketingYearPlan extends GetMarketingYearPlanData {
  yearPlanOrder?: string[];
}

export interface MarketingPlannerProps {
  currentUser?: CurrentUser;
}

const MarketingPlanner: FC<MarketingPlannerProps> = ({ currentUser }) => {
  const [view, setView] = useState<Views>("year");
  const [year, setYear] = useState(new Date().getFullYear());
  const [data, setData] = useState<LocalMarketingYearPlan>({});
  const [notification, setNotification] = useState<NotificationProps>({});

  useRecallLastView("marketing-planner-view", { year }, { setYear });

  const {
    data: remoteData,
    error: queryError,
    refetch,
  } = useQuery<GetMarketingYearPlanData, GetMarketingYearPlanVariables>(
    GET_MARKETING_YEAR_PLAN,
    { fetchPolicy: "cache-and-network", variables: { year } },
  );

  const [addColumn, { error: addColumnError }] = useMutation<
    Response,
    AddColumnVariables
  >(ADD_COLUMN);
  const [copyColumns, { error: copyColumnsError }] = useMutation<
    Response,
    CopyColumnsVariables
  >(COPY_COLUMNS);
  const [deleteColumn, { error: deleteColumnError }] = useMutation<
    Response,
    DeleteColumnVariables
  >(DELETE_COLUMN);
  const [editColumnTitle, { error: editColumnTitleError }] =
    useMutation<EditColumnTitleVariables>(EDIT_COLUMN_TITLE);
  const [addTask, { error: addTaskError }] = useMutation<
    Response,
    AddTaskVariables
  >(ADD_TASK);
  const [editTask, { error: editTaskError }] = useMutation<
    Response,
    EditTaskVariables
  >(EDIT_TASK);
  const [moveTask, { error: moveTaskError }] = useMutation<
    Response,
    MoveTaskVariables
  >(MOVE_TASK);
  const [deleteTask, { error: deleteTaskError }] = useMutation<
    Response,
    DeleteTaskVariables
  >(DELETE_TASK);
  const [updateYearPlan, { error: updateYearPlanError }] = useMutation<
    Response,
    UpdateYearPlanVariables
  >(UPDATE_YEAR_PLAN);

  const error =
    queryError ||
    addColumnError ||
    copyColumnsError ||
    deleteColumnError ||
    editColumnTitleError ||
    addTaskError ||
    editTaskError ||
    moveTaskError ||
    deleteTaskError ||
    updateYearPlanError;

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  useEffect(() => {
    if (error) {
      setNotification({
        children: <p>Sorry something went wrong. Please try again later.</p>,
        type: "error",
      });
    }

    const yearPlanOrder = Object.keys(
      remoteData?.marketingYearPlan?.tasks ?? {},
    );

    setData({
      ...remoteData,
      yearPlanOrder,
    });
  }, [error, remoteData]);

  return (
    <>
      <Notification fadeOut float type={notification.type} width="100%">
        {notification.children}
      </Notification>
      <Section>
        <h1>Marketing Planner</h1>

        <NavContainer>
          <PlannerNav setView={setView} view={view} />

          <PlannerYearSelector
            availableYears={remoteData?.marketingYearPlan?.availableYears ?? []}
            setYear={setYear}
            year={year}
          />
        </NavContainer>

        {view === "year" ? (
          <YearView
            addColumn={async ({ title }) => {
              await addColumn({ variables: { title, year } });
              refetch();
            }}
            addTask={async (variables) => {
              await addTask({ variables });
              setNotification({
                children: <p>New Task added</p>,
                type: "success",
              });
              refetch();
            }}
            availableYears={
              remoteData?.marketingYearPlan?.availableYears?.filter(
                (y) => y !== year,
              ) ?? []
            }
            copyColumns={async ({ copyYear }) => {
              await copyColumns({ variables: { copyYear, year } });
              setNotification({
                children: <p>Columns copied</p>,
                type: "success",
              });
              refetch();
            }}
            data={data}
            deleteColumn={async (variables) => {
              await deleteColumn({ variables: { ...variables, year } });
              setNotification({
                children: <p>Successfully deleted</p>,
                type: "success",
              });
              refetch();
            }}
            deleteTask={async (variables) => {
              await deleteTask({ variables: { ...variables, year } });
              setNotification({
                children: <p>Task deleted</p>,
                type: "success",
              });
              refetch();
            }}
            editColumnTitle={async (variables) => {
              await editColumnTitle({ variables });
              setNotification({
                children: <p>Updated successfully</p>,
                type: "success",
              });
              refetch();
            }}
            editTask={async (variables) => {
              await editTask({ variables });
              setNotification({
                children: <p>Task updated successfully</p>,
                type: "success",
              });
              refetch();
            }}
            moveTask={async ({ destination, id, source }) => {
              await moveTask({ variables: { destination, id, source } });
              refetch();
            }}
            readOnly={!currentUser?.active}
            setData={setData}
            updateYearPlan={async ({ columnOrder }) => {
              await updateYearPlan({
                variables: {
                  columnOrder,
                  months: data.marketingYearPlan?.months ?? emptyMonths,
                  year,
                },
              });
              refetch();
            }}
          />
        ) : (
          <QuarterView
            data={data}
            deleteTask={async (variables) => {
              await deleteTask({ variables: { ...variables, year } });
              setNotification({
                children: <p>Task deleted</p>,
                type: "success",
              });
              refetch();
            }}
            editTask={async (variables) => {
              await editTask({ variables });
              setNotification({
                children: <p>Task updated successfully</p>,
                type: "success",
              });
              refetch();
            }}
            readOnly={!currentUser?.active}
            setData={setData}
            setNotification={setNotification}
            updateYearPlan={async ({ months }) => {
              await updateYearPlan({
                variables: {
                  columnOrder: data.marketingYearPlan?.columnOrder ?? [],
                  months,
                  year,
                },
              });
              refetch();
            }}
            view={view}
          />
        )}
      </Section>
    </>
  );
};

export default MarketingPlanner;
