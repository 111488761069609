import styled from "@emotion/styled";
import React, { FC, useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import CollapseToggleButton from "../../components/CollapseToggleButton";
import Modal from "../../components/Modal";
import formatDate from "../../helpers/format-date";
import parseDate from "../../helpers/parse-date";
import EditContentForm from "./EditContentForm";
import { EditEditorialContentVariables } from "./queries";
import { Content as ContentType } from "./types";

const LOCAL_STORAGE_KEY = "editorial-content-collapse-state";

const Container = styled.li<{ color?: string }>`
  background-color: ${({ color }) => color};
  border-radius: 0.25rem;
  border: 0.0625rem solid var(--csa-border);
  margin-bottom: 0.5rem;
  padding: 1rem;
  position: relative;
`;

const Heading = styled.h3`
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  margin: 0;
  padding-right: 4rem;
`;

const EditButton = styled.button`
  apperance: none;
  background: none;
  border: 0;
  color: var(--csa-blue);
  font-size: 0.875rem;
  margin-top: 0.875rem;
  padding: 0.25rem;
  position: absolute;
  right: 2rem;
  top: 0;
`;

const CollapseButton = styled(CollapseToggleButton)`
  color: var(--csa-border);
  font-size: 0.875rem;
  margin-top: 0.875rem;
  padding: 0.25rem;
  position: absolute;
  right: 0.5rem;
  top: 0;
`;

const List = styled.dl<{ show: boolean }>`
  display: ${({ show }) => (show ? "grid" : "none")};
  font-size: 0.8125rem;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const DefintionContainer = styled.div<{ notes?: boolean }>`
  ${({ notes }) => notes && "grid-column: span 4"}
`;

const Term = styled.dt`
  font-weight: bold;
`;

const Def = styled.dd`
  margin: 0;
`;

export interface ContentProps {
  content: ContentType;
  index: number;
  onDelete: (variables: { id: string }) => void;
  onEdit: (variables: EditEditorialContentVariables) => Promise<void>;
  readOnly?: boolean;
}

const Content: FC<ContentProps> = ({
  content,
  index,
  onDelete,
  onEdit,
  readOnly,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (content.id) {
      const state = localStorage.getItem(LOCAL_STORAGE_KEY)
        ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? "")
        : {};

      if (state?.[content.id]) {
        setIsCollapsed(state[content.id]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (content.id) {
      const state = localStorage.getItem(LOCAL_STORAGE_KEY)
        ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? "")
        : {};

      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({ ...state, [content.id]: isCollapsed }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed]);

  return (
    <Draggable
      draggableId={content.id ?? ""}
      index={index}
      isDragDisabled={readOnly}
    >
      {({ dragHandleProps, draggableProps, innerRef }) => (
        <>
          <Container
            ref={innerRef}
            color={content.color}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...dragHandleProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...draggableProps}
          >
            <Heading>
              {content.headline}
              <span>
                Content Type:&nbsp;
                {content.type}
                &nbsp;|&nbsp;Date Due:&nbsp;
                {content.date
                  ? formatDate(parseDate(content.date), {
                      showDate: true,
                      showMonth: true,
                      showYear: true,
                    })
                  : ""}
              </span>
            </Heading>
            <EditButton disabled={readOnly} onClick={() => setIsEditing(true)}>
              Edit
            </EditButton>
            <CollapseButton
              isCollapsed={isCollapsed}
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
            <List show={!isCollapsed}>
              <DefintionContainer>
                <Term>The Audience Persona</Term>
                <Def>{content.persona}</Def>
              </DefintionContainer>

              <DefintionContainer>
                <Term>Content Creator</Term>
                <Def>{content.creator}</Def>
              </DefintionContainer>

              <DefintionContainer>
                <Term>Editor of Content</Term>
                <Def>{content.editor}</Def>
              </DefintionContainer>

              <DefintionContainer>
                <Term>Channels this will be published</Term>
                <Def>{content.channels}</Def>
              </DefintionContainer>

              <DefintionContainer>
                <Term>Publish Date</Term>
                <Def>
                  {content.publishDate
                    ? formatDate(parseDate(content.publishDate), {
                        showDate: true,
                        showMonth: true,
                        showYear: true,
                      })
                    : ""}
                </Def>
              </DefintionContainer>

              <DefintionContainer>
                <Term>Status</Term>
                <Def>{content.status}</Def>
              </DefintionContainer>

              <DefintionContainer>
                <Term>Key Metrics</Term>
                <Def>{content.metrics}</Def>
              </DefintionContainer>

              <DefintionContainer>
                <Term>Call to Action</Term>
                <Def>{content.cta}</Def>
              </DefintionContainer>

              <DefintionContainer notes>
                <Term>Notes</Term>
                <Def>{content.notes}</Def>
              </DefintionContainer>
            </List>
          </Container>
          <Modal onClose={() => setIsEditing(false)} show={isEditing}>
            <EditContentForm
              content={content}
              onDelete={() => onDelete({ id: content.id ?? "" })}
              onSubmit={async (variables) => {
                await onEdit({ ...variables, id: content.id ?? "" });
                setIsEditing(false);
              }}
            />
          </Modal>
        </>
      )}
    </Draggable>
  );
};

export default Content;
