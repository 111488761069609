import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { FC, useState } from "react";
import { CSALinkStyles } from "./CSALink";

const RESEND_VERIFICATION_LINK = gql`
  mutation ResendVerificationLink {
    resendVerification {
      code
      message
    }
  }
`;

const Resend = CSALinkStyles.withComponent("button");

const ResendEmailVerification: FC = () => {
  const [clicked, setClicked] = useState(false);
  const [resend] = useMutation(RESEND_VERIFICATION_LINK);

  const onClick = (): void => {
    if (!clicked) {
      setClicked(true);
      resend();
      setTimeout(() => setClicked(false), 2000);
    }
  };

  return (
    <p>
      Your email needs to be verified.&nbsp;
      <Resend onClick={onClick} type="button">
        {clicked ? "Sent" : "Resend Verification"}
      </Resend>
    </p>
  );
};

export default ResendEmailVerification;
