import styled from "@emotion/styled";

const ModalFormInput = styled.input<{ error: boolean; unselected?: boolean }>`
  border: none;
  outline: none;
  ${({ error }) => error && "border-bottom: 0.125rem solid var(--csa-red);"}
  ${({ unselected }) => unselected && "opacity: 0.6;"}
`;

export default ModalFormInput;
