import styled from "@emotion/styled";
import { EventApi } from "@fullcalendar/react";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import CSAButton from "../../components/CSAButton";
import Modal from "../../components/Modal";
import ModalFormInput from "../../components/ModalFormInput";
import ModalFormLabel from "../../components/ModalFormLabel";
import ModalFormTitleInput from "../../components/ModalFormTitleInput";
import useCancelOnEsc from "../../hooks/use-cancel-on-esc";
import ConfirmButton from "./ConfirmButton";
import {
  AddCalendarEventVariables,
  DeleteCalendarEventVariables,
  DeleteRecurringCalendarEventVariables,
  EditCalendarEventVariables,
  EditRecurringCalendarEventVariables,
} from "./types";

const Form = styled.form`
  min-width: 24rem;
`;

const AllDayCheckboxInput = styled(ModalFormInput)`
  margin-right: auto;
`;

const Select = styled(ModalFormInput.withComponent("select"))`
  margin-left: -0.125rem;
`;

const NullOption = styled.option`
  opacity: 0.6;
`;

const Textarea = styled(ModalFormInput.withComponent("textarea"))`
  display: block;
  font-size: 0.75rem;
  margin-top: 1rem;
  min-height: 5rem;
  width: 100%;
`;

const EventDetailsLabel = styled.label`
  font-weight: bold;
  margin-bottom: 2rem;
`;

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const ConfirmContainer = styled.div`
  text-align: center;
`;

const Submit = styled(CSAButton)`
  width: 8rem;
`;

interface FormData {
  allDay: boolean;
  details: string;
  endDate: string;
  pointPerson: string;
  repeats: "NONE" | "DAILY" | "WEEKLY" | "MONTHLY" | "YEARLY";
  startDate: string;
  title: string;
}

export interface CalendarEventFormProps {
  clearForm: () => void;
  clickedEvent: EventApi | undefined;
  controlledAllDay: boolean;
  controlledEndDate: Date | null;
  controlledStartDate: Date;
  onDelete: (variables: DeleteCalendarEventVariables) => Promise<void>;
  onDeleteRecurringCalendarEvent: (
    variables: DeleteRecurringCalendarEventVariables,
  ) => Promise<void>;
  isAdding: boolean;
  isEditing: boolean;
  onAdd: (variables: AddCalendarEventVariables) => Promise<void>;
  onEdit: (variables: EditCalendarEventVariables) => Promise<void>;
  onEditRecurringEvent: (
    variables: Omit<EditRecurringCalendarEventVariables, "updateOnlyThisEvent">,
  ) => Promise<void>;
  setControlledAllDay: Dispatch<SetStateAction<boolean>>;
  setControlledEndDate: Dispatch<SetStateAction<Date | null>>;
  setControlledStartDate: Dispatch<SetStateAction<Date>>;
}

const CalendarEventForm: FC<CalendarEventFormProps> = ({
  clearForm,
  clickedEvent,
  controlledAllDay,
  controlledEndDate,
  controlledStartDate,
  onDelete,
  onDeleteRecurringCalendarEvent,
  isAdding,
  isEditing,
  onAdd,
  onEdit,
  onEditRecurringEvent,
  setControlledAllDay,
  setControlledEndDate,
  setControlledStartDate,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const {
    control,
    errors,
    formState: { isDirty },
    handleSubmit,
    register,
    watch,
  } = useForm<FormData>({
    mode: "onBlur",
  });

  const clear = () => {
    setConfirmDelete(false);
    clearForm();
  };

  const onSubmit = async (e: FormData) => {
    if (!controlledEndDate || !controlledStartDate) return;

    const newEvent: AddCalendarEventVariables = {
      allDay: controlledAllDay,
      details: e.details ?? "",
      duration: Math.abs(
        controlledStartDate.getTime() - controlledEndDate.getTime(),
      ),
      exdate: [],
      pointPerson: e.pointPerson ?? "",
      repeatFreq: e.repeats.toUpperCase(),
      repeatUntil: clickedEvent?.extendedProps.repeatUntil ?? "",
      startDate: controlledStartDate?.toISOString() ?? "",
      title: e.title ?? "",
    };

    if (isAdding) {
      onAdd(newEvent);
    } else if (isEditing && clickedEvent) {
      if (!isDirty) {
        clear();
        return;
      }

      if (
        clickedEvent &&
        clickedEvent.extendedProps.repeatFreq !== "NONE" &&
        clickedEvent?.start
      ) {
        onEditRecurringEvent({
          ...newEvent,
          id: clickedEvent.id,
          targetStartDate: clickedEvent.start?.toISOString() ?? "",
        });
      } else {
        onEdit({ ...newEvent, id: clickedEvent.id });
      }
    }
  };

  useCancelOnEsc({ onCancel: () => setConfirmDelete(false) });

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalFormTitleInput
          ref={register({ required: true })}
          defaultValue={clickedEvent?.title}
          error={!!errors.title}
          name="title"
          placeholder="Add title"
          type="text"
        />

        <ModalFormLabel htmlFor="allDay">
          All day
          <Controller
            control={control}
            name="allDay"
            render={({ onChange }) => (
              <AllDayCheckboxInput
                checked={controlledAllDay}
                error={false}
                name="allDay"
                onChange={() => {
                  setControlledAllDay(!controlledAllDay);
                  onChange(controlledAllDay);

                  // Set to correct timestamp
                  controlledStartDate.setHours(0, 0, 0, 0);
                  setControlledStartDate(controlledStartDate);

                  if (controlledEndDate) {
                    controlledEndDate.setHours(0, 0, 0, 0);
                    controlledEndDate.setDate(controlledEndDate.getDate() + 1);
                    setControlledEndDate(controlledEndDate);
                  }
                }}
                type="checkbox"
              />
            )}
          />
        </ModalFormLabel>

        <ModalFormLabel htmlFor="startDate">
          Start Date
          <Controller
            control={control}
            name="startDate"
            render={({ onChange }) => (
              <DatePicker
                customInput={<ModalFormInput error={false} />}
                dateFormat={
                  controlledAllDay ? "MM-dd-yyyy" : "MM-dd-yyyy h:mm aa"
                }
                onChange={(date) => {
                  if (date) {
                    setControlledStartDate(date);
                    // Trigger onChange for marking dirty
                    onChange(date);
                    if (controlledEndDate && date > controlledEndDate) {
                      setControlledEndDate(
                        new Date(date.getTime() + 15 * 60 * 1000),
                      );
                    }
                  }
                }}
                selected={controlledStartDate}
                showTimeSelect={!controlledAllDay}
                timeCaption="time"
                timeFormat="h:mm aa"
                timeIntervals={15}
              />
            )}
          />
        </ModalFormLabel>

        <ModalFormLabel htmlFor="endDate">
          End Date
          <Controller
            control={control}
            name="endDate"
            render={({ onChange }) => (
              <DatePicker
                customInput={<ModalFormInput error={false} />}
                dateFormat={
                  controlledAllDay ? "MM-dd-yyyy" : "MM-dd-yyyy h:mm aa"
                }
                filterTime={(time: Date) =>
                  controlledStartDate.getTime() < new Date(time).getTime()
                }
                minDate={controlledStartDate}
                name="endDate"
                onChange={(date: Date | null) => {
                  if (date) {
                    // FullCalendar is date exclusive so we have to add the 1s back
                    setControlledEndDate(
                      controlledAllDay ? new Date(date.getTime() + 1000) : date,
                    );
                    // Trigger onChange for marking dirty
                    onChange(
                      controlledAllDay ? new Date(date.getTime() + 1000) : date,
                    );
                  }
                }}
                selected={
                  // FullCalendar is date exclusive so we have to go back 1s to
                  // get the previous day if allDay
                  controlledAllDay && controlledEndDate
                    ? new Date(controlledEndDate.getTime() - 1000)
                    : controlledEndDate
                }
                showTimeSelect={!controlledAllDay}
                timeCaption="time"
                timeFormat="h:mm aa"
                timeIntervals={15}
              />
            )}
          />
        </ModalFormLabel>

        <ModalFormLabel htmlFor="repeats">
          Repeats
          <Select
            ref={register}
            defaultValue={clickedEvent?.extendedProps.repeatFreq}
            error={!!errors.repeats}
            name="repeats"
            unselected={watch("repeats") === "NONE"}
          >
            <NullOption value="NONE">None</NullOption>
            <option value="DAILY">Daily</option>
            <option value="WEEKLY">Weekly</option>
            <option value="MONTHLY">Monthly</option>
            <option value="YEARLY">Yearly</option>
          </Select>
        </ModalFormLabel>

        <ModalFormLabel htmlFor="pointPerson">
          Point Person
          <ModalFormInput
            ref={register}
            defaultValue={clickedEvent?.extendedProps.pointPerson}
            error={!!errors.pointPerson}
            maxLength={80}
            name="pointPerson"
            placeholder="No Point Person"
            type="text"
          />
        </ModalFormLabel>

        <EventDetailsLabel htmlFor="details">
          Event Details
          <Textarea
            ref={register}
            defaultValue={clickedEvent?.extendedProps.details}
            error={!!errors.details}
            maxLength={1000}
            name="details"
            placeholder="Add details for event"
          />
        </EventDetailsLabel>

        <ButtonsContainer>
          <Submit type="submit">{isAdding ? "Add" : "Update"}</Submit>
          {isEditing && (
            <CSAButton
              onClick={() => setConfirmDelete(true)}
              type="button"
              variant="danger"
            >
              Delete Event
            </CSAButton>
          )}
        </ButtonsContainer>
      </Form>

      <Modal onClose={() => clear()} show={confirmDelete}>
        <ConfirmContainer>
          <h3>
            Are you sure you want to delete&nbsp;
            {clickedEvent?.title ?? "this event"}
          </h3>

          {clickedEvent?.extendedProps.repeatFreq !== "NONE" ? (
            <div>
              <ConfirmButton
                marginBottom="1rem"
                onClick={async () => {
                  if (!clickedEvent) {
                    clear();
                    return;
                  }

                  try {
                    await onDeleteRecurringCalendarEvent({
                      id: clickedEvent.id,
                      targetStartDate: controlledStartDate.toISOString(),
                      updateOnlyThisEvent: true,
                    });
                  } finally {
                    clear();
                  }
                }}
                type="button"
              >
                Delete Only This Event
              </ConfirmButton>

              {clickedEvent?.extendedProps.startDateRef ===
              controlledStartDate.toISOString() ? (
                <ConfirmButton
                  onClick={async () => {
                    try {
                      await onDelete({ id: clickedEvent.id });
                    } finally {
                      clear();
                    }
                  }}
                  type="button"
                  variant="danger"
                >
                  Delete All
                </ConfirmButton>
              ) : (
                <ConfirmButton
                  onClick={async () => {
                    if (!clickedEvent) {
                      clear();
                      return;
                    }

                    try {
                      await onDeleteRecurringCalendarEvent({
                        id: clickedEvent.id,
                        targetStartDate: new Date(
                          // FullCalendar needs seconds to be behind
                          controlledStartDate.getTime() - 1000,
                        ).toISOString(),
                        updateOnlyThisEvent: false,
                      });
                    } finally {
                      clear();
                    }
                  }}
                  type="button"
                  variant="danger"
                >
                  Delete All Future Events
                </ConfirmButton>
              )}
            </div>
          ) : (
            <ConfirmButton
              onClick={async () => {
                try {
                  await onDelete({ id: clickedEvent.id });
                } finally {
                  clear();
                }
              }}
              type="button"
            >
              Confirm
            </ConfirmButton>
          )}
        </ConfirmContainer>
      </Modal>
    </>
  );
};

export default CalendarEventForm;
