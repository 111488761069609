import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gql } from "apollo-boost";
import React, { FC, useState } from "react";
import BlockContainer from "../../components/BlockContainer";
import CSAButton from "../../components/CSAButton";
import CSAInput from "../../components/CSAInput";
import DataCell from "../../components/DataCell";
import HeaderCell from "../../components/HeaderCell";
import Modal from "../../components/Modal";
import Notification, { NotificationProps } from "../../components/Notification";
import formatDate from "../../helpers/format-date";
import parseDate from "../../helpers/parse-date";
import { CurrentUser } from "../../queries";
import { Response } from "../../types";
import EditReferralForm from "./EditReferralForm";

const Section = styled.section`
  position: relative;
`;

const Controls = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const SearchInputContainer = styled.div`
  display: inline-block;
  position: relative;
`;

const SearchInput = styled(CSAInput)`
  margin-right: 1rem;
`;

const ClearSearchButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  color: var(--csa-darkgrey);
  display: inline-block;
  outline: none;
  position: absolute;
  right: 1.5rem;
  top: 1rem;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`;

const AddReferralButton = styled(CSAButton)`
  display: inline-block;
`;

const TableContainer = styled(BlockContainer)`
  margin-top: 3rem;
  max-width: 70rem;
  position: relative;
`;

const Table = styled.table`
  font-size: 1rem;
  min-width: 80rem;
  width: 100%;

  & th,
  td {
    padding: 0 0.5rem;
  }

  & td:last-child {
    padding-right: 0;
  }
`;

const TableHeaderCell = styled(HeaderCell)`
  font-weight: bold;
  white-space: nowrap;
`;

const ControlsCell = styled.td`
  text-align: right;
  width: 4rem;
`;

const ActionButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  color: var(--csa-blue);
  display: inline-block;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;

const ConfirmationMessage = styled.p`
  font-size: 1.25rem;
  text-align: center;
`;

const DeleteConfirmButton = styled(CSAButton)`
  width: 100%;
`;

const REFERRAL_DIRECTORY = gql`
  query GetReferralDirectory {
    referralDirectory {
      address
      contactOfInterest
      dateContacted
      email
      id
      initialDateOfService
      outcome
      patientName
      phone
      referralSource
      typeOfReferral
    }
  }
`;

export interface ReferralDirectoryDataReferral {
  address?: string;
  contactOfInterest?: string;
  dateContacted?: string;
  email?: string;
  id?: string;
  initialDateOfService?: string;
  outcome?: string;
  patientName?: string;
  phone?: string;
  referralSource?: string;
  typeOfReferral?: string;
}

export interface ReferralDirectoryData {
  referralDirectory: ReferralDirectoryDataReferral[];
}

const ADD_REFERRAL = gql`
  mutation AddReferral(
    $address: String
    $contactOfInterest: String
    $dateContacted: String
    $email: String
    $initialDateOfService: String
    $outcome: String
    $patientName: String
    $phone: String
    $referralSource: String
    $typeOfReferral: String
  ) {
    addPatientReferral(
      address: $address
      contactOfInterest: $contactOfInterest
      dateContacted: $dateContacted
      email: $email
      initialDateOfService: $initialDateOfService
      outcome: $outcome
      patientName: $patientName
      phone: $phone
      referralSource: $referralSource
      typeOfReferral: $typeOfReferral
    ) {
      code
      message
    }
  }
`;

export interface AddReferralVariables {
  address: string;
  contactOfInterest: string;
  dateContacted: string;
  email: string;
  initialDateOfService: string;
  outcome: string;
  patientName: string;
  phone: string;
  referralSource: string;
  typeOfReferral: string;
}

const DELETE_REFERRAL = gql`
  mutation DeleteReferral($id: ID!) {
    deletePatientReferral(id: $id) {
      code
      message
    }
  }
`;

export interface DeleteReferralVariables {
  id: string;
}

const EDIT_REFERRAL = gql`
  mutation EditReferral(
    $address: String
    $contactOfInterest: String
    $dateContacted: String
    $email: String
    $id: ID!
    $initialDateOfService: String
    $outcome: String
    $patientName: String
    $phone: String
    $referralSource: String
    $typeOfReferral: String
  ) {
    editPatientReferral(
      address: $address
      contactOfInterest: $contactOfInterest
      dateContacted: $dateContacted
      email: $email
      id: $id
      initialDateOfService: $initialDateOfService
      outcome: $outcome
      patientName: $patientName
      phone: $phone
      referralSource: $referralSource
      typeOfReferral: $typeOfReferral
    ) {
      code
      message
    }
  }
`;

export interface EditReferralVariables {
  address: string;
  contactOfInterest: string;
  dateContacted: string;
  email: string;
  id: string;
  initialDateOfService: string;
  outcome: string;
  patientName: string;
  phone: string;
  referralSource: string;
  typeOfReferral: string;
}

export interface ReferralDirectoryProps {
  currentUser?: CurrentUser;
}

const ReferralDirectory: FC<ReferralDirectoryProps> = ({ currentUser }) => {
  const [notification, setNotification] = useState<NotificationProps>({});
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editingReferral, setEditingReferral] = useState<
    ReferralDirectoryDataReferral | undefined
  >();
  const [deletingReferral, setDeletingReferral] = useState<
    string | undefined
  >();
  const [search, setSearch] = useState("");

  const { data } = useQuery<ReferralDirectoryData, void>(REFERRAL_DIRECTORY, {
    fetchPolicy: "cache-and-network",
  });

  const [addReferral] = useMutation<Response, AddReferralVariables>(
    ADD_REFERRAL,
    {
      onCompleted: () =>
        setNotification({
          children: <p>Added successfully</p>,
          type: "success",
        }),
      onError: () =>
        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        }),
      refetchQueries: [{ query: REFERRAL_DIRECTORY }],
    },
  );

  const [deleteReferral] = useMutation<Response, DeleteReferralVariables>(
    DELETE_REFERRAL,
    {
      onCompleted: () =>
        setNotification({
          children: <p>Deleted successfully</p>,
          type: "success",
        }),
      onError: () =>
        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        }),
      refetchQueries: [{ query: REFERRAL_DIRECTORY }],
    },
  );

  const [editReferral] = useMutation<Response, EditReferralVariables>(
    EDIT_REFERRAL,
    {
      onCompleted: () =>
        setNotification({
          children: <p>Edited successfully</p>,
          type: "success",
        }),
      onError: () =>
        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        }),
      refetchQueries: [{ query: REFERRAL_DIRECTORY }],
    },
  );

  const filteredData = data?.referralDirectory.filter((r) => {
    if (!search) {
      return true;
    }

    let isInFilter = false;
    Object.keys(r).forEach((key) => {
      if (
        `${r[key as keyof ReferralDirectoryDataReferral]}`
          .toLowerCase()
          .includes(search.toLowerCase())
      ) {
        isInFilter = true;
      }
    });

    return isInFilter;
  });

  return (
    <>
      <Notification fadeOut float type={notification.type} width="100%">
        {notification.children}
      </Notification>

      <Section>
        <h1>The Referral Directory</h1>

        <Controls>
          <SearchInputContainer>
            <SearchInput
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
              type="text"
              value={search}
            />
            {search && (
              <ClearSearchButton onClick={() => setSearch("")} type="button">
                <FontAwesomeIcon icon={faTimes} />
              </ClearSearchButton>
            )}
          </SearchInputContainer>
          <AddReferralButton
            disabled={!currentUser?.active}
            onClick={() => setIsAdding(true)}
            type="button"
          >
            Add Referral
          </AddReferralButton>
        </Controls>

        <TableContainer>
          <Table>
            <thead>
              <tr>
                <TableHeaderCell>Initial Date of Service</TableHeaderCell>
                <TableHeaderCell>Patient Name</TableHeaderCell>
                <TableHeaderCell>Referral Source</TableHeaderCell>
                <TableHeaderCell>Type of Referral</TableHeaderCell>
                <TableHeaderCell>Contact of Interest</TableHeaderCell>
                <TableHeaderCell>Phone</TableHeaderCell>
                <TableHeaderCell>Email</TableHeaderCell>
                <TableHeaderCell>Address</TableHeaderCell>
                <TableHeaderCell>Date Contacted?</TableHeaderCell>
                <TableHeaderCell>Outcome</TableHeaderCell>
                <th aria-label="Edit" />
              </tr>
            </thead>
            <tbody>
              {filteredData?.map((r) => (
                <tr key={r.id}>
                  <DataCell>
                    {r.initialDateOfService
                      ? formatDate(parseDate(r.initialDateOfService), {
                          showDate: true,
                          showMonth: true,
                          showYear: true,
                        })
                      : ""}
                  </DataCell>
                  <DataCell>{r.patientName}</DataCell>
                  <DataCell>{r.referralSource}</DataCell>
                  <DataCell>{r.typeOfReferral}</DataCell>
                  <DataCell>{r.contactOfInterest}</DataCell>
                  <DataCell>{r.phone}</DataCell>
                  <DataCell>{r.email}</DataCell>
                  <DataCell>{r.address}</DataCell>
                  <DataCell>
                    {r.dateContacted
                      ? formatDate(parseDate(r.dateContacted), {
                          showDate: true,
                          showMonth: true,
                          showYear: true,
                        })
                      : ""}
                  </DataCell>
                  <DataCell>{r.outcome}</DataCell>
                  <ControlsCell>
                    <ActionButton
                      disabled={!currentUser?.active}
                      onClick={() => {
                        setEditingReferral(r);
                        setIsEditing(true);
                      }}
                      type="button"
                    >
                      Edit
                    </ActionButton>
                    <ActionButton
                      disabled={!currentUser?.active}
                      onClick={() => {
                        setDeletingReferral(r.id);
                        setIsDeleting(true);
                      }}
                      type="button"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </ActionButton>
                  </ControlsCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Section>

      <Modal onClose={() => setIsAdding(false)} show={isAdding}>
        <EditReferralForm
          onSubmit={async (variables) => {
            await addReferral({ variables });
            setIsAdding(false);
          }}
        />
      </Modal>

      <Modal
        onClose={() => {
          setIsEditing(false);
          setEditingReferral(undefined);
        }}
        show={isEditing}
      >
        <EditReferralForm
          onSubmit={async (variables) => {
            if (editingReferral?.id) {
              await editReferral({
                variables: { ...variables, id: editingReferral.id },
              });
            }
            setIsEditing(false);
            setEditingReferral(undefined);
          }}
          referral={editingReferral}
        />
      </Modal>

      <Modal
        onClose={() => {
          setIsDeleting(false);
          setDeletingReferral(undefined);
        }}
        show={isDeleting}
      >
        <ConfirmationMessage>
          Are you sure you want to delete?
        </ConfirmationMessage>
        <DeleteConfirmButton
          onClick={async () => {
            setIsDeleting(false);
            setDeletingReferral(undefined);

            if (deletingReferral) {
              await deleteReferral({
                variables: {
                  id: deletingReferral,
                },
              });
            }
          }}
          type="button"
        >
          Delete
        </DeleteConfirmButton>
      </Modal>
    </>
  );
};

export default ReferralDirectory;
