import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { FC, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Notification, { NotificationProps } from "../../components/Notification";
import { CurrentUser } from "../../queries";
import { Response } from "../../types";
import Checklist from "./Checklist";
import List from "./List";

const GET_MCM_ROADMAP_CHECKLISTS = gql`
  query GetMcmRoadmapChecklists {
    mcmRoadmapChecklists {
      id
      name
      data
    }
  }
`;

export interface GetMcmRoadmapChecklistsData {
  mcmRoadmapChecklists?: {
    id: string;
    name: string;
    data: Record<string, boolean | string>;
  }[];
}

const CREATE_MCM_ROADMAP_CHECKLIST = gql`
  mutation CreateMCMRoadmapChecklist($name: String) {
    createMCMRoadmapChecklist(name: $name) {
      code
      message
    }
  }
`;

export interface CreateMCMRoadmapChecklistVariables {
  name: string;
}

const UPDATE_MCM_ROADMAP_CHECKLIST = gql`
  mutation UpdateMCMRoadmapChecklist($data: JSON!, $id: ID!, $name: String) {
    updateMCMRoadmapChecklist(data: $data, id: $id, name: $name) {
      code
      message
    }
  }
`;

export interface UpdateMCMRoadmapChecklistVariables {
  data: Record<string, boolean | string>;
  id: string;
  name?: string;
}

const DELETE_MCM_ROADMAP_CHECKLIST = gql`
  mutation DeleteMCMRoadmapChecklist($id: ID!) {
    deleteMCMRoadmapChecklist(id: $id) {
      code
      message
    }
  }
`;

export interface DeleteMCMRoadmapChecklistVariables {
  id: string;
}

export interface MCMRoadmapChecklistProps {
  currentUser?: CurrentUser;
}

const MCMRoadmapChecklist: FC<MCMRoadmapChecklistProps> = ({ currentUser }) => {
  const match = useRouteMatch();
  const [notification, setNotification] = useState<NotificationProps>({});

  const { data, loading } = useQuery<GetMcmRoadmapChecklistsData>(
    GET_MCM_ROADMAP_CHECKLISTS,
    {
      fetchPolicy: "cache-and-network",
      onError: () =>
        setNotification({
          children: <p>Sorry data couldn&apos;t be loaded</p>,
          type: "error",
        }),
    },
  );

  const [createChecklist] = useMutation<
    Response,
    CreateMCMRoadmapChecklistVariables
  >(CREATE_MCM_ROADMAP_CHECKLIST, {
    onCompleted: () =>
      setNotification({
        children: <p>Created successfully</p>,
        type: "success",
      }),
    onError: () =>
      setNotification({
        children: <p>Sorry something went wrong</p>,
        type: "error",
      }),
    refetchQueries: [{ query: GET_MCM_ROADMAP_CHECKLISTS }],
  });

  const [updateChecklist, { error }] = useMutation<
    Response,
    UpdateMCMRoadmapChecklistVariables
  >(UPDATE_MCM_ROADMAP_CHECKLIST, {
    onCompleted: () =>
      setNotification({
        children: <p>Checklist saved</p>,
        type: "success",
      }),
    onError: () =>
      setNotification({
        children: <p>Sorry something went wrong</p>,
        type: "error",
      }),
    refetchQueries: [{ query: GET_MCM_ROADMAP_CHECKLISTS }],
  });

  const [deleteChecklist] = useMutation<
    Response,
    DeleteMCMRoadmapChecklistVariables
  >(DELETE_MCM_ROADMAP_CHECKLIST, {
    onCompleted: () =>
      setNotification({
        children: <p>Checklist deleted</p>,
        type: "success",
      }),
    onError: () =>
      setNotification({
        children: <p>Sorry something went wrong</p>,
        type: "error",
      }),
    refetchQueries: [{ query: GET_MCM_ROADMAP_CHECKLISTS }],
  });

  return (
    <>
      <Notification fadeOut float type={notification.type} width="100%">
        {notification.children}
      </Notification>

      <Switch>
        <Route path={`${match.path}/:checklistId`}>
          <Checklist
            data={data}
            error={error}
            loading={loading}
            onUpdate={async (variables) => {
              await updateChecklist({ variables });
            }}
            readOnly={!currentUser?.active}
          />
        </Route>
        <Route path={match.path}>
          <List
            createChecklist={async (variables) => {
              await createChecklist({ variables });
            }}
            data={data}
            onDelete={async (variables) => {
              await deleteChecklist({ variables });
            }}
            onUpdate={async (variables) => {
              await updateChecklist({ variables });
            }}
            readOnly={!currentUser?.active}
          />
        </Route>
      </Switch>
    </>
  );
};

export default MCMRoadmapChecklist;
