import styled from "@emotion/styled";
import ModalFormInput from "./ModalFormInput";

const ModalFormTitleInput = styled(ModalFormInput)`
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
  min-width: 85%;
`;

export default ModalFormTitleInput;
