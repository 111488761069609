import styled from "@emotion/styled";
import React, { FC } from "react";

export interface CSAInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  error?: string | boolean;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labelProps?: any;
  name?: string;
  padding?: string;
  ref?: React.Ref<HTMLInputElement>;
}

const errorColor = "var(--csa-red)";

export const Label = styled.label`
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
`;

const Input = styled.input<CSAInputProps>`
  ${({ label }) => label && "margin-top: 0.5rem;"}
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: block;
  outline: none;
  padding: ${({ padding }) => padding ?? "1rem"};
  width: 100%;
  border: 0.0625rem solid
    ${({ error }): string => (error ? errorColor : "var(--csa-border)")};

  &:focus {
    border-color: ${({ error }): string =>
      error ? errorColor : "var(--csa-blue)"};
  }
`;

const Error = styled.span`
  color: ${errorColor};
  display: inline-block;
  margin-top: 0.25rem;
`;

/* eslint react/jsx-props-no-spreading: 0 */
const CSAInput: FC<CSAInputProps> = React.forwardRef(
  (
    { className, children, error, id, label, labelProps, name, ...props },
    ref,
  ) => (
    <Label {...labelProps} className={className} htmlFor={id || name}>
      {label}
      <Input
        {...props}
        ref={ref}
        error={error}
        id={id}
        label={label}
        name={name}
      />
      {children}
      {error && typeof error === "string" && <Error>{error}</Error>}
    </Label>
  ),
);

export default CSAInput;
