import styled from "@emotion/styled";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import CSALogo from "../images/csa-logo.jpg";

export interface HomeImageLinkProps {
  onClick?: () => void;
}

const Img = styled.img`
  width: 16rem;
`;

const HomeImageLink: FC<HomeImageLinkProps> = ({ onClick }) => (
  <Link onClick={onClick} to="/">
    <Img alt="Chiropractic Success Academy" src={CSALogo} />
  </Link>
);

export default HomeImageLink;
