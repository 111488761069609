import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "@fullcalendar/react";
import React, { FC, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import CSAButton from "../../components/CSAButton";
import CSAInput from "../../components/CSAInput";
import CSASelector from "../../components/CSASelector";
import Modal from "../../components/Modal";
import Notification, { NotificationProps } from "../../components/Notification";
import useRecallLastView from "../../hooks/use-recall-last-view";
import { CurrentUser } from "../../queries";
import NetworkForm from "./NetworkForm";
import {
  ADD_NETWORK_ENTRY,
  AddNetworkEntryVariables,
  CREATE_NETWORK_LIST,
  CreateNetworkListVariables,
  DELETE_NETWORK_ENTRY,
  DeleteNetworkEntryVariables,
  EDIT_NETWORK_ENTRY,
  EditNetworkEntryVariables,
  GET_MY_NETWORK,
  GetMyNetworkData,
  REORDER_NETWORK,
  ReorderNetworkVariables,
} from "./queries";
import { Network, NetworkList } from "./types";

const Section = styled.section`
  margin-bottom: 4rem;
`;

const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const H1 = styled.h1`
  margin-bottom: 2rem;
`;

const ListControls = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const ListSelector = styled(CSASelector)`
  width: 10rem;
  height: 2.875rem;
`;

const CreateListButton = styled(CSAButton)``;

const H2 = styled.h2`
  text-align: center;
  grid-column-start: 2;
`;

const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  text-align: left;
  border: 0.0625rem solid var(--csa-border);
`;

const Tr = styled.tr<{ isDragging?: boolean }>`
  border-bottom: 0.0625rem solid var(--csa-border);
  transition: all 0.1s;

  &:hover {
    background: var(--csa-bg-secondary);
  }

  ${({ isDragging }) =>
    isDragging &&
    `
    display: table;
    border-bottom: 0;
    table-layout: fixed;
  `}
`;

const Th = styled.th`
  background: var(--csa-bg-tertiary);
  padding: 1rem;

  @media (min-width: 1200px) {
    font-size: 0.75rem;
  }

  @media (min-width: 1400px) {
    font-size: 0.875rem;
  }
`;

const EmptyTd = styled.td`
  opacity: 0.5;
  padding: 1rem;
  text-align: center;
`;

const Td = styled.td`
  overflow: hidden;
  padding: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 1200px) {
    font-size: 0.75rem;
  }
`;

const SectionHeading = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media (min-width: 1200px) {
    flex-direction: row;
    gap: 2rem;
    justify-content: space-between;
  }
`;

const AddButton = styled(CSAButton)`
  margin-left: auto;
  grid-column-start: 3;
  place-self: center;
`;

export interface MyCalendarProps {
  currentUser?: CurrentUser;
}

const MyCalendar: FC<MyCalendarProps> = ({ currentUser }) => {
  const [notification, setNotification] = useState<NotificationProps>({});
  const [formContent, setFormContent] = useState<
    (Network & { list: NetworkList }) | { list: NetworkList } | undefined
  >();
  const [isAddingNewList, setIsAddingNewList] = useState(false);
  const [myNetworkListId, setMyNetworkListId] = useState("");

  useRecallLastView("my-network", { myNetworkListId }, { setMyNetworkListId });

  // Used for better animation of reordering
  const [data, setData] = useState<GetMyNetworkData>();
  const { data: remoteData } = useQuery<GetMyNetworkData>(GET_MY_NETWORK, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (remoteData) {
      setData(remoteData);
    }
  }, [remoteData]);

  const selectedList = data?.myNetwork?.find?.(
    (list) => list.id === myNetworkListId,
  );

  const [createNetworkList] = useMutation<Response, CreateNetworkListVariables>(
    CREATE_NETWORK_LIST,
    {
      onCompleted: () =>
        setNotification({
          children: <p>List Created</p>,
          type: "success",
        }),
      onError: () =>
        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        }),
      refetchQueries: [{ query: GET_MY_NETWORK }],
    },
  );

  const [addNetworkEntry] = useMutation<Response, AddNetworkEntryVariables>(
    ADD_NETWORK_ENTRY,
    {
      onCompleted: () =>
        setNotification({
          children: <p>Added successfully</p>,
          type: "success",
        }),
      onError: () =>
        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        }),
      refetchQueries: [{ query: GET_MY_NETWORK }],
    },
  );

  const [deleteNetworkEntry] = useMutation<
    Response,
    DeleteNetworkEntryVariables
  >(DELETE_NETWORK_ENTRY, {
    onCompleted: () =>
      setNotification({
        children: <p>Deleted successfully</p>,
        type: "success",
      }),
    onError: () =>
      setNotification({
        children: <p>Sorry something went wrong</p>,
        type: "error",
      }),
    refetchQueries: [{ query: GET_MY_NETWORK }],
  });

  const [editNetworkEntry] = useMutation<Response, EditNetworkEntryVariables>(
    EDIT_NETWORK_ENTRY,
    {
      onCompleted: () =>
        setNotification({
          children: <p>Updated successfully</p>,
          type: "success",
        }),
      onError: () =>
        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        }),
      refetchQueries: [{ query: GET_MY_NETWORK }],
    },
  );

  const [reorderNetwork] = useMutation<Response, ReorderNetworkVariables>(
    REORDER_NETWORK,
    {
      onCompleted: () =>
        setNotification({
          children: <p>Updated successfully</p>,
          type: "success",
        }),
      onError: () =>
        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        }),
      refetchQueries: [{ query: GET_MY_NETWORK }],
    },
  );

  const { handleSubmit, register } = useForm<{ name: string }>();

  return (
    <>
      <Notification fadeOut float type={notification.type} width="100%">
        {notification.children}
      </Notification>

      <article>
        <Header>
          <H1>My Network</H1>

          <ListControls>
            {data?.myNetwork?.length ? (
              <ListSelector
                items={
                  data?.myNetwork?.map((list) => ({
                    id: list.id as string,
                    value: list.name as string,
                  })) ?? []
                }
                label="List:"
                onSelect={({ id }) => setMyNetworkListId(id as string)}
                value={
                  data?.myNetwork?.find((l) => l.id === myNetworkListId)
                    ?.name ?? "Select a list"
                }
              />
            ) : null}
            <div>
              {!isAddingNewList && (
                <CreateListButton
                  disabled={!currentUser?.active}
                  onClick={() => setIsAddingNewList(true)}
                  type="button"
                >
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp;Create List
                </CreateListButton>
              )}

              {isAddingNewList && (
                <form
                  onSubmit={handleSubmit((variables) => {
                    createNetworkList({ variables });
                    setIsAddingNewList(false);
                  })}
                >
                  <CSAInput
                    ref={register({ required: true })}
                    maxLength={40}
                    name="name"
                    placeholder="List Name"
                  />
                </form>
              )}
            </div>
          </ListControls>
        </Header>

        {selectedList && (
          <ListContainer>
            <DragDropContext
              onDragEnd={async ({ destination, draggableId, source }) => {
                if (!destination || !selectedList) {
                  return;
                }

                if (
                  destination.droppableId === source.droppableId &&
                  destination.index === source.index
                ) {
                  return;
                }

                const newPotentialReferring = selectedList.potentialReferring;
                const newReferring = selectedList.referring;

                if (!newPotentialReferring || !newReferring) {
                  return;
                }

                let network: Network;
                if (source.droppableId === "POTENTIAL_REFERRING") {
                  [network] = newPotentialReferring.splice(source.index, 1);
                } else if (source.droppableId === "REFERRING") {
                  [network] = newReferring.splice(source.index, 1);
                } else {
                  return;
                }

                if (destination.droppableId === "POTENTIAL_REFERRING") {
                  newPotentialReferring.splice(destination.index, 0, network);
                } else if (destination.droppableId === "REFERRING") {
                  newReferring.splice(destination.index, 0, network);
                }

                setData({
                  ...data,
                  myNetwork: data?.myNetwork?.map((list) => {
                    if (list.id === myNetworkListId) {
                      return {
                        ...list,
                        potentialReferring: newPotentialReferring,
                        referring: newReferring,
                      };
                    }

                    return list;
                  }),
                });

                await reorderNetwork({
                  variables: {
                    destination: {
                      droppableId: destination.droppableId as NetworkList,
                      index: destination.index,
                    },
                    id: draggableId,
                    myNetworkListId,
                    source: {
                      droppableId: source.droppableId as NetworkList,
                      index: source.index,
                    },
                  },
                });
              }}
            >
              <Section>
                <SectionHeading>
                  <H2>Potential Referring Partners</H2>
                  <AddButton
                    disabled={!currentUser?.active}
                    onClick={() =>
                      setFormContent({ list: "POTENTIAL_REFERRING" })
                    }
                    type="button"
                  >
                    Add
                  </AddButton>
                </SectionHeading>

                <Droppable droppableId="POTENTIAL_REFERRING">
                  {({ droppableProps, innerRef, placeholder }) => (
                    <Table>
                      <thead>
                        <Tr>
                          <Th>Position</Th>
                          <Th>Name</Th>
                          <Th>Profession</Th>
                          <Th>Contact Info</Th>
                          <Th>Notes</Th>
                          <Th>Last Check In</Th>
                        </Tr>
                      </thead>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <tbody ref={innerRef} {...droppableProps}>
                        {!selectedList.potentialReferring?.length && (
                          <tr>
                            <EmptyTd colSpan={6}>
                              Add or drag in a new partner...
                            </EmptyTd>
                          </tr>
                        )}
                        {selectedList.potentialReferring?.map(
                          (network, index) => (
                            <Draggable
                              key={network.id}
                              draggableId={network.id ?? ""}
                              index={index}
                              isDragDisabled={!currentUser?.active}
                            >
                              {(
                                {
                                  dragHandleProps,
                                  draggableProps,
                                  innerRef: innerRefTmp,
                                },
                                { isDragging },
                              ) => (
                                <Tr
                                  ref={innerRefTmp}
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...dragHandleProps}
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...draggableProps}
                                  isDragging={isDragging}
                                  onClick={() =>
                                    setFormContent({
                                      ...network,
                                      list: "POTENTIAL_REFERRING",
                                    })
                                  }
                                >
                                  <Td>{index + 1}</Td>
                                  <Td>{network.name}</Td>
                                  <Td>{network.profession}</Td>
                                  <Td>{network.contact}</Td>
                                  <Td>{network.notes}</Td>
                                  <Td>
                                    {formatDate(network.lastCheckIn ?? "")}
                                  </Td>
                                </Tr>
                              )}
                            </Draggable>
                          ),
                        )}
                        {placeholder}
                      </tbody>
                    </Table>
                  )}
                </Droppable>
              </Section>

              <Section>
                <SectionHeading>
                  <H2>Referring Partners</H2>
                  <AddButton
                    disabled={!currentUser?.active}
                    onClick={() => setFormContent({ list: "REFERRING" })}
                    type="button"
                  >
                    Add
                  </AddButton>
                </SectionHeading>

                <Droppable droppableId="REFERRING">
                  {({ droppableProps, innerRef, placeholder }) => (
                    <Table>
                      <thead>
                        <Tr>
                          <Th>Position</Th>
                          <Th>Name</Th>
                          <Th>Profession</Th>
                          <Th>Contact Info</Th>
                          <Th>Notes</Th>
                          <Th>Last Check In</Th>
                        </Tr>
                      </thead>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <tbody ref={innerRef} {...droppableProps}>
                        {!selectedList.referring?.length && (
                          <tr>
                            <EmptyTd colSpan={6}>
                              Add or drag in a new partner...
                            </EmptyTd>
                          </tr>
                        )}
                        {selectedList.referring?.map((network, index) => (
                          <Draggable
                            key={network.id}
                            draggableId={network.id ?? ""}
                            index={index}
                            isDragDisabled={!currentUser?.active}
                          >
                            {(
                              {
                                dragHandleProps,
                                draggableProps,
                                innerRef: innerRefTmp,
                              },
                              { isDragging },
                            ) => (
                              <Tr
                                ref={innerRefTmp}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...dragHandleProps}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...draggableProps}
                                isDragging={isDragging}
                                onClick={() =>
                                  setFormContent({
                                    ...network,
                                    list: "REFERRING",
                                  })
                                }
                              >
                                <Td>{index + 1}</Td>
                                <Td>{network.name}</Td>
                                <Td>{network.profession}</Td>
                                <Td>{network.contact}</Td>
                                <Td>{network.notes}</Td>
                                <Td>{formatDate(network.lastCheckIn ?? "")}</Td>
                              </Tr>
                            )}
                          </Draggable>
                        ))}
                        {placeholder}
                      </tbody>
                    </Table>
                  )}
                </Droppable>
              </Section>
            </DragDropContext>
          </ListContainer>
        )}

        <Modal onClose={() => setFormContent(undefined)} show={!!formContent}>
          <NetworkForm
            network={formContent}
            onDelete={async () => {
              if (!formContent || !(formContent as Network).id) return;

              await deleteNetworkEntry({
                variables: {
                  id: (formContent as Network).id ?? "",
                  myNetworkListId,
                },
              });

              setFormContent(undefined);
            }}
            onSubmit={async (variables) => {
              if (!formContent) return;

              if ((formContent as Network)?.id) {
                await editNetworkEntry({
                  variables: {
                    ...variables,
                    id: (formContent as Network).id ?? "",
                  },
                });

                setFormContent(undefined);

                return;
              }

              await addNetworkEntry({
                variables: {
                  ...variables,
                  list: formContent.list,
                  myNetworkListId,
                },
              });

              setFormContent(undefined);
            }}
            readOnly={!currentUser?.active}
          />
        </Modal>
      </article>
    </>
  );
};

export default MyCalendar;
