import styled from "@emotion/styled";
import React, { FC, Suspense, lazy, useState } from "react";
import { useForm } from "react-hook-form";
import CSAButton from "../../components/CSAButton";
import CopyToClipboard from "../../components/CopyToClipboard";
import Menu from "../../components/Menu";
import ModalFormInput from "../../components/ModalFormInput";
import ModalFormLabel from "../../components/ModalFormLabel";
import ModalFormTitleInput from "../../components/ModalFormTitleInput";
import selectStyles from "./select-styles";
import { AddAlliedHealthNetworkVariables, AlliedHealthNetwork } from ".";

import "react-datepicker/dist/react-datepicker.css";

const CreatableSelect = styled(lazy(() => import("react-select/creatable")))`
  width: 100%;
`;

const TypeSelect = styled(lazy(() => import("react-select")))`
  width: 12rem;
`;

const HelpText = styled.span`
  font-size: 0.75rem;
  color: var(--csa-darkgrey);
`;

const Form = styled.form`
  font-size: 0.875rem;
  padding: 4rem;
  position: relative;

  @media (min-width: 576px) {
    min-width: 36rem;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Textarea = styled(ModalFormInput.withComponent("textarea"))`
  font-size: 0.75rem;
`;

const Submit = styled(CSAButton)`
  margin-bottom: 0.5rem;
  width: 8rem;
`;

export interface AlliedHealthNetworkFormProps {
  onSubmit: (variables: AddAlliedHealthNetworkVariables) => void;
  onDelete?: () => void;
  network?: AlliedHealthNetwork;
  readOnly?: boolean;
  specialtyOptions: {
    label: string;
    options: { group: string; label: string; value: string }[];
  }[];
}

const AlliedHealthNetworkForm: FC<AlliedHealthNetworkFormProps> = ({
  network,
  onDelete,
  onSubmit,
  readOnly,
  specialtyOptions,
}) => {
  const [specialtyType, setSpecialtyType] = useState<
    | {
        label: string;
        value: string;
      }
    | undefined
  >(
    network?.specialty
      ? {
          label: network.specialty,
          value: network.specialty,
        }
      : undefined,
  );

  const [type, setType] = useState<"HEALTH" | "PERFORMANCE" | undefined>(
    network?.type,
  );

  const [isNew, setIsNew] = useState(false);

  const { errors, handleSubmit, register, watch } =
    useForm<AddAlliedHealthNetworkVariables>({
      defaultValues: network,
    });

  return (
    <Form
      onSubmit={handleSubmit((variables) => {
        if (specialtyType && type) {
          onSubmit({
            ...variables,
            specialty: specialtyType.value,
            type,
          });
        }
      })}
    >
      {!readOnly && onDelete && (
        <Menu ellipsisOrientation="horizontal" onDelete={onDelete} />
      )}

      <ModalFormTitleInput
        ref={register({ required: true })}
        error={!!errors.name}
        maxLength={80}
        name="name"
        placeholder="Name"
        readOnly={readOnly}
        type="text"
      />
      <ModalFormLabel htmlFor="specialty">
        Specialty/Focus
        <div>
          <Suspense fallback={<div />}>
            <SelectContainer>
              <CreatableSelect
                classNames={{ group: () => "select-group" }}
                isDisabled={readOnly}
                onChange={(v) => {
                  setSpecialtyType(
                    (v as {
                      label: string;
                      value: string;
                    }) ?? undefined,
                  );
                  setType(
                    (v as { group: "HEALTH" | "PERFORMANCE" | undefined })
                      .group,
                  );

                  // eslint-disable-next-line no-underscore-dangle
                  setIsNew(!!(v as { __isNew__?: boolean }).__isNew__);
                }}
                options={specialtyOptions}
                placeholder="Add Provider Type..."
                required
                styles={selectStyles}
                value={specialtyType}
              />
              {isNew && (
                <TypeSelect
                  onChange={(v) =>
                    setType((v as { value: "HEALTH" | "PERFORMANCE" }).value)
                  }
                  options={[
                    { label: "Health", value: "HEALTH" },
                    { label: "Performance", value: "PERFORMANCE" },
                  ]}
                  required
                  styles={selectStyles}
                />
              )}
            </SelectContainer>
          </Suspense>
          <HelpText>Type to create a new specialty/focus</HelpText>
        </div>
      </ModalFormLabel>
      <ModalFormLabel htmlFor="facilityName">
        Facility Name
        <ModalFormInput
          ref={register}
          error={!!errors.facilityName}
          maxLength={80}
          name="facilityName"
          placeholder="No Facility Name"
          readOnly={readOnly}
          type="text"
        />
      </ModalFormLabel>
      <ModalFormLabel htmlFor="address">
        Address
        <CopyToClipboard text={watch("address")}>
          <ModalFormInput
            ref={register}
            error={!!errors.address}
            maxLength={80}
            name="address"
            placeholder="No Address"
            readOnly={readOnly}
            type="text"
          />
        </CopyToClipboard>
      </ModalFormLabel>
      <ModalFormLabel htmlFor="officeNumber">
        Office Number
        <CopyToClipboard text={watch("officeNumber")}>
          <ModalFormInput
            ref={register}
            error={!!errors.officeNumber}
            maxLength={80}
            name="officeNumber"
            placeholder="No Office Number"
            readOnly={readOnly}
            type="text"
          />
        </CopyToClipboard>
      </ModalFormLabel>
      <ModalFormLabel htmlFor="cellNumber">
        Cell Number
        <CopyToClipboard text={watch("cellNumber")}>
          <ModalFormInput
            ref={register}
            error={!!errors.cellNumber}
            maxLength={80}
            name="cellNumber"
            placeholder="No Cell Number"
            readOnly={readOnly}
            type="text"
          />
        </CopyToClipboard>
      </ModalFormLabel>
      <ModalFormLabel htmlFor="emailAddress">
        Email Address
        <CopyToClipboard text={watch("emailAddress")}>
          <ModalFormInput
            ref={register}
            error={!!errors.emailAddress}
            maxLength={80}
            name="emailAddress"
            placeholder="No Email Address"
            readOnly={readOnly}
            type="email"
          />
        </CopyToClipboard>
      </ModalFormLabel>
      <ModalFormLabel htmlFor="contactPerson">
        Contact Person
        <ModalFormInput
          ref={register}
          error={!!errors.contactPerson}
          maxLength={80}
          name="contactPerson"
          placeholder="No Contact Person"
          readOnly={readOnly}
          type="text"
        />
      </ModalFormLabel>
      <ModalFormLabel htmlFor="notes">
        Notes
        <Textarea
          ref={register}
          error={!!errors.notes}
          maxLength={500}
          name="notes"
          placeholder="No Notes"
          readOnly={readOnly}
        />
      </ModalFormLabel>
      {!readOnly && <Submit type="submit">Save</Submit>}
    </Form>
  );
};

export default AlliedHealthNetworkForm;
