import styled from "@emotion/styled";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import MinimalInput from "../../components/MinimalInput";
import useCancelOnEsc from "../../hooks/use-cancel-on-esc";

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.25rem 0;
  margin: 0 -0.125rem;
  position: relative;
`;

const Input = styled(MinimalInput)<{ width: string }>`
  margin: 0 0.125rem;
  width: ${({ width }) => width};
`;

const Button = styled.button<{ right: string }>`
  background: none;
  border: 0;
  color: var(--csa-blue);
  position: absolute;
  right: ${({ right }) => right};
`;

interface Indicator {
  goals: string;
  title: string;
  who: string;
}

export interface IndicatorAddProps {
  onAdd: (indicator: Indicator) => void;
  onCancel: () => void;
}

const IndicatorAdd: FC<IndicatorAddProps> = ({ onAdd, onCancel }) => {
  const { errors, handleSubmit, register } = useForm<Indicator>();

  useCancelOnEsc({ onCancel });

  return (
    <Form onSubmit={handleSubmit(onAdd)}>
      <Input
        ref={register({ required: true })}
        error={!!errors.who}
        maxLength={80}
        name="who"
        width="8.5rem"
      />
      <Input
        ref={register({ required: true })}
        error={!!errors.title}
        maxLength={80}
        name="title"
        width="8.5rem"
      />
      <Input
        ref={register({ required: true })}
        error={!!errors.goals}
        maxLength={80}
        name="goals"
        width="4.5rem"
      />
      <Button right="-2rem" type="submit">
        <FontAwesomeIcon icon={faCheck} />
      </Button>
      <Button right="-3.5rem" type="reset">
        <FontAwesomeIcon icon={faTimes} onClick={onCancel} />
      </Button>
    </Form>
  );
};

export default IndicatorAdd;
