import uniq from "lodash.uniq";
import uniqBy from "lodash.uniqby";
import React, { Dispatch, FC, SetStateAction } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { NotificationProps } from "../../components/Notification";
import Column from "./Column";
import ContentContainer from "./ContentContainer";
import { Views } from "./Nav";
import Task from "./Task";
import { EditTaskVariables, YearPlanTask } from "./graphql";
import { LocalMarketingYearPlan } from ".";

const getMonths = (view: Views) => {
  switch (view) {
    case "q1":
      return [1, 2, 3];
    case "q2":
      return [4, 5, 6];
    case "q3":
      return [7, 8, 9];
    case "q4":
      return [10, 11, 12];
    default:
      return [];
  }
};

const toMonth = (id: number) =>
  [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][id - 1];

const getListOfTasksAssignedToMonth = (months: {
  [key: string]: { tasks?: YearPlanTask[] };
}) =>
  Object.keys(months)
    .reduce<YearPlanTask[]>(
      (acc, key) => acc.concat((months[key] ?? {})?.tasks ?? []),
      [],
    )
    .map(({ id }) => id);

export interface QuarterViewProps {
  data: LocalMarketingYearPlan;
  deleteTask: (variables: { id: string; month?: number }) => Promise<void>;
  editTask: (variables: EditTaskVariables) => Promise<void>;
  readOnly?: boolean;
  setData: Dispatch<SetStateAction<LocalMarketingYearPlan>>;
  setNotification: Dispatch<SetStateAction<NotificationProps>>;
  updateYearPlan: (yearPlan: {
    months: { [key: string]: { tasks: YearPlanTask[] } };
  }) => Promise<void>;
  view: Views;
}

const QuarterView: FC<QuarterViewProps> = ({
  data,
  deleteTask,
  editTask,
  readOnly,
  setData,
  setNotification,
  updateYearPlan,
  view,
}) => {
  const months = getMonths(view);
  const tasksAlreadyAssignedToMonth = uniq(
    getListOfTasksAssignedToMonth(data?.marketingYearPlan?.months ?? {}),
  );

  const repeatTask = async ({
    monthId,
    ...task
  }: YearPlanTask & {
    monthId: number;
  }) => {
    const repeatMonthId = monthId + 1 <= 12 ? monthId + 1 : 1;
    const repeatMonth = data?.marketingYearPlan?.months?.[repeatMonthId];
    const newTasks = Array.from(repeatMonth?.tasks ?? []);
    newTasks.splice(newTasks.length, 0, task);
    const uniqueNewTasks = uniqBy(newTasks, "id");

    const newMonths = {
      ...data.marketingYearPlan?.months,
      [repeatMonthId]: {
        ...repeatMonth,
        tasks: uniqueNewTasks,
      },
    };

    setData({
      ...data,
      marketingYearPlan: {
        ...data.marketingYearPlan,
        months: newMonths,
      },
    });

    await updateYearPlan({ months: newMonths });

    setNotification({
      children: (
        <p>
          Task repeated for&nbsp;
          {toMonth(repeatMonthId)}
        </p>
      ),
      type: "success",
    });
  };

  return (
    <DragDropContext
      onDragEnd={async ({
        destination,
        source,
        draggableId: draggableIdWithMonth,
      }) => {
        if (!destination) {
          return;
        }

        if (
          destination.droppableId === source.droppableId &&
          destination.index === source.index
        ) {
          return;
        }

        const draggableIdParts = draggableIdWithMonth.split("-");
        const draggableId = draggableIdParts
          .slice(0, draggableIdParts.length - 1)
          .join("-");

        let newMonths = { ...data?.marketingYearPlan?.months };
        const newYearPlanOrder = Array.from(data?.yearPlanOrder ?? []);

        if (source.droppableId === "year") {
          newYearPlanOrder.splice(source.index, 1);
        }

        if (destination.droppableId === "year") {
          newYearPlanOrder.splice(destination.index, 0, draggableId);
        }

        const start = (data?.marketingYearPlan?.months ?? {})[
          source.droppableId
        ];
        const finish = (data?.marketingYearPlan?.months ?? {})[
          destination.droppableId
        ];

        if (start && finish && start === finish) {
          const newTasksOrder = Array.from(start?.tasks ?? []);
          const [task] = newTasksOrder.splice(source.index, 1);
          newTasksOrder.splice(destination.index, 0, task);

          newMonths = {
            ...newMonths,
            [destination.droppableId]: {
              tasks: uniqBy(newTasksOrder, "id"),
            },
          };
        } else {
          if (start) {
            const newStartTasksOrder = Array.from(start?.tasks ?? []);
            newStartTasksOrder.splice(source.index, 1);

            newMonths = {
              ...newMonths,
              [source.droppableId]: {
                tasks: uniqBy(newStartTasksOrder, "id"),
              },
            };
          }

          if (finish) {
            const task =
              source.droppableId === "year"
                ? data?.marketingYearPlan?.tasks?.[draggableId]
                : start.tasks.find(({ id }) => id === draggableId);

            if (task) {
              const newFinishTasksOrder = Array.from(finish?.tasks ?? []);
              newFinishTasksOrder.splice(destination.index, 0, task);
              newMonths = {
                ...newMonths,
                [destination.droppableId]: {
                  tasks: uniqBy(newFinishTasksOrder, "id"),
                },
              };
            }
          }
        }

        setData({
          ...data,
          marketingYearPlan: {
            ...data.marketingYearPlan,
            months: newMonths,
          },
          yearPlanOrder: uniqBy(newYearPlanOrder, "id"),
        });

        await updateYearPlan({ months: newMonths });
      }}
    >
      <Droppable direction="horizontal" droppableId="months" type="month">
        {({ droppableProps, innerRef, placeholder }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <ContentContainer ref={innerRef} {...droppableProps}>
            <Column
              canDelete={false}
              editable={false}
              id="year"
              index={0}
              readOnly={readOnly}
              title="Year Plan"
            >
              {data?.yearPlanOrder?.map((taskId, taskIndex) => (
                <Task
                  key={taskId}
                  description={
                    (data?.marketingYearPlan?.tasks ?? {})[taskId]
                      ?.description ?? ""
                  }
                  dueDate={
                    (data?.marketingYearPlan?.tasks ?? {})[taskId]?.dueDate ??
                    ""
                  }
                  faded={tasksAlreadyAssignedToMonth.includes(taskId)}
                  id={taskId}
                  index={taskIndex}
                  onDelete={deleteTask}
                  onEdit={editTask}
                  readOnly={readOnly}
                  repeat={
                    (data?.marketingYearPlan?.tasks ?? {})[taskId]?.repeat ??
                    "NONE"
                  }
                  status={
                    (data?.marketingYearPlan?.tasks ?? {})[taskId]?.status ??
                    "NEW"
                  }
                  title={
                    (data?.marketingYearPlan?.tasks ?? {})[taskId]?.title ?? ""
                  }
                />
              ))}
            </Column>
            {months.map((monthId, monthIndex) => (
              <Column
                key={monthId}
                canDelete={false}
                editable={false}
                id={monthId.toString()}
                index={monthIndex}
                readOnly={readOnly}
                title={toMonth(monthId)}
              >
                {(data?.marketingYearPlan?.months ?? {})[monthId].tasks?.map(
                  (task, taskIndex) => (
                    <Task
                      key={`${task.id}`}
                      description={task.description}
                      dueDate={task.dueDate}
                      id={task.id ?? ""}
                      index={taskIndex}
                      monthId={monthId}
                      onDelete={(vars) =>
                        deleteTask({ ...vars, month: monthId })
                      }
                      onEdit={async (vars) => {
                        const month =
                          data?.marketingYearPlan?.months?.[monthId];
                        const index = month?.tasks.findIndex(
                          ({ id }) => id === vars.id,
                        );

                        const newMonths = {
                          ...data.marketingYearPlan?.months,
                          [monthId]: {
                            ...month,
                            tasks: [
                              ...(month?.tasks ?? []).slice(0, taskIndex),
                              vars,
                              ...(month?.tasks ?? []).slice(taskIndex + 1),
                            ],
                          },
                        };

                        if (index !== undefined) {
                          setData({
                            ...data,
                            marketingYearPlan: {
                              ...data.marketingYearPlan,
                              months: newMonths,
                            },
                          });

                          await updateYearPlan({ months: newMonths });
                        }
                      }}
                      onRepeat={(vars) => repeatTask({ ...vars, monthId })}
                      readOnly={readOnly}
                      repeat={task.repeat ?? "NONE"}
                      status={task.status ?? "NEW"}
                      title={task.title ?? ""}
                      view={view}
                    />
                  ),
                )}
              </Column>
            ))}
            {placeholder}
          </ContentContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default QuarterView;
