import { gql } from "apollo-boost";

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      code
      message
    }
  }
`;

export interface ChangePasswordVariables {
  currentPassword: string;
  newPassword: string;
}

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $firstName: String!
    $lastName: String!
    $practiceName: String!
  ) {
    updateUser(
      firstName: $firstName
      lastName: $lastName
      practiceName: $practiceName
    ) {
      code
      message
    }
  }
`;

export interface UpdateUserVariables {
  firstName: string;
  lastName: string;
  practiceName: string;
}

export const UPLOAD_LOGO = gql`
  mutation UploadLogo($logo: Upload!) {
    uploadLogo(logo: $logo) {
      path
    }
  }
`;
