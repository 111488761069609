import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import CSAButton from "../components/CSAButton";
import CSALink from "../components/CSALink";
import CenteredContainer from "../components/CenteredContainer";
import FormItem from "../components/FormItem";
import HomeImageLink from "../components/HomeImageLink";
import Notification, { NotificationProps } from "../components/Notification";

const INITIATE_RESET_PASSWORD = gql`
  mutation InitiateResetPassword($email: String!) {
    initiateResetPassword(email: $email) {
      code
      message
    }
  }
`;

const ForgotPasswordNotification = FormItem.withComponent(Notification);
const LinkButton = CSAButton.withComponent(CSALink);

const ForgotPassword: FC = () => {
  const { register, handleSubmit } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [notification, setNotification] = useState<NotificationProps>({});
  const [initiateResetPassword] = useMutation(INITIATE_RESET_PASSWORD);

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      await initiateResetPassword({ variables: { email } });
      setSubmitted(true);
    } catch (e) {
      setNotification({
        children: (
          <p>
            Sorry we couldn&apos;t find this email.
            <br />
            <CSALink to="/signup">Sign Up</CSALink>
          </p>
        ),
        type: "error",
      });
    }
  });

  if (submitted) {
    return (
      <CenteredContainer>
        <HomeImageLink />
        <h1>Reset link sent!</h1>
        <p>You will receive an email with a reset password link.</p>
        <p>Please check your inbox and junk mail folders.</p>
        <LinkButton to="/login">Back to Log In</LinkButton>
      </CenteredContainer>
    );
  }

  return (
    <CenteredContainer>
      <HomeImageLink />
      <h1>Forgot your password?</h1>

      <ForgotPasswordNotification type={notification.type}>
        {notification.children}
      </ForgotPasswordNotification>

      <form onSubmit={onSubmit}>
        <FormItem
          ref={register}
          label="Email Address"
          name="email"
          placeholder="name@company.com"
          required
          type="email"
        />
        <CSAButton type="submit">Send me reset link</CSAButton>
      </form>
    </CenteredContainer>
  );
};

export default ForgotPassword;
