import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import CSAButton from "../../components/CSAButton";
import CopyToClipboard from "../../components/CopyToClipboard";
import ModalFormInput from "../../components/ModalFormInput";
import ModalFormLabel from "../../components/ModalFormLabel";
import ModalFormTitleInput from "../../components/ModalFormTitleInput";
import parseDate from "../../helpers/parse-date";
import { Referral } from "./types";

const Form = styled.form`
  min-width: 28rem;
`;

const Submit = styled(CSAButton)`
  min-width: 8rem;
`;

export interface EditReferralFormProps {
  onSubmit: (referral: Referral) => void;
  referral?: Partial<Referral>;
}

const EditReferralForm: FC<EditReferralFormProps> = ({
  onSubmit,
  referral,
}) => {
  const [initialDateOfService, setInitialDateOfService] = useState(
    referral?.initialDateOfService ?? "",
  );
  const [dateContacted, setDateContacted] = useState(
    referral?.dateContacted ?? "",
  );
  const { errors, handleSubmit, register, watch } = useForm<Referral>();

  return (
    <Form
      onSubmit={handleSubmit((variables) =>
        onSubmit({
          ...variables,
          dateContacted,
          initialDateOfService,
        }),
      )}
    >
      <ModalFormTitleInput
        ref={register({ required: true })}
        defaultValue={referral?.patientName}
        error={!!errors.patientName}
        name="patientName"
        placeholder="Patient Name"
        type="text"
      />

      <ModalFormLabel htmlFor="initialDateOfService">
        Initial Date of Service
        <DatePicker
          customInput={<ModalFormInput error={false} />}
          dateFormat="MM-dd-yyyy"
          isClearable
          onChange={(d) => {
            if (d) {
              setInitialDateOfService(d.toISOString());
              return;
            }

            setInitialDateOfService("");
          }}
          placeholderText="No Initial Date"
          selected={parseDate(initialDateOfService)}
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="referralSource">
        Referral Source
        <ModalFormInput
          ref={register}
          defaultValue={referral?.referralSource}
          error={!!errors.referralSource}
          name="referralSource"
          placeholder="No Source"
          type="text"
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="typeOfReferral">
        Type of Referral
        <ModalFormInput
          ref={register}
          defaultValue={referral?.typeOfReferral}
          error={!!errors.typeOfReferral}
          name="typeOfReferral"
          placeholder="No Type"
          type="text"
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="contactOfInterest">
        Contact of Interest
        <ModalFormInput
          ref={register}
          defaultValue={referral?.contactOfInterest}
          error={!!errors.contactOfInterest}
          name="contactOfInterest"
          placeholder="No Contact"
          type="text"
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="phone">
        Phone
        <CopyToClipboard text={watch("phone")}>
          <ModalFormInput
            ref={register}
            defaultValue={referral?.phone}
            error={!!errors.phone}
            name="phone"
            placeholder="No Phone"
            type="tel"
          />
        </CopyToClipboard>
      </ModalFormLabel>

      <ModalFormLabel htmlFor="email">
        Email
        <CopyToClipboard text={watch("email")}>
          <ModalFormInput
            ref={register}
            defaultValue={referral?.email}
            error={!!errors.email}
            name="email"
            placeholder="No Email"
            type="email"
          />
        </CopyToClipboard>
      </ModalFormLabel>

      <ModalFormLabel htmlFor="address">
        Address
        <CopyToClipboard text={watch("address")}>
          <ModalFormInput
            ref={register}
            defaultValue={referral?.address}
            error={!!errors.address}
            name="address"
            placeholder="No address"
            type="text"
          />
        </CopyToClipboard>
      </ModalFormLabel>

      <ModalFormLabel htmlFor="dateContacted">
        Date Contacted?
        <DatePicker
          customInput={<ModalFormInput error={false} />}
          dateFormat="MM-dd-yyyy"
          isClearable
          onChange={(d) => {
            if (d) {
              setDateContacted(d.toISOString());
              return;
            }

            setDateContacted("");
          }}
          placeholderText="No Date Contacted"
          selected={parseDate(dateContacted)}
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="outcome">
        Outcome
        <ModalFormInput
          ref={register}
          defaultValue={referral?.outcome}
          error={!!errors.outcome}
          name="outcome"
          placeholder="No Outcome"
          type="text"
        />
      </ModalFormLabel>

      <Submit type="submit">Save</Submit>
    </Form>
  );
};

export default EditReferralForm;
