import { useMutation } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { gql } from "apollo-boost";
import React, { FC, KeyboardEvent, useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import DeleteButton from "../../components/DeleteButton";
import { isGraphQLErrorCode } from "../../helpers/graphql";
import { Response } from "../../types";
import Td from "./Td";
import { Indicator } from "./indicator-scorecard";

const MIN_SCORE_VALUE = 0;
const MAX_SCORE_VALUE = 999999;

const UPDATE_INDICATOR = gql`
  mutation UpdateIndicator(
    $goals: String!
    $id: ID!
    $title: String!
    $who: String!
  ) {
    updateIndicator(goals: $goals, id: $id, title: $title, who: $who) {
      code
      message
    }
  }
`;

interface UpdateIndicatorVariables {
  goals: string;
  id: string;
  title: string;
  who: string;
}

const UPDATE_INDICATOR_SCORE = gql`
  mutation UpdateIndicatorScore(
    $day: Int!
    $id: ID!
    $month: Int!
    $score: Int!
  ) {
    updateIndicatorScore(day: $day, id: $id, month: $month, score: $score) {
      code
      message
    }
  }
`;

interface UpdateIndicatorScoreVariables {
  day: number;
  id: string;
  month: number;
  score: number;
}

const SaveButton = styled.button<{ state: string }>`
  background: none;
  border-radius: 0.25rem;
  border: 0.0625rem solid var(--csa-blue);
  color: var(--csa-blue);
  margin-left: 0.5rem;
  margin-top: -0.5rem;
  opacity: ${({ state }): number => (state === "entered" ? 1 : 0)};
  position: absolute;
  transition: all 200ms;

  &:hover {
    background: var(--csa-blue-2);
  }
`;

const ScoreTd = styled(Td)`
  min-width: 4rem;
  padding: 0;
  text-align: center;
`;

const SaveTd = styled.td`
  width: 1px;
`;

const Input = styled.input`
  background: none;
  border: none;
  display: inline-block;
  font-size: 0.875rem;
  outline: none;
  padding: 0;
  width: 90%;
`;

const ScoreInput = styled(Input)`
  text-align: center;
`;

const sanitizeScore = (score?: number) => {
  if (!score) {
    return MIN_SCORE_VALUE;
  }

  if (score < MIN_SCORE_VALUE) {
    return MIN_SCORE_VALUE;
  }

  if (score > MAX_SCORE_VALUE) {
    return MAX_SCORE_VALUE;
  }

  return score;
};

export interface IndicatorRowProps {
  editing: boolean;
  indicator: Indicator;
  onDelete: () => void;
  onUpdate: () => void;
  onUpdateError: (reason?: string) => void;
  readOnly?: boolean;
  weeks: Date[];
}

const IndicatorRow: FC<IndicatorRowProps> = ({
  editing,
  indicator,
  onDelete,
  onUpdate,
  onUpdateError,
  readOnly,
  weeks,
}) => {
  const [showSaveBtn, setShowSaveBtn] = useState(false);

  // Fields
  const [who, setWho] = useState(indicator.who ?? "");
  const [title, setTitle] = useState(indicator.title ?? "");
  const [goals, setGoals] = useState(indicator.goals ?? "");
  const [scores, setScores] = useState(indicator.scores ?? {});

  useEffect(() => {
    if (indicator) {
      setWho(indicator.who ?? "");
      setTitle(indicator.title ?? "");
      setGoals(indicator.goals ?? "");
      setScores(indicator.scores ?? {});
    }
  }, [indicator]);

  const [updateIndicator] = useMutation<Response, UpdateIndicatorVariables>(
    UPDATE_INDICATOR,
  );

  const [updateScore] = useMutation<Response, UpdateIndicatorScoreVariables>(
    UPDATE_INDICATOR_SCORE,
  );

  const handleChange = () => {
    setShowSaveBtn(true);
  };

  const saveRow = async () => {
    if (!showSaveBtn) {
      return;
    }

    try {
      if (
        goals !== indicator.goals ||
        title !== indicator.title ||
        who !== indicator.who
      ) {
        await updateIndicator({
          variables: {
            goals,
            id: indicator.id ?? "",
            title,
            who,
          },
        });
      }

      await Promise.all(
        Object.keys(scores).map(async (key) => {
          if (scores[key]?.score !== indicator?.scores?.[key]?.score) {
            if (indicator.id && scores[key].day && scores[key].month) {
              await updateScore({
                variables: {
                  day: scores[key].day ?? 0,
                  id: indicator.id,
                  month: scores[key].month ?? 0,
                  score: sanitizeScore(scores[key]?.score),
                },
              });
            }
          }
        }),
      );

      setShowSaveBtn(false);
      onUpdate();
    } catch (e) {
      if (isGraphQLErrorCode(e, "BAD_USER_INPUT")) {
        onUpdateError("Field can't be empty");
      } else {
        onUpdateError();
      }
    }
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      saveRow();
    }
  };

  return (
    <tr>
      {editing && (
        <td aria-label="delete">
          <DeleteButton onClick={onDelete} />
        </td>
      )}
      <Td>
        <Input
          maxLength={80}
          name="who"
          onChange={(e) => {
            setWho(e.target.value);
            handleChange();
          }}
          onKeyPress={handleEnter}
          readOnly={readOnly}
          required
          type="text"
          value={who}
        />
      </Td>
      <Td>
        <Input
          maxLength={80}
          name="title"
          onChange={(e) => {
            setTitle(e.target.value);
            handleChange();
          }}
          onKeyPress={handleEnter}
          readOnly={readOnly}
          required
          size={indicator?.title?.length}
          type="text"
          value={title}
        />
      </Td>
      <Td>
        <Input
          maxLength={80}
          name="goals"
          onChange={(e) => {
            setGoals(e.target.value);
            handleChange();
          }}
          onKeyPress={handleEnter}
          readOnly={readOnly}
          required
          type="text"
          value={goals}
        />
      </Td>
      {weeks.map((week) => {
        const key = `${week.getMonth() + 1}-${week.getDate()}`;
        return (
          <ScoreTd key={key}>
            <ScoreInput
              max={MAX_SCORE_VALUE}
              min={MIN_SCORE_VALUE}
              onChange={(e) => {
                setScores({
                  ...scores,
                  [key]: {
                    ...scores[key],
                    day: week.getDate(),
                    month: week.getMonth() + 1,
                    score: parseInt(e.target.value, 10),
                  },
                });
                handleChange();
              }}
              onKeyPress={handleEnter}
              readOnly={readOnly}
              required
              type="number"
              value={scores[key]?.score ?? MIN_SCORE_VALUE}
            />
          </ScoreTd>
        );
      })}
      <Transition in={showSaveBtn} timeout={0}>
        {(state): React.ReactNode => (
          <SaveTd>
            <SaveButton onClick={saveRow} state={state}>
              Save
            </SaveButton>
          </SaveTd>
        )}
      </Transition>
    </tr>
  );
};

export default IndicatorRow;
