import { gql } from "apollo-boost";

export const GET_ANALYTICS = gql`
  query GetAnalytics(
    $aggregateEnd: String!
    $aggregateStart: String!
    $month: String!
  ) {
    analytics(
      aggregateEnd: $aggregateEnd
      aggregateStart: $aggregateStart
      month: $month
    ) {
      aggregates {
        monthlyAverageNewPatients
        monthlyAverageOfficeVisits
        monthlyAveragePatientCollections
        months {
          id
          newPatients
          officeVisits
          patientCollections
          year
        }
        newPatients
        officeVisits
        patientCollections
        runningNewPatientValue
        runningOfficeVisitAverage
        runningPatientVisitAverage
      }
      month {
        id
        newPatients
        officeVisits
        patientCollections
        year
      }
    }
  }
`;

export interface GetAnalyticsData {
  analytics?: {
    aggregates?: {
      monthlyAverageNewPatients?: number;
      monthlyAverageOfficeVisits?: number;
      monthlyAveragePatientCollections?: number;
      months?: Array<{
        id?: string;
        newPatients?: number;
        officeVisits?: number;
        patientCollections?: number;
        year?: number;
      }>;
      newPatients?: number;
      officeVisits?: number;
      patientCollections?: number;
      runningNewPatientValue?: number;
      runningOfficeVisitAverage?: number;
      runningPatientVisitAverage?: number;
    };
    month?: {
      id?: string;
      newPatients?: number;
      officeVisits?: number;
      patientCollections?: number;
      year?: number;
    };
  };
}

export interface GetAnalyticsVariables {
  aggregateEnd: string;
  aggregateStart: string;
  month: string;
}

export const UPDATE_ANALYTICS_MONTH = gql`
  mutation UpdateAnalyticsMonth(
    $data: AnalyticsMonthInput!
    $month: Int!
    $year: Int!
  ) {
    updateAnalyticsMonth(data: $data, month: $month, year: $year) {
      code
      message
    }
  }
`;

export interface UpdateAnalyticsMonthVariablesData {
  newPatients?: number;
  officeVisits?: number;
  patientCollections?: number;
}

export interface UpdateAnalyticsMonthVariables {
  data: UpdateAnalyticsMonthVariablesData;
  month: number;
  year: number;
}
