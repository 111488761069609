/** @jsxRuntime classic */
/** @jsx jsx */
import { useMutation, useQuery } from "@apollo/react-hooks";
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import React, { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Notification, { NotificationProps } from "../../components/Notification";
import currencyFormatter from "../../helpers/currency-formatter";
import formatMonth from "../../helpers/format-month";
import stringToNumber from "../../helpers/string-to-number";
import useRecallLastView from "../../hooks/use-recall-last-view";
import { CurrentUser } from "../../queries";
import BarChart from "./BarChart";
import Block from "./Block";
import MonthInput from "./MonthInput";
import Value from "./Value";
import {
  GET_ANALYTICS,
  GetAnalyticsData,
  GetAnalyticsVariables,
  UPDATE_ANALYTICS_MONTH,
  UpdateAnalyticsMonthVariables,
  UpdateAnalyticsMonthVariablesData,
} from "./graphql";
import "react-datepicker/dist/react-datepicker.css";

const Section = styled.section`
  display: grid;
  font-size: 0.875rem;
  font-weight: bold;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
`;

const Heading = styled.h1`
  font-size: 1.5rem;
  text-align: left;
`;

const Hr = styled.hr`
  margin: 0;
`;

const MonthHeader = styled.header`
  display: flex;
  justify-content: space-around;
  font-size: 0.875rem;

  & > * {
    flex: 0 0 auto;
  }
`;

const MonthChangeButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  color: var(--csa-blue);
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;

const fullGridWidth = css`
  grid-column: 1 / 4;
`;

const DatePickerButton = styled.button`
  appearance: none;
  background: none;
  border: 0.0625rem solid var(--csa-border);
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
`;

const DateRangePickerButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  padding: 0.75rem 1rem;
`;

const RangePicker = styled.div`
  align-items: center;
  appearance: none;
  border-radius: 0.25rem;
  border: 0.0625rem solid var(--csa-border);
  display: flex;
  justify-self: start;
`;

const CustomDatePickerInput = React.forwardRef<
  HTMLButtonElement,
  { label: string; value: string; onClick: () => void }
>(({ label, value, onClick }, ref) => (
  <DatePickerButton ref={ref} onClick={onClick} type="button">
    {label}
    &nbsp;
    {value}
  </DatePickerButton>
));

const CustomDateRangePickerInput = React.forwardRef<
  HTMLButtonElement,
  { label: string; value: string; onClick: () => void }
>(({ label, value, onClick }, ref) => (
  <DateRangePickerButton ref={ref} onClick={onClick} type="button">
    {label}
    &nbsp;
    {value}
  </DateRangePickerButton>
));

const debouncedUpdateMonth = debounce(
  async ({ updateMonth, refetch, variables }) => {
    await updateMonth({ variables });
    refetch();
  },
  500,
);

export interface AnalyticsProps {
  currentUser?: CurrentUser;
}

const Analytics: FC<AnalyticsProps> = ({ currentUser }) => {
  const [notification, setNotification] = useState<NotificationProps>({});
  const [aggregateStartDate, setAggregateStartDate] = useState(new Date());
  const [aggregateEndDate, setAggregateEndDate] = useState(new Date());
  const [date, setDate] = useState(new Date());

  const changeMonth = (direction: "previous" | "next") => {
    if (direction === "previous") {
      setDate(new Date(date.setMonth(date.getMonth() - 1)));
    } else {
      setDate(new Date(date.setMonth(date.getMonth() + 1)));
    }
  };

  useRecallLastView(
    "analytics-view",
    { aggregateEndDate, aggregateStartDate, date },
    {
      setAggregateEndDate: (d) => setAggregateEndDate(new Date(d)),
      setAggregateStartDate: (d) => setAggregateStartDate(new Date(d)),
      setDate: (d) => setDate(new Date(d)),
    },
  );

  const {
    data,
    error: queryError,
    loading: queryLoading,
    refetch,
  } = useQuery<GetAnalyticsData, GetAnalyticsVariables>(GET_ANALYTICS, {
    fetchPolicy: "no-cache",
    variables: {
      aggregateEnd: `${aggregateEndDate.getFullYear()}-${formatMonth(
        aggregateEndDate,
      )}`,
      aggregateStart: `${aggregateStartDate.getFullYear()}-${formatMonth(
        aggregateStartDate,
      )}`,
      month: `${date.getFullYear()}-${formatMonth(date)}`,
    },
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const [
    updateMonthMutation,
    { error: mutationError, loading: mutationLoading },
  ] = useMutation<Response, UpdateAnalyticsMonthVariables>(
    UPDATE_ANALYTICS_MONTH,
  );

  const error = queryError || mutationError;

  useEffect(() => {
    if (error) {
      setNotification({
        children: <p>Sorry something went wrong. Please try again.</p>,
        type: "error",
      });
    }
  }, [error]);

  const loading = queryLoading || mutationLoading;

  const updateMonth = async (
    key: keyof UpdateAnalyticsMonthVariablesData,
    value: number | undefined,
  ) => {
    const month = data?.analytics?.month?.id;
    const year = data?.analytics?.month?.year;

    const map = ({
      newPatients,
      officeVisits,
      patientCollections,
    }: UpdateAnalyticsMonthVariablesData) => ({
      newPatients,
      officeVisits,
      patientCollections,
    });

    if (month && year) {
      await debouncedUpdateMonth({
        refetch,
        updateMonth: updateMonthMutation,
        variables: {
          data: {
            ...map(data?.analytics?.month ?? {}),
            [key]: value,
          },
          month: parseInt(month, 10),
          year,
        },
      });
    }
  };

  const updateRange = (source: "start" | "end", value: Date | null) => {
    if (value) {
      if (source === "start") {
        if (value > aggregateEndDate) {
          setAggregateEndDate(value);
        }

        setAggregateStartDate(value);
        return;
      }

      if (source === "end") {
        if (value < aggregateStartDate) {
          setAggregateStartDate(value);
        }

        setAggregateEndDate(value);
      }
    }
  };

  return (
    <>
      <Notification fadeOut float type={notification.type} width="100%">
        {notification.children}
      </Notification>

      <Section>
        <Heading css={fullGridWidth}>Your Practice Analytics</Heading>

        <RangePicker>
          <DatePicker
            customInput={<CustomDateRangePickerInput label="From:" />}
            dateFormat="MMM yyyy"
            endDate={aggregateEndDate}
            onChange={(d) => updateRange("start", d)}
            selected={aggregateStartDate}
            selectsStart
            showMonthYearPicker
            startDate={aggregateStartDate}
          />
          <FontAwesomeIcon icon={faChevronRight} />
          <DatePicker
            customInput={<CustomDateRangePickerInput label="To:" />}
            dateFormat="MMM yyyy"
            endDate={aggregateEndDate}
            onChange={(d) => updateRange("end", d)}
            selected={aggregateEndDate}
            selectsEnd
            showMonthYearPicker
            startDate={aggregateStartDate}
          />
        </RangePicker>

        <BarChart
          css={fullGridWidth}
          id="new-patients-chart"
          monthlyAverage={
            data?.analytics?.aggregates?.monthlyAverageNewPatients?.toFixed(
              1,
            ) ?? "0.0"
          }
          months={
            data?.analytics?.aggregates?.months?.map(({ id, newPatients }) => ({
              id,
              value: newPatients,
            })) ?? []
          }
          title="New Patients"
          yaxisLabelFormatter={(value) => value.toFixed(0)}
        />

        <BarChart
          css={fullGridWidth}
          id="office-visits-chart"
          monthlyAverage={
            data?.analytics?.aggregates?.monthlyAverageOfficeVisits?.toFixed(
              1,
            ) ?? "0.0"
          }
          months={
            data?.analytics?.aggregates?.months?.map(
              ({ id, officeVisits }) => ({
                id,
                value: officeVisits,
              }),
            ) ?? []
          }
          title="Office Visits"
          yaxisLabelFormatter={(value) => value.toFixed(0)}
        />

        <BarChart
          css={fullGridWidth}
          id="patient-collections-chart"
          monthlyAverage={currencyFormatter(
            data?.analytics?.aggregates?.monthlyAveragePatientCollections ?? 0,
          )}
          months={
            data?.analytics?.aggregates?.months?.map(
              ({ id, patientCollections }) => ({
                id,
                value: patientCollections,
              }),
            ) ?? []
          }
          title="Patient Collections"
          yaxisLabelFormatter={(value) => currencyFormatter(value)}
        />

        <Block loading={loading} title="Running Patient Visit Average">
          <Value>
            {data?.analytics?.aggregates?.runningPatientVisitAverage?.toFixed(
              1,
            )}
          </Value>
        </Block>

        <Block loading={loading} title="Running Office Visit Average">
          <Value>
            {currencyFormatter(
              data?.analytics?.aggregates?.runningOfficeVisitAverage,
            )}
          </Value>
        </Block>

        <Block loading={loading} title="Running New Patient Value">
          <Value>
            {currencyFormatter(
              data?.analytics?.aggregates?.runningNewPatientValue,
            )}
          </Value>
        </Block>

        <Hr css={fullGridWidth} />

        <MonthHeader css={fullGridWidth}>
          <MonthChangeButton
            onClick={() => changeMonth("previous")}
            type="button"
          >
            Prev
          </MonthChangeButton>
          <div>
            <DatePicker
              customInput={<CustomDatePickerInput label="Month:" />}
              dateFormat="MMM yyyy"
              onChange={(d) => d && setDate(d)}
              selected={date}
              showMonthYearPicker
            />
          </div>
          <MonthChangeButton onClick={() => changeMonth("next")} type="button">
            Next
          </MonthChangeButton>
        </MonthHeader>

        <Block loading={loading} title="New Patients">
          <MonthInput
            defaultValue={data?.analytics?.month?.newPatients ?? ""}
            formatter={(v) => v.replace(/[^\d]/g, "")}
            onUpdate={async (value) => {
              await updateMonth(
                "newPatients",
                parseInt(value, 10) >= 0 ? parseInt(value, 10) : undefined,
              );
            }}
            readOnly={!currentUser?.active}
          />
        </Block>

        <Block loading={loading} title="Office Visits">
          <MonthInput
            defaultValue={data?.analytics?.month?.officeVisits ?? ""}
            formatter={(v) => v.replace(/[^\d]/g, "")}
            onUpdate={async (value) => {
              await updateMonth(
                "officeVisits",
                parseInt(value, 10) >= 0 ? parseInt(value, 10) : undefined,
              );
            }}
            readOnly={!currentUser?.active}
          />
        </Block>

        <Block loading={loading} title="Patient Collections">
          <MonthInput
            defaultValue={
              (data?.analytics?.month?.patientCollections &&
                data.analytics.month.patientCollections / 100) ??
              ""
            }
            formatter={(v) =>
              stringToNumber(v) >= 0
                ? currencyFormatter(stringToNumber(v) * 100)
                : ""
            }
            onUpdate={async (value) => {
              await updateMonth(
                "patientCollections",
                stringToNumber(value) >= 0
                  ? Math.round(stringToNumber(value) * 100)
                  : undefined,
              );
            }}
            readOnly={!currentUser?.active}
          />
        </Block>
      </Section>
    </>
  );
};

export default Analytics;
