import styled from "@emotion/styled";
import React, { FC, useEffect, useState } from "react";
import { Transition } from "react-transition-group";

export interface NetworkErrorToasterProps {
  showTimestamp: number;
}

const Container = styled.div<{ state: string }>`
  background-color: var(--csa-darkgrey);
  bottom: 0;
  color: #fff;
  left: 50%;
  opacity: ${({ state }): number => (state === "entered" ? 1 : 0)};
  padding: 0.5rem 3rem;
  position: absolute;
  transform: ${({ state }): string =>
    state === "entered" ? "translate(-50%, 0%)" : "translate(-50%, 100%)"};
  transition: all 100ms;
`;

const NetworkErrorToaster: FC<NetworkErrorToasterProps> = ({
  showTimestamp,
}) => {
  const [autoHide, setAutoHide] = useState(false);

  useEffect(() => {
    if (showTimestamp) {
      setAutoHide(false);
      setTimeout(() => setAutoHide(true), 2000);
    }
  }, [showTimestamp]);

  if (!showTimestamp) {
    return null;
  }

  return (
    <Transition
      appear={!autoHide && !!showTimestamp}
      in={!autoHide && !!showTimestamp}
      timeout={500}
    >
      {(state): React.ReactNode => (
        <Container state={state}>
          <p>Connection Error</p>
        </Container>
      )}
    </Transition>
  );
};

export default NetworkErrorToaster;
