import styled from "@emotion/styled";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import Modal from "../../components/Modal";
import useCancelOnEsc from "../../hooks/use-cancel-on-esc";
import { TaskStatus, Task as TaskType } from "../../types";
import { Views } from "./Nav";
import TaskForm from "./TaskForm";
import { EditTaskVariables, YearPlanTask } from "./graphql";

const Li = styled.li<{ faded?: boolean; status: TaskStatus }>`
  border-radius: 0.3125rem;
  border-style: solid;
  border-width: 0.0625rem;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  padding: 1rem 0.5rem;
  position: relative;

  ${({ faded }) => faded && "opacity: 0.5;"}

  ${({ status }) => {
    switch (status) {
      case "PROGRESS":
        return `
      background: var(--csa-blue-2);
      border-color: var(--csa-blue);
      `;
      case "COMPLETED":
        return `
      background: var(--csa-green-2);
      border-color: var(--csa-green);
      `;
      case "HOLD":
        return `
      background: var(--csa-orange-2);
      border-color: var(--csa-orange);
      `;
      case "CANCELLED":
        return `
      background: var(--csa-red-2);
      border-color: var(--csa-red);
      `;

      default:
        return `
      background: var(--csa-bg-secondary);
      border-color: var(--csa-border);
      `;
    }
  }}
`;

const Title = styled.p`
  font-weight: bold;
  margin: 0 0 1rem;
`;

const Line = styled.p`
  margin: 0;
  text-transform: capitalize;
`;

const RepeatButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  position: absolute;
  right: 0;
  top: 2.25rem;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    transition: all 0.1s;
  }
`;

export interface TaskProps extends TaskType {
  faded?: boolean;
  id: string;
  index: number;
  monthId?: number;
  onDelete: (variables: { id: string }) => Promise<void>;
  onEdit: (variables: EditTaskVariables) => Promise<void>;
  onRepeat?: (variables: YearPlanTask) => Promise<void>;
  readOnly?: boolean;
  view?: Views;
}

const Task: FC<TaskProps> = ({
  description,
  dueDate,
  faded,
  id,
  index,
  monthId,
  onDelete,
  onEdit,
  onRepeat,
  readOnly,
  repeat,
  status,
  title,
  view,
}) => {
  const [editing, setEditing] = useState(false);

  useCancelOnEsc({ onCancel: () => setEditing(false) });

  return (
    <>
      <Draggable
        draggableId={`${id}-${monthId}`}
        index={index}
        isDragDisabled={readOnly}
      >
        {({ dragHandleProps, draggableProps, innerRef: draggableInnerRef }) => (
          <Li
            ref={draggableInnerRef}
            faded={faded}
            onClick={() => {
              if (!readOnly) setEditing(true);
            }}
            status={status}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...dragHandleProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...draggableProps}
          >
            <Title>{title}</Title>
            <Line>{`Repeat: ${repeat.toLowerCase()}`}</Line>
            {view && view !== "year" && monthId !== 12 && (
              <RepeatButton
                onClick={async (e) => {
                  e.stopPropagation();

                  if (onRepeat) {
                    await onRepeat({
                      description,
                      dueDate,
                      id,
                      repeat,
                      status,
                      title,
                    });
                  }
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
                &nbsp;Repeat
              </RepeatButton>
            )}
          </Li>
        )}
      </Draggable>
      <Modal onClose={() => setEditing(false)} show={editing}>
        <TaskForm
          deleteable
          onDelete={async () => {
            await onDelete({ id });
            setEditing(false);
          }}
          onSubmit={async (task) => {
            await onEdit({ id, ...task });
            setEditing(false);
          }}
          submitButtonMsg="Save Changes"
          task={{
            description,
            dueDate,
            repeat,
            status,
            title,
          }}
        />
      </Modal>
    </>
  );
};

export default Task;
