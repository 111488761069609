import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import React, { FC, useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import CSAButton from "../../components/CSAButton";
import CSASelector from "../../components/CSASelector";
import Modal from "../../components/Modal";
import Notification, { NotificationProps } from "../../components/Notification";
import YearSelector from "../../components/YearSelector";
import useRecallLastView from "../../hooks/use-recall-last-view";
import { CurrentUser } from "../../queries";
import { Response } from "../../types";
import Content from "./Content";
import EditContentForm from "./EditContentForm";
import {
  ADD_EDITORIAL_CONTENT,
  AddEditorialContentVariables,
  DELETE_EDITORIAL_CONTENT,
  DeleteEditorialContentVariables,
  EDIT_EDITORIAL_CONTENT,
  EditEditorialContentVariables,
  GET_EDITORIAL_CALENDAR,
  GetEditorialCalendarData,
  GetEditorialCalendarVariables,
  REORDER_EDITORIAL_CONTENT,
  ReorderContentVariables,
} from "./queries";

const Section = styled.section`
  position: relative;
`;

const H1 = styled.h1`
  margin-bottom: 2rem;
`;

const CalendarMonthSelector = styled(CSASelector)`
  position: absolute;
  right: 9rem;
  top: 0;
  z-index: 1;
`;

const CalendarYearSelector = styled(YearSelector)`
  position: absolute;
  right: 0;
  top: 0;
`;

type MonthKeys =
  | "Jan"
  | "Feb"
  | "Mar"
  | "Apr"
  | "May"
  | "Jun"
  | "Jul"
  | "Aug"
  | "Sep"
  | "Oct"
  | "Nov"
  | "Dec";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const monthNameToMonthId = (name: MonthKeys): number =>
  ({
    Jan: 1,
    // eslint-disable-next-line sort-keys
    Feb: 2,
    Mar: 3,
    // eslint-disable-next-line sort-keys
    Apr: 4,
    May: 5,
    // eslint-disable-next-line sort-keys
    Jun: 6,
    // eslint-disable-next-line sort-keys
    Jul: 7,
    // eslint-disable-next-line sort-keys
    Aug: 8,
    Sep: 9,
    // eslint-disable-next-line sort-keys
    Oct: 10,
    // eslint-disable-next-line sort-keys
    Nov: 11,
    // eslint-disable-next-line sort-keys
    Dec: 12,
  }[name]);

const monthIdToMonthName = (id: number): string => months[id - 1];

export interface EditorialCalendarProps {
  currentUser?: CurrentUser;
}

const EditorialCalendar: FC<EditorialCalendarProps> = ({ currentUser }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [notification, setNotification] = useState<NotificationProps>({});
  const [isAdding, setIsAdding] = useState(false);
  // Used for better animation of reordering
  const [data, setData] = useState<GetEditorialCalendarData>();

  const { data: remoteData, refetch } = useQuery<
    GetEditorialCalendarData,
    GetEditorialCalendarVariables
  >(GET_EDITORIAL_CALENDAR, {
    fetchPolicy: "cache-and-network",
    variables: { month, year },
  });

  useEffect(() => {
    if (remoteData) {
      setData(remoteData);
    }
  }, [remoteData]);

  const [addEditorialContent] = useMutation<
    Response,
    AddEditorialContentVariables
  >(ADD_EDITORIAL_CONTENT, {
    onCompleted: () =>
      setNotification({
        children: <p>Added successfully</p>,
        type: "success",
      }),
    onError: () =>
      setNotification({
        children: <p>Sorry something went wrong</p>,
        type: "error",
      }),
    refetchQueries: [
      { query: GET_EDITORIAL_CALENDAR, variables: { month, year } },
    ],
  });

  const [deleteContent] = useMutation<
    Response,
    DeleteEditorialContentVariables
  >(DELETE_EDITORIAL_CONTENT, {
    onCompleted: () =>
      setNotification({
        children: <p>Deleted successfully</p>,
        type: "success",
      }),
    onError: () =>
      setNotification({
        children: <p>Sorry something went wrong</p>,
        type: "error",
      }),
    refetchQueries: [
      { query: GET_EDITORIAL_CALENDAR, variables: { month, year } },
    ],
  });

  const [editContent] = useMutation<Response, EditEditorialContentVariables>(
    EDIT_EDITORIAL_CONTENT,
    {
      onCompleted: () =>
        setNotification({
          children: <p>Updated successfully</p>,
          type: "success",
        }),
      onError: () =>
        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        }),
      refetchQueries: [
        { query: GET_EDITORIAL_CALENDAR, variables: { month, year } },
      ],
    },
  );

  const [reorderContent] = useMutation<Response, ReorderContentVariables>(
    REORDER_EDITORIAL_CONTENT,
    {
      onCompleted: () =>
        setNotification({
          children: <p>Updated successfully</p>,
          type: "success",
        }),
      onError: () =>
        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        }),
      refetchQueries: [
        { query: GET_EDITORIAL_CALENDAR, variables: { month, year } },
      ],
    },
  );

  useRecallLastView(
    "editorial-calendar-view",
    { month, year },
    { setMonth, setYear },
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year]);

  return (
    <>
      <Notification fadeOut float type={notification.type} width="100%">
        {notification.children}
      </Notification>

      <Section>
        <H1>Editorial Calendar</H1>

        <CalendarMonthSelector
          items={months.map((m) => ({ id: m, value: m }))}
          label="Month:"
          onSelect={(m) => setMonth(monthNameToMonthId(m.id as MonthKeys))}
          value={monthIdToMonthName(month)}
        />
        <CalendarYearSelector
          availableYears={data?.editorialCalendar?.availableYears}
          setYear={setYear}
          year={year}
        />

        <DragDropContext
          onDragEnd={async ({ destination, draggableId, source }) => {
            if (!destination) {
              return;
            }

            if (
              destination.droppableId === source.droppableId &&
              destination.index === source.index
            ) {
              return;
            }

            const newContent = Array.from(
              data?.editorialCalendar?.content ?? [],
            );
            const [content] = newContent.splice(source.index, 1);
            newContent.splice(destination.index, 0, content);

            setData({
              ...data,
              editorialCalendar: {
                ...data?.editorialCalendar,
                content: newContent,
              },
            });

            await reorderContent({
              variables: {
                destination: { index: destination.index },
                id: draggableId,
                month,
                source: { index: source.index },
                year,
              },
            });
          }}
        >
          <Droppable direction="vertical" droppableId="editorial-calendar">
            {({ droppableProps, innerRef, placeholder }) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <ul ref={innerRef} {...droppableProps}>
                {data?.editorialCalendar?.content?.map((content, index) => (
                  <Content
                    key={content.id}
                    content={content}
                    index={index}
                    onDelete={(variables) =>
                      deleteContent({
                        variables: { ...variables, month, year },
                      })
                    }
                    onEdit={async (variables) => {
                      await editContent({ variables });
                    }}
                    readOnly={!currentUser?.active}
                  />
                ))}
                {placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>

        <CSAButton
          disabled={!currentUser?.active}
          onClick={() => setIsAdding(true)}
          type="button"
        >
          Add Content
        </CSAButton>
      </Section>

      <Modal onClose={() => setIsAdding(false)} show={isAdding}>
        <EditContentForm
          onSubmit={async (variables) => {
            await addEditorialContent({
              variables: { ...variables, month, year },
            });
            setIsAdding(false);
          }}
        />
      </Modal>
    </>
  );
};

export default EditorialCalendar;
