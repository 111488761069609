import styled from "@emotion/styled";
import "react-placeholder/lib/reactPlaceholder.css";
import React, { FC, LegacyRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactPlaceholder from "react-placeholder/lib";
import BlockContainer from "../../components/BlockContainer";
import DataCell from "../../components/DataCell";
import DeleteButton from "../../components/DeleteButton";
import EditControls from "../../components/EditControls";
import HeaderCell from "../../components/HeaderCell";
import MinimalInput from "../../components/MinimalInput";
import useCancelOnEsc from "../../hooks/use-cancel-on-esc";
import Total from "./Total";

const TableBlock = styled(BlockContainer)`
  overflow: visible;
`;

const StyledTable = styled.table`
  table-layout: fixed;
  width: 100%;
`;

const Caption = styled.div`
  position: relative;
  font-weight: bold;
  text-align: left;
  margin-bottom: 1rem;
`;

const CaptionText = styled.span`
  display: inline-block;
  max-width: 75%;
`;

const Row = styled.tr`
  & > *:last-child {
    text-align: right;
  }
`;

const EditTh = styled.th`
  width: 2rem;
`;

const Cell = styled(DataCell)<{ editable?: boolean }>`
  color: ${({ editable }) =>
    editable ? "var(--csa-blue)" : "var(--csa-text-primary)"};
  word-break: break-word;

  &:hover {
    ${({ editable }) => (editable ? "cursor: pointer;" : "")}
  }
`;

const CloseCell = styled.td`
  padding: 0.5rem 0;
`;

const EditableCellForm = styled.form`
  display: flex;
  justify-content: space-between;
`;

const Form = styled.form<{ numberOfItems: number }>`
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin: 0 -0.25rem;

  & > * {
    flex-basis: calc(90% / ${({ numberOfItems }) => numberOfItems});
    max-width: calc(90% / ${({ numberOfItems }) => numberOfItems});
    margin: 0 0.25rem;
  }
`;

const Select = MinimalInput.withComponent("select");

const DateSelectContainer = styled.span`
  display: flex;
  flex: 1 0 auto;
`;

const SubmitButton = styled.input`
  font-family: FontAwesome;
  background: none;
  border: none;
  color: var(--csa-blue);
  flex: 0 0 auto !important;
  margin-left: 0.25rem;
  padding: 0;
`;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const formatToDoubleDigitDay = (day: number): string =>
  day < 10 ? `0${day}` : `${day}`;

const getDaysForMonth = (year: number, month: number): string[] => {
  const days = new Date(year, month, 0).getDate();
  return [...new Array(days)].map((_, i) => formatToDoubleDigitDay(i + 1));
};

const parseDate = ({
  day,
  month,
  year,
}: {
  day: string;
  month: string;
  year: string;
}) => {
  if (!day || !month || !year) {
    return "";
  }

  return `${year}-${month}-${day}`;
};

const DateInput: FC<{
  day?: string;
  month?: string;
  register: LegacyRef<HTMLSelectElement>;
  selectedMonth: string;
  year: number;
}> = ({ day = "01", month = "01", register, selectedMonth, year }) => (
  <DateSelectContainer>
    <Select ref={register} defaultValue={month} error={false} name="month">
      <option value="01">Jan</option>
      <option value="02">Feb</option>
      <option value="03">Mar</option>
      <option value="04">Apr</option>
      <option value="05">May</option>
      <option value="06">Jun</option>
      <option value="07">Jul</option>
      <option value="08">Aug</option>
      <option value="09">Sep</option>
      <option value="10">Oct</option>
      <option value="11">Nov</option>
      <option value="12">Dec</option>
    </Select>

    <Select ref={register} defaultValue={day} error={false} name="day">
      {getDaysForMonth(year, parseInt(selectedMonth ?? "01", 10)).map((v) => (
        <option key={v} value={v}>
          {v}
        </option>
      ))}
    </Select>
  </DateSelectContainer>
);

const EditableCell: FC<{
  editable: boolean;
  loading: boolean;
  name: string;
  onEdit: (value: string) => void;
  value: string;
  year: number;
}> = ({ editable, loading, name, onEdit, value, year }) => {
  const [isEditingCell, setIsEditingCell] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm();
  const selectedMonth = watch("month");

  useCancelOnEsc({ onCancel: () => setIsEditingCell(false) });

  const formatValue = () => {
    if (name === "date") {
      const date = new Date(value);

      return `${months[date.getUTCMonth()].substr(
        0,
        3,
      )} ${formatToDoubleDigitDay(date.getUTCDate())}`;
    }

    return value;
  };

  return (
    <Cell
      editable={editable}
      onClick={() => {
        if (editable) setIsEditingCell(true);
      }}
    >
      <ReactPlaceholder
        delay={150}
        ready={!loading}
        showLoadingAnimation
        style={{ width: `${Math.random() * 20 + 80}%` }}
        type="textRow"
      >
        {isEditingCell ? (
          <EditableCellForm
            onSubmit={handleSubmit((values) => {
              onEdit(
                name === "date"
                  ? parseDate({
                      day: values.day,
                      month: values.month,
                      year: `${year}`,
                    })
                  : values[name],
              );
              setIsEditingCell(false);
            })}
          >
            {name === "date" ? (
              <>
                <DateInput
                  day={value.split("-")[2]}
                  month={value.split("-")[1]}
                  register={register}
                  selectedMonth={selectedMonth ?? value.split("-")[1]}
                  year={year}
                />
                <SubmitButton type="submit" value="&#xf00c;" />
              </>
            ) : (
              <MinimalInput
                ref={register}
                defaultValue={value}
                error={errors[name]}
                maxLength={80}
                name={name}
              />
            )}
          </EditableCellForm>
        ) : (
          formatValue()
        )}
      </ReactPlaceholder>
    </Cell>
  );
};

export interface TableProps {
  caption: string;
  data: {
    id: { editable: boolean; value: string };
    [key: string]: { editable: boolean; value: string };
  }[];
  editable?: boolean;
  headers: { key: string; name: string }[];
  loading: boolean;
  onAdd?: (item: { [key: string]: string }) => void;
  onDelete?: (index: number) => void;
  onEdit?: (index: number, key: string, value: string) => void;
  readOnly?: boolean;
  readOnlyAppendedData?: { id: string; [key: string]: string }[];
  total?: string;
  year: number;
}

const Table: FC<TableProps> = ({
  caption,
  data,
  editable = true,
  headers,
  loading,
  onAdd,
  onDelete,
  onEdit,
  readOnly,
  readOnlyAppendedData,
  total,
  year,
}) => {
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(false);

  const { register, handleSubmit, errors, watch } = useForm();
  const selectedMonth = watch("month");

  useCancelOnEsc({
    onCancel: () => {
      setAdding(false);
      setEditing(false);
    },
  });

  useEffect(() => {
    if (adding) {
      setEditing(false);
    }

    if (editing) {
      setAdding(false);
    }
  }, [adding, editing]);

  return (
    <TableBlock>
      <Caption>
        <CaptionText>{caption}</CaptionText>
        {editable && !readOnly && (
          <EditControls
            adding={adding}
            editing={editing}
            setAdding={setAdding}
            setEditing={setEditing}
          />
        )}
      </Caption>

      <StyledTable>
        <thead>
          <Row>
            {editing && <EditTh />}
            {headers.map(({ key, name }) => (
              <HeaderCell key={key}>{name}</HeaderCell>
            ))}
          </Row>
        </thead>

        <tbody>
          {data.map((item, index) => (
            <Row key={item.id.value}>
              {editing && (
                <CloseCell>
                  <DeleteButton
                    onClick={() => {
                      if (onDelete) onDelete(index);
                    }}
                  />
                </CloseCell>
              )}
              {headers.map(({ key }) => (
                <EditableCell
                  key={`${item.id.value}-${key}`}
                  editable={!readOnly && item[key].editable}
                  loading={loading}
                  name={key}
                  onEdit={(value) => {
                    if (onEdit) onEdit(index, key, value);
                  }}
                  value={item[key].value}
                  year={year}
                />
              ))}
            </Row>
          ))}
          {adding && (
            <Row>
              <Cell colSpan={headers.length}>
                <Form
                  numberOfItems={headers.length}
                  onSubmit={handleSubmit((values) => {
                    if (onAdd) {
                      const parsedDate = parseDate({
                        day: values.day,
                        month: values.month,
                        year: `${year}`,
                      });
                      onAdd({ ...values, date: parsedDate });
                    }

                    setAdding(false);
                  })}
                >
                  {headers.map(({ key, name }) => {
                    if (key === "date") {
                      return (
                        <DateInput
                          key={key}
                          register={register}
                          selectedMonth={selectedMonth}
                          year={year}
                        />
                      );
                    }

                    return (
                      <MinimalInput
                        key={key}
                        ref={register({ required: `${name} is required` })}
                        error={errors[key]}
                        maxLength={80}
                        name={key}
                      />
                    );
                  })}

                  <SubmitButton type="submit" value="&#xf00c;" />
                </Form>
              </Cell>
            </Row>
          )}
          {readOnlyAppendedData?.map((item) => (
            <Row key={item.id}>
              {editing && <td aria-label="No Value" />}
              {headers.map(({ key }) => (
                <Cell key={`${item.id}-${key}`}>{item[key]}</Cell>
              ))}
            </Row>
          ))}
        </tbody>

        {total ? (
          <tfoot>
            <Row>
              <Total
                colSpan={editing ? headers.length + 1 : headers.length}
                total={total}
              />
            </Row>
          </tfoot>
        ) : null}
      </StyledTable>
    </TableBlock>
  );
};

export default Table;
