import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { FC, useEffect } from "react";
import { useLocation } from "react-router";
import CSAButton from "../components/CSAButton";
import CSALink from "../components/CSALink";
import CenteredContainer from "../components/CenteredContainer";
import HomeImageLink from "../components/HomeImageLink";
import { isGraphQLErrorCode } from "../helpers/graphql";
import { CURRENT_USER } from "../queries";
import { Response } from "../types";

const VERIFY_EMAIL = gql`
  mutation VerifyEmail($email: String!, $token: String!) {
    verifyEmail(email: $email, token: $token) {
      code
      message
    }
  }
`;

interface VerifyEmailData {
  verifyEmail: Response;
}

interface VerifyEmailVariables {
  email: string;
  token: string;
}

const ExpiredBody: FC = () => (
  <>
    <h1>Sorry your token has expired</h1>
    <p>You can resend a new verification from the Dashboard</p>
    <ContinueButton to="/dashboard">Go to Dashboard</ContinueButton>
  </>
);

const FailureBody: FC = () => (
  <>
    <h1>Sorry we couldn&apos;t verify your email</h1>
    <p>Please try again later</p>
    <ContinueButton to="/dashboard">Go to Dashboard</ContinueButton>
  </>
);

const SuccessBody: FC = () => (
  <>
    <h1>Your email has been verified</h1>
    <ContinueButton to="/dashboard">Go to Dashboard</ContinueButton>
  </>
);

const ContinueButton = CSAButton.withComponent(CSALink);

const VerifyEmail: FC = () => {
  const [verifyEmail, { data, error, loading }] = useMutation<
    VerifyEmailData,
    VerifyEmailVariables
  >(VERIFY_EMAIL, { refetchQueries: [{ query: CURRENT_USER }] });
  const params = new URLSearchParams(useLocation().search);

  const email = decodeURIComponent(params.get("email") ?? "");
  const token = params.get("token");

  useEffect(() => {
    if (verifyEmail) {
      verifyEmail({ variables: { email, token: token ?? "" } });
    }
  }, [verifyEmail, email, token]);

  if (loading) {
    return null;
  }

  let body;
  if (error) {
    if (isGraphQLErrorCode(error, "EXPIRED_TOKEN")) {
      body = <ExpiredBody />;
    } else {
      body = <FailureBody />;
    }
  }

  if (data?.verifyEmail) {
    body = <SuccessBody />;
  }

  return (
    <CenteredContainer>
      <HomeImageLink />
      {body}
    </CenteredContainer>
  );
};

export default VerifyEmail;
