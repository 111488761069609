import styled from "@emotion/styled";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import CSAInput from "../../components/CSAInput";

const NewChecklistInput = styled(CSAInput)`
  width: 20rem;
`;

const Form = styled.form`
  display: flex;
`;

const Button = styled.button`
  appearance: none;
  background: none;
  border: 0;
  color: var(--csa-blue);
  margin-left: 1rem;
  font-size: 1.25rem;
`;

export interface ChecklistVariables {
  name: string;
}

export interface CreateChecklistFormProps {
  checklist?: ChecklistVariables;
  onCancel: () => void;
  onSubmit: (variables: ChecklistVariables) => Promise<void>;
}

const CreateChecklistForm: FC<CreateChecklistFormProps> = ({
  checklist,
  onCancel,
  onSubmit,
}) => {
  const { handleSubmit, register } = useForm();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <NewChecklistInput
        ref={register}
        defaultValue={checklist?.name}
        name="name"
        placeholder="Checklist Name"
        type="text"
      />
      <Button type="submit">
        <FontAwesomeIcon icon={faCheck} />
      </Button>
      <Button type="reset">
        <FontAwesomeIcon icon={faTimes} onClick={onCancel} />
      </Button>
    </Form>
  );
};

export default CreateChecklistForm;
