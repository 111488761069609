import { StylesConfig } from "react-select";

export default {
  control: (baseStyles) => ({
    ...baseStyles,
    border: "0.0625rem solid var(--csa-border);",
    color: "var(--csa-darkgrey)",
    fontSize: "0.8125rem",
    height: "3rem",
    minWidth: "10rem",
    width: "100%",
  }),
  groupHeading: (styles) => ({
    ...styles,
    fontWeight: 700,
  }),
  option: (styles) => ({
    ...styles,
    fontSize: "0.75rem",
    fontWeight: 400,
  }),
} as StylesConfig;
