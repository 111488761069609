/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { FC, useEffect, useState } from "react";
import stringToNumber from "../helpers/string-to-number";
import useCancelOnEsc from "../hooks/use-cancel-on-esc";
import BlockContainer from "./BlockContainer";
import BlockHeading from "./BlockHeading";
import GraphBar from "./GraphBar";
import MinimalInput from "./MinimalInput";

const budgetMargin = css`
  margin: 0.5rem auto;
`;

const Container = styled(BlockContainer)`
  text-align: center;
`;

const Budget = styled.button<{ editableBudget: boolean }>`
  appearance: none;
  background: none;
  border: none;
  color: var(--csa-blue);
  display: block;
  font-size: 0.75rem;
  padding: 0;
  position: relative;
  text-align: center;

  &:focus {
    outline: none;
  }

  ${({ editableBudget }) =>
    editableBudget &&
    `
  &:hover {
    cursor: pointer;

    &::after {
      content: "\f044";
      font-family: FontAwesome;
      position: absolute;
      right: -1.5rem;
    }
  } `}
`;

const TotalContainer = styled.div<{ height?: string }>`
  align-items: flex-end;
  background: var(--csa-bg-tertiary);
  display: flex;
  font-size: 2rem;
  justify-content: center;
  height: 12rem;
  position: relative;
  text-align: center;

  ${({ height }) => height && `height: ${height};`}
`;

const Total = styled.p`
  margin: 0;
  padding: 0 1rem;
`;

export interface YearlyMarketingExpensesProps {
  budgeted?: string;
  editableBudget?: boolean;
  graphHeight?: string;
  onBudgetedUpdate?: (v: number) => Promise<void>;
  readOnly?: boolean;
  title: string;
  total?: string;
}

const YearlyMarketingExpenses: FC<YearlyMarketingExpensesProps> = ({
  budgeted,
  editableBudget = true,
  graphHeight,
  onBudgetedUpdate,
  readOnly,
  title,
  total,
}) => {
  const [editBudget, setEditBudget] = useState(false);
  const [localBudget, setLocalBudget] = useState(budgeted);

  useCancelOnEsc({ onCancel: () => setEditBudget(false) });

  useEffect(() => {
    if (budgeted) setLocalBudget(budgeted);
  }, [budgeted]);

  const ratio =
    stringToNumber(total ?? "0") / stringToNumber(budgeted ?? "1") || 1;
  const percentage = Math.round(Math.min(ratio, 1) * 100);

  return (
    <Container>
      <BlockHeading>{title}</BlockHeading>
      {editableBudget && editBudget ? (
        <form
          css={budgetMargin}
          onSubmit={(e) => {
            e.preventDefault();
            if (onBudgetedUpdate) {
              onBudgetedUpdate(stringToNumber(localBudget ?? "") * 100 || 0);
            }
            setEditBudget(false);
          }}
        >
          <MinimalInput
            error={false}
            maxLength={80}
            onChange={(e) => setLocalBudget(e.target.value)}
            readOnly={readOnly}
            value={localBudget}
          />
        </form>
      ) : (
        <Budget
          css={budgetMargin}
          disabled={readOnly}
          editableBudget={editableBudget}
          onClick={() => setEditBudget(true)}
        >
          Budget&nbsp;
          {budgeted}
        </Budget>
      )}
      <TotalContainer height={graphHeight}>
        <GraphBar color={ratio > 1 ? "red" : "green"} height={`${percentage}%`}>
          <Total>{total}</Total>
        </GraphBar>
      </TotalContainer>
    </Container>
  );
};

export default YearlyMarketingExpenses;
