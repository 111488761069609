import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import React, { FC, useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import CSAButton from "../../components/CSAButton";
import FormItem from "../../components/FormItem";
import ImageUpload from "../../components/ImageUpload";
import Notification, { NotificationProps } from "../../components/Notification";
import { graphQLErrorsToParagraphs } from "../../helpers/graphql";
import { CURRENT_USER, CurrentUser, CurrentUserData } from "../../queries";
import ChangePasswordFormItem from "./ChangePasswordFormItem";
import ChangePasswordModal from "./ChangePasswordModal";
import {
  CHANGE_PASSWORD,
  ChangePasswordVariables,
  UPDATE_USER,
  UPLOAD_LOGO,
  UpdateUserVariables,
} from "./graphql";

interface FormValues {
  "first-name": string;
  "last-name": string;
  "practice-name": string;
}

const Section = styled.section`
  max-width: 60rem;
`;

const Form = styled.form`
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 2rem;

  & > * {
    grid-column: 1 / 2;
    justify-self: start;
  }
`;

const PracticeName = styled(FormItem)`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  justify-self: end;
`;

const ImageUploadFormItem = styled(ImageUpload)`
  grid-column: 2 / 3;
  grid-row: 2 / 4;
  justify-self: end;
`;

const UpdateButton = styled(CSAButton)`
  grid-column: 2 / 3;
  margin-top: 5rem;
  width: 12.5rem;
  justify-self: end;
`;

export interface SettingsProps {
  currentUser?: CurrentUser;
}

const Settings: FC<SettingsProps> = ({ currentUser }) => {
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [notification, setNotification] = useState<NotificationProps>({});
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const { register, handleSubmit, errors } = useForm<FormValues>({
    defaultValues: {},
  });

  const { data, error } = useQuery<CurrentUserData>(CURRENT_USER);
  const [updateUser] = useMutation<Response, UpdateUserVariables>(UPDATE_USER, {
    refetchQueries: [{ query: CURRENT_USER }],
  });
  const [uploadLogo] = useMutation(UPLOAD_LOGO, {
    refetchQueries: [{ query: CURRENT_USER }],
  });
  const [changePassword] = useMutation<Response, ChangePasswordVariables>(
    CHANGE_PASSWORD,
  );

  const onDrop = useCallback(
    async (acceptedFiles, fileRejections) => {
      if (!currentUser?.active) return;

      if (acceptedFiles.length) {
        try {
          await uploadLogo({ variables: { logo: acceptedFiles[0] } });
          setNotification({
            children: <p>Logo uploaded</p>,
            type: "success",
          });
        } catch (e) {
          setNotification({
            children: (
              <p>Sorry something went wrong. Please try again later.</p>
            ),
            type: "error",
          });
        }
      } else if (fileRejections.length) {
        setNotification({
          children: <p>File must be an image less than 4MB</p>,
          type: "error",
        });
      }
    },
    [uploadLogo, currentUser],
  );

  if (error) {
    return <h1>Sorry something went wrong. Try again.</h1>;
  }

  const onSubmit = handleSubmit(async (values) => {
    try {
      await updateUser({
        variables: {
          firstName: values["first-name"],
          lastName: values["last-name"],
          practiceName: values["practice-name"] ?? "",
        },
      });
      setNotification({
        children: <p>Updated</p>,
        type: "success",
      });

      if (submitButtonRef.current) {
        submitButtonRef.current.focus();
      }
    } catch (e) {
      setNotification({
        children: <>{graphQLErrorsToParagraphs(e.graphQLErrors)}</>,
        type: "error",
      });
    }
  });

  return (
    <>
      <Section>
        <h1>Settings</h1>

        <hr />

        <Notification fadeOut float type={notification.type} width="100%">
          {notification.children}
        </Notification>

        <Form onSubmit={onSubmit}>
          {data?.currentUser?.userType === "owner" && (
            <FormItem
              ref={register({ required: "Please enter your first name" })}
              defaultValue={data?.currentUser?.firstName}
              error={errors["first-name"]?.message?.toString()}
              label="First Name"
              name="first-name"
              placeholder="First"
              readOnly={!currentUser?.active}
              required
              type="text"
            />
          )}

          {data?.currentUser?.userType === "owner" && (
            <FormItem
              ref={register({ required: "Please enter your last name" })}
              defaultValue={data?.currentUser?.lastName}
              error={errors["last-name"]?.message?.toString()}
              label="Last Name"
              name="last-name"
              placeholder="Last"
              readOnly={!currentUser?.active}
              required
              type="text"
            />
          )}

          <ChangePasswordFormItem
            disabled={!currentUser?.active}
            onClick={() => setShowChangePasswordModal(!showChangePasswordModal)}
          />

          {data?.currentUser?.userType === "owner" && (
            <PracticeName
              ref={register}
              defaultValue={data?.currentUser?.practiceName}
              error={errors["practice-name"]?.message?.toString()}
              label="Practice Name"
              name="practice-name"
              placeholder="Practice Name"
              readOnly={!currentUser?.active}
              type="text"
            />
          )}

          {data?.currentUser?.userType === "owner" && (
            <UpdateButton
              ref={submitButtonRef}
              disabled={!currentUser?.active}
              type="submit"
            >
              Save Changes
            </UpdateButton>
          )}

          {data?.currentUser?.userType === "owner" && (
            <ImageUploadFormItem
              disabled={!currentUser?.active}
              imgUrl={data?.currentUser?.logoUrl}
              onDrop={onDrop}
            />
          )}
        </Form>
      </Section>
      <ChangePasswordModal
        onClose={() => setShowChangePasswordModal(false)}
        onSubmit={async (variables) => {
          try {
            await changePassword({ variables });
            setNotification({
              children: <p>Password changed</p>,
              type: "success",
            });
          } catch (e) {
            setNotification({
              children: <>{graphQLErrorsToParagraphs(e.graphQLErrors)}</>,
              type: "error",
            });
          } finally {
            setShowChangePasswordModal(false);
          }
        }}
        show={showChangePasswordModal}
      />
    </>
  );
};

export default Settings;
