import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import {
  faEnvelope,
  faList,
  faMapMarkerAlt,
  faMobile,
  faPhoneAlt,
  faSort,
  faSortDown,
  faSortUp,
  faTh,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gql } from "apollo-boost";
import uniqBy from "lodash.uniqby";
import React, { FC, Suspense, lazy, useState } from "react";
import CSAButton from "../../components/CSAButton";
import CSAInput from "../../components/CSAInput";
import DataCell from "../../components/DataCell";
import HeaderCell from "../../components/HeaderCell";
import Modal from "../../components/Modal";
import Notification, { NotificationProps } from "../../components/Notification";
import useRecallLastView from "../../hooks/use-recall-last-view";
import { CurrentUser } from "../../queries";
import { Response } from "../../types";
import AlliedHealthNetworkForm from "./AlliedHealthNetworkForm";
import selectStyles from "./select-styles";

const FilterSelectable = styled(lazy(() => import("react-select")))`
  width: 10rem;

  @media (min-width: 1200px) {
    width: 14rem;
  }
`;

const H1 = styled.h1`
  font-size: 1.5rem;
`;

const Section = styled.section`
  position: relative;
`;

const Controls = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  justify-content: space-between;

  @media (min-width: 576px) {
    flex-flow: row;
  }
`;

const SearchInputContainer = styled.div`
  display: block;
  position: relative;
`;

const SearchInput = styled(CSAInput)`
  margin-bottom: 0;
  min-width: 8rem;

  @media (min-width: 1200px) {
    min-width: 16rem;
  }
`;

const ClearSearchButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  color: var(--csa-darkgrey);
  display: inline-block;
  outline: none;
  position: absolute;
  right: 1.5rem;
  top: 1rem;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`;

const GroupButtons = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const GroupButton = styled.button<{ selected?: boolean }>`
  appearance: none;
  border-radius: 0.5rem;
  padding: 1rem 0.5rem;
  min-width: 3rem;
  transition: all 0.2s;
  color: ${({ selected }) =>
    selected ? "var(--csa-blue)" : "var(--csa-darkgrey)"};
  background: ${({ selected }) =>
    selected ? "var(--csa-blue-2)" : "transparent"};
  border: ${({ selected }) =>
    selected
      ? "0.0625rem solid var(--csa-blue)"
      : "0.0625rem solid var(--csa-darkgrey)"};

  &:hover {
    background: var(--csa-blue-2);
    border: 0.0625rem solid var(--csa-blue);
    color: var(--csa-blue);
  }
`;

const SortButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  margin-left: 0.5rem;
`;

const AddNetworkButton = styled(CSAButton)`
  display: block;
  min-width: 8rem;
`;

const TableContainer = styled.div`
  margin-top: 3rem;
  position: relative;
`;

const Table = styled.table`
  border-spacing: 0;
  font-size: 1rem;
  overflow-x: auto;
  width: 100%;

  & thead {
    font-weight: 700;
    background: var(--csa-bg-secondary);
  }

  & th {
    background: var(--csa-bg-secondary);
  }

  & td {
    border-bottom: 0.0625rem solid var(--csa-border-3);
  }

  & th,
  & td {
    padding: 1rem 0.5rem;
    max-width: 12rem;
  }

  & td:last-child {
    max-width: 16rem;
  }

  & tbody tr:hover td {
    transition: background 0.2s;
    background: var(--csa-blue-2);
  }

  & th:last-child {
    background: var(--csa-bg-secondary);
  }
`;

const TableHeaderCell = styled(HeaderCell)`
  font-weight: bold;
  white-space: nowrap;
  color: var(--csa-text-primary);
`;

const SpecialtyFocus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SpecialtyType = styled.span`
  color: var(--csa-darkgrey);
  text-transform: capitalize;
`;

const TableNotes = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 48%);
  width: 100%;
  margin-top: 1rem;
  justify-content: space-between;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 32%);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(2, 48%);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 32%);
  }
`;

const Card = styled.div`
  border-radius: 0.3125rem;
  border: 0.0625rem solid var(--csa-border-2);
  color: var(--csa-text-primary);
  font-size: 0.75rem;
  padding: 1.5rem;
  transition: all 0.2s;

  & p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    background: var(--csa-blue-2);
    border: 0.0625rem solid var(--csa-blue);
  }
`;

const CardTitle = styled.p`
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 0;
`;

const CardSubTitle = styled.p`
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 0;
  min-height: 1rem;
`;

const CardSpecialty = styled.p`
  color: var(--csa-darkgrey);
  margin-top: 0;
  text-transform: capitalize;
`;

const CardAddress = styled.address`
  color: var(--csa-text-primary);
  font-style: normal;
  margin-bottom: 1rem;
  margin-top: 1rem;
  min-height: 7.5rem;

  & > div {
    align-items: center;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 0.75rem 1fr;
    margin-bottom: 0.5rem;
  }

  & svg {
    color: var(--csa-darkgrey);
    margin-left: auto;
    margin-right: auto;
  }
`;

const CardNotes = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal !important;
`;

const ConfirmationMessage = styled.p`
  font-size: 1.25rem;
  text-align: center;
`;

const DeleteConfirmButton = styled(CSAButton)`
  width: 100%;
`;

const ALLIED_HEALTH_NETWORK = gql`
  query GetAlliedHealthNetwork {
    alliedHealthNetwork {
      address
      cellNumber
      contactPerson
      emailAddress
      facilityName
      id
      name
      notes
      officeNumber
      specialty
      type
    }
  }
`;

export interface AlliedHealthNetwork {
  address?: string;
  cellNumber?: string;
  contactPerson?: string;
  emailAddress?: string;
  facilityName?: string;
  id: string;
  name: string;
  notes?: string;
  officeNumber?: string;
  specialty: string;
  type: "HEALTH" | "PERFORMANCE";
}

export interface AlliedHealthNetworkData {
  alliedHealthNetwork: AlliedHealthNetwork[];
}

const ADD_ALLIED_HEALTH_NETWORK = gql`
  mutation AddAlliedHealthNetwork(
    $address: String
    $cellNumber: String
    $contactPerson: String
    $emailAddress: String
    $facilityName: String
    $name: String!
    $notes: String
    $officeNumber: String
    $specialty: String!
    $type: AlliedHealthNetworkType!
  ) {
    addAlliedHealthNetwork(
      address: $address
      cellNumber: $cellNumber
      contactPerson: $contactPerson
      emailAddress: $emailAddress
      facilityName: $facilityName
      name: $name
      notes: $notes
      officeNumber: $officeNumber
      specialty: $specialty
      type: $type
    ) {
      code
      message
    }
  }
`;

export interface AddAlliedHealthNetworkVariables {
  address: string;
  cellNumber: string;
  contactPerson: string;
  emailAddress: string;
  facilityName: string;
  name: string;
  notes: string;
  officeNumber: string;
  specialty: string;
  type: "HEALTH" | "PERFORMANCE";
}

const DELETE_ALLIED_HEALTH_NETOWRK = gql`
  mutation DeleteAlliedHealthNetwork($id: ID!) {
    deleteAlliedHealthNetwork(id: $id) {
      code
      message
    }
  }
`;

export interface DeleteAlliedHealthNetworkVariables {
  id: string;
}

const EDIT_ALLIED_HEALTH_NETWORK = gql`
  mutation EditAlliedHealthNetwork(
    $address: String
    $cellNumber: String
    $contactPerson: String
    $emailAddress: String
    $facilityName: String
    $id: ID!
    $name: String!
    $notes: String
    $officeNumber: String
    $specialty: String!
    $type: AlliedHealthNetworkType!
  ) {
    editAlliedHealthNetwork(
      address: $address
      cellNumber: $cellNumber
      contactPerson: $contactPerson
      emailAddress: $emailAddress
      facilityName: $facilityName
      id: $id
      name: $name
      notes: $notes
      officeNumber: $officeNumber
      specialty: $specialty
      type: $type
    ) {
      code
      message
    }
  }
`;

export interface EditAlliedHealthNetworkVariables {
  address: string;
  cellNumber: string;
  contactPerson: string;
  emailAddress: string;
  facilityName: string;
  id: string;
  name: string;
  notes: string;
  officeNumber: string;
  specialty: string;
  type: "HEALTH" | "PERFORMANCE";
}

export interface AlliedHealthNetworkProps {
  currentUser?: CurrentUser;
}

const AlliedHealthNetwork: FC<AlliedHealthNetworkProps> = ({ currentUser }) => {
  const [notification, setNotification] = useState<NotificationProps>({});
  const [networkType, setNetworkType] = useState<
    "ALL" | "HEALTH" | "PERFORMANCE"
  >("ALL");
  const [viewType, setViewType] = useState<"GRID" | "LIST">("LIST");
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editingNetwork, setEditingNetwork] = useState<
    AlliedHealthNetwork | undefined
  >();
  const [deletingNetwork, setDeletingNetwork] = useState<string | undefined>();
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState<[string, "" | "ASC" | "DESC"]>(["", ""]);
  const [specialtyFilter, setSpecialtyFilter] = useState<string>("");

  useRecallLastView(
    "allied-health-network",
    { networkType, viewType },
    { setNetworkType, setViewType },
  );

  const toggleSort = (key: string) => {
    if (sort[0] === key) {
      if (sort[1] === "ASC") {
        setSort([key, "DESC"]);
      } else if (sort[1] === "DESC") {
        setSort(["", ""]);
      }
    } else {
      setSort([key, "ASC"]);
    }
  };

  const { data } = useQuery<AlliedHealthNetworkData, void>(
    ALLIED_HEALTH_NETWORK,
    {
      fetchPolicy: "cache-and-network",
    },
  );

  const dataSpecialties = uniqBy(
    data?.alliedHealthNetwork
      .map((r) => ({ group: r.type, label: r.specialty, value: r.specialty }))
      .filter((r) => !!r.value) ?? [],
    "value",
  );

  const specialtyOptions = [
    {
      label: "HEALTH",
      options: uniqBy(
        [
          ...dataSpecialties.filter(({ group }) => group === "HEALTH"),
          {
            group: "HEALTH",
            label: "Primary Care Physician",
            value: "Primary Care Physician",
          },
          { group: "HEALTH", label: "Spine Surgeon", value: "Spine Surgeon" },
          {
            group: "HEALTH",
            label: "Knee/Shoulder Orthopedist",
            value: "Knee/Shoulder Orthopedist",
          },
          {
            group: "HEALTH",
            label: "Foot/Ankle Specialist (Podiatry)",
            value: "Foot/Ankle Specialist (Podiatry)",
          },
          {
            group: "HEALTH",
            label: "Orthotics/Pedorthist",
            value: "Orthotics/Pedorthist",
          },
          { group: "HEALTH", label: "Neurologist", value: "Neurologist" },
          { group: "HEALTH", label: "Neurosurgeon", value: "Neurosurgeon" },
          {
            group: "HEALTH",
            label: "Hand/Wrist Specialist",
            value: "Hand/Wrist Specialist",
          },
          {
            group: "HEALTH",
            label: "Physical Therapy",
            value: "Physical Therapy",
          },
          {
            group: "HEALTH",
            label: "Pain Management",
            value: "Pain Management",
          },
          { group: "HEALTH", label: "Acupuncture", value: "Acupuncture" },
          { group: "HEALTH", label: "Dry Needling", value: "Dry Needling" },
          {
            group: "HEALTH",
            label: "Imaging Centers",
            value: "Imaging Centers",
          },
          {
            group: "HEALTH",
            label: "Massage Therapists",
            value: "Massage Therapists",
          },
          { group: "HEALTH", label: "Urgent Cares", value: "Urgent Cares" },
          {
            group: "HEALTH",
            label: "Nutritionist/Dietician",
            value: "Nutritionist/Dietician",
          },
          { group: "HEALTH", label: "Doula/Midwives", value: "Doula/Midwives" },
          {
            group: "HEALTH",
            label: "Dentists (TMD Speciality)",
            value: "Dentists (TMD Speciality)",
          },
          {
            group: "HEALTH",
            label: "Functional Medicine",
            value: "Functional Medicine",
          },
          {
            group: "HEALTH",
            label: "Pelvic Floor PT/Chiro",
            value: "Pelvic Floor PT/Chiro",
          },
          { group: "HEALTH", label: "Pediatrician", value: "Pediatrician" },
          {
            group: "HEALTH",
            label: "Psychologists/Therapists",
            value: "Psychologists/Therapists",
          },
        ],
        "value",
      ),
    },
    {
      label: "PERFORMANCE",
      options: uniqBy(
        [
          ...dataSpecialties.filter(({ group }) => group === "PERFORMANCE"),
          {
            group: "PERFORMANCE",
            label: "Personal Trainer",
            value: "Personal Trainer",
          },
          {
            group: "PERFORMANCE",
            label: "Sports Performance Coaches",
            value: "Sports Performance Coaches",
          },
          { group: "PERFORMANCE", label: "Gyms", value: "Gyms" },
          { group: "PERFORMANCE", label: "Pilates", value: "Pilates" },
          {
            group: "PERFORMANCE",
            label: "Yoga Studios",
            value: "Yoga Studios",
          },
          {
            group: "PERFORMANCE",
            label: "Martial Arts",
            value: "Martial Arts",
          },
          {
            group: "PERFORMANCE",
            label: "Golf Instructor",
            value: "Golf Instructor",
          },
          {
            group: "PERFORMANCE",
            label: "Tennis Instructor",
            value: "Tennis Instructor",
          },
          {
            group: "PERFORMANCE",
            label: "Running/Triathlete Coach",
            value: "Running/Triathlete Coach",
          },
        ],
        "value",
      ),
    },
  ];

  const [addAlliedHealthNetwork] = useMutation<
    Response,
    AddAlliedHealthNetworkVariables
  >(ADD_ALLIED_HEALTH_NETWORK, {
    onCompleted: () =>
      setNotification({
        children: <p>Added successfully</p>,
        type: "success",
      }),
    onError: () =>
      setNotification({
        children: <p>Sorry something went wrong</p>,
        type: "error",
      }),
    refetchQueries: [{ query: ALLIED_HEALTH_NETWORK }],
  });

  const [deleteAlliedHealthNetwork] = useMutation<
    Response,
    DeleteAlliedHealthNetworkVariables
  >(DELETE_ALLIED_HEALTH_NETOWRK, {
    onCompleted: () =>
      setNotification({
        children: <p>Deleted successfully</p>,
        type: "success",
      }),
    onError: () =>
      setNotification({
        children: <p>Sorry something went wrong</p>,
        type: "error",
      }),
    refetchQueries: [{ query: ALLIED_HEALTH_NETWORK }],
  });

  const [editAlliedHealthNetwork] = useMutation<
    Response,
    EditAlliedHealthNetworkVariables
  >(EDIT_ALLIED_HEALTH_NETWORK, {
    onCompleted: () =>
      setNotification({
        children: <p>Edited successfully</p>,
        type: "success",
      }),
    onError: () =>
      setNotification({
        children: <p>Sorry something went wrong</p>,
        type: "error",
      }),
    refetchQueries: [{ query: ALLIED_HEALTH_NETWORK }],
  });

  const filteredData = data?.alliedHealthNetwork
    .filter(({ type }) => (networkType === "ALL" ? true : networkType === type))
    .filter(
      ({ specialty }) => !specialtyFilter || specialtyFilter === specialty,
    )
    .filter((r) => {
      if (!search) {
        return true;
      }

      let isInFilter = false;
      Object.keys(r).forEach((key) => {
        if (
          `${r[key as keyof AlliedHealthNetwork]}`
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          isInFilter = true;
        }
      });

      return isInFilter;
    })
    .sort((a, b) => {
      if (sort[0] === "specialty") {
        return sort[1] === "ASC"
          ? a.specialty.localeCompare(b.specialty)
          : b.specialty.localeCompare(a.specialty);
      }

      if (sort[0] === "name") {
        return sort[1] === "ASC"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }

      return 0;
    });

  return (
    <>
      <Notification fadeOut float type={notification.type} width="100%">
        {notification.children}
      </Notification>

      <H1>Allied Health Network</H1>

      <Controls>
        <SearchInputContainer>
          <SearchInput
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            type="text"
            value={search}
          />
          {search && (
            <ClearSearchButton onClick={() => setSearch("")} type="button">
              <FontAwesomeIcon icon={faTimes} />
            </ClearSearchButton>
          )}
        </SearchInputContainer>

        <Actions>
          <GroupButtons>
            <GroupButton
              onClick={() => setNetworkType("ALL")}
              selected={networkType === "ALL"}
              type="button"
            >
              All
            </GroupButton>
            <GroupButton
              onClick={() => setNetworkType("HEALTH")}
              selected={networkType === "HEALTH"}
              type="button"
            >
              Health
            </GroupButton>
            <GroupButton
              onClick={() => setNetworkType("PERFORMANCE")}
              selected={networkType === "PERFORMANCE"}
              type="button"
            >
              Performance
            </GroupButton>
          </GroupButtons>

          <Suspense fallback={<div />}>
            <FilterSelectable
              classNames={{ group: () => "select-group" }}
              isClearable
              isDisabled={!currentUser?.active}
              onChange={(v) =>
                setSpecialtyFilter(
                  (v as { value: string } | undefined)?.value ?? "",
                )
              }
              options={[
                {
                  label: "HEALTH",
                  options: dataSpecialties.filter(
                    ({ group }) => group === "HEALTH",
                  ),
                },
                {
                  label: "PERFORMANCE",
                  options: dataSpecialties.filter(
                    ({ group }) => group === "PERFORMANCE",
                  ),
                },
              ]}
              placeholder="No filters"
              styles={selectStyles}
            />
          </Suspense>

          <GroupButtons>
            <GroupButton
              onClick={() => setViewType("GRID")}
              selected={viewType === "GRID"}
              type="button"
            >
              <FontAwesomeIcon icon={faTh} />
            </GroupButton>
            <GroupButton
              onClick={() => setViewType("LIST")}
              selected={viewType === "LIST"}
              type="button"
            >
              <FontAwesomeIcon icon={faList} />
            </GroupButton>
          </GroupButtons>

          <AddNetworkButton
            disabled={!currentUser?.active}
            onClick={() => setIsAdding(true)}
            type="button"
          >
            Add Contact
          </AddNetworkButton>
        </Actions>
      </Controls>

      <Section>
        {viewType === "GRID" && (
          <Grid>
            {filteredData?.map((r) => (
              <Card
                key={r.id}
                onClick={() => {
                  setEditingNetwork(r);
                  setIsEditing(true);
                }}
              >
                <CardTitle>{r.name}</CardTitle>
                <CardSubTitle>{r.facilityName}</CardSubTitle>
                <CardSpecialty>
                  {r.specialty} • {r.type.toLowerCase()}
                </CardSpecialty>
                <CardAddress>
                  {r.contactPerson && (
                    <div>
                      <FontAwesomeIcon icon={faUser} />
                      {r.contactPerson}
                    </div>
                  )}
                  {r.officeNumber && (
                    <div>
                      <FontAwesomeIcon icon={faPhoneAlt} />
                      {r.officeNumber}
                    </div>
                  )}
                  {r.cellNumber && (
                    <div>
                      <FontAwesomeIcon icon={faMobile} />
                      {r.cellNumber}
                    </div>
                  )}
                  {r.emailAddress && (
                    <div>
                      <FontAwesomeIcon icon={faEnvelope} />
                      {r.emailAddress}
                    </div>
                  )}
                  {r.address && (
                    <div>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                      {r.address}
                    </div>
                  )}
                </CardAddress>
                <CardNotes>{r.notes}</CardNotes>
              </Card>
            ))}
          </Grid>
        )}

        {viewType === "LIST" && (
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <TableHeaderCell>
                    Specialty/Focus
                    <SortButton
                      onClick={() => toggleSort("specialty")}
                      type="button"
                    >
                      {sort[0] === "specialty" && sort[1] === "ASC" && (
                        <FontAwesomeIcon icon={faSortUp} />
                      )}
                      {sort[0] === "specialty" && sort[1] === "DESC" && (
                        <FontAwesomeIcon icon={faSortDown} />
                      )}
                      {sort[0] !== "specialty" && (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                      <span className="sr-only">Sort by Specialty</span>
                    </SortButton>
                  </TableHeaderCell>
                  <TableHeaderCell>
                    Name
                    <SortButton
                      onClick={() => toggleSort("name")}
                      type="button"
                    >
                      {sort[0] === "name" && sort[1] === "ASC" && (
                        <FontAwesomeIcon icon={faSortUp} />
                      )}
                      {sort[0] === "name" && sort[1] === "DESC" && (
                        <FontAwesomeIcon icon={faSortDown} />
                      )}
                      {sort[0] !== "name" && <FontAwesomeIcon icon={faSort} />}
                      <span className="sr-only">Sort by Specialty</span>
                    </SortButton>
                  </TableHeaderCell>
                  <TableHeaderCell>Facility Name</TableHeaderCell>
                  <TableHeaderCell>Address</TableHeaderCell>
                  <TableHeaderCell>Office Number</TableHeaderCell>
                  <TableHeaderCell>Cell Number</TableHeaderCell>
                  <TableHeaderCell>Email Address</TableHeaderCell>
                  <TableHeaderCell>Contact Person</TableHeaderCell>
                  <TableHeaderCell>Notes</TableHeaderCell>
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((r) => (
                  <tr
                    key={r.id}
                    onClick={() => {
                      setEditingNetwork(r);
                      setIsEditing(true);
                    }}
                  >
                    <DataCell>
                      <SpecialtyFocus>
                        {r.specialty}
                        <SpecialtyType>{r.type.toLowerCase()}</SpecialtyType>
                      </SpecialtyFocus>
                    </DataCell>
                    <DataCell oneline>{r.name}</DataCell>
                    <DataCell oneline>{r.facilityName}</DataCell>
                    <DataCell oneline>{r.address}</DataCell>
                    <DataCell oneline>{r.officeNumber}</DataCell>
                    <DataCell oneline>{r.cellNumber}</DataCell>
                    <DataCell oneline>{r.emailAddress}</DataCell>
                    <DataCell oneline>{r.contactPerson}</DataCell>
                    <DataCell>
                      <TableNotes>{r.notes}</TableNotes>
                    </DataCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        )}
      </Section>

      <Modal onClose={() => setIsAdding(false)} show={isAdding}>
        <AlliedHealthNetworkForm
          onSubmit={async (variables) => {
            await addAlliedHealthNetwork({ variables });
            setIsAdding(false);
          }}
          specialtyOptions={specialtyOptions}
        />
      </Modal>

      <Modal
        onClose={() => {
          setIsEditing(false);
          setEditingNetwork(undefined);
        }}
        show={isEditing}
      >
        <AlliedHealthNetworkForm
          network={editingNetwork}
          onDelete={() => {
            if (editingNetwork?.id) {
              setIsDeleting(true);
              setDeletingNetwork(editingNetwork.id);
            }
          }}
          onSubmit={async (variables) => {
            if (editingNetwork?.id) {
              await editAlliedHealthNetwork({
                variables: {
                  ...variables,
                  id: editingNetwork.id,
                },
              });
            }
            setIsEditing(false);
            setEditingNetwork(undefined);
          }}
          specialtyOptions={specialtyOptions}
        />
      </Modal>

      <Modal
        onClose={() => {
          setIsDeleting(false);
          setDeletingNetwork(undefined);
        }}
        show={isDeleting}
      >
        <ConfirmationMessage>
          Are you sure you want to delete?
        </ConfirmationMessage>
        <DeleteConfirmButton
          onClick={async () => {
            setIsDeleting(false);
            setDeletingNetwork(undefined);
            setIsEditing(false);
            setEditingNetwork(undefined);

            if (deletingNetwork) {
              await deleteAlliedHealthNetwork({
                variables: { id: deletingNetwork },
              });
            }
          }}
          type="button"
        >
          Delete
        </DeleteConfirmButton>
      </Modal>
    </>
  );
};

export default AlliedHealthNetwork;
