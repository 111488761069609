import { ApolloError } from "apollo-boost";
import { GraphQLError } from "graphql";
import React from "react";

export const graphQLErrorsToParagraphs = (
  errors?: readonly GraphQLError[],
): React.ReactNode =>
  errors?.map(({ message }) => <p key={message}>{message}</p>);

export const isGraphQLErrorCode = (
  error: ApolloError,
  code: string,
): boolean => {
  if (!error) {
    return false;
  }

  const filtered = error.graphQLErrors.filter(
    (e) => e.extensions?.code === code,
  );

  return filtered.length > 0;
};
