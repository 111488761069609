import styled from "@emotion/styled";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useState } from "react";

const Button = styled.button`
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
  transition: all 0.1s;

  &:hover {
    color: var(--csa-blue);
  }
`;

const Span = styled.span`
  color: var(--csa-blue);
  font-size: 0.75rem;
  margin-right: 1rem;
`;

export interface CopyToClipboardProps {
  text?: string;
}

const CopyToClipboard: FC<CopyToClipboardProps> = ({ children, text }) => {
  const [copied, setCopied] = useState(false);

  const onClick = () => {
    navigator.clipboard.writeText(text || "");
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  return (
    <div>
      {children}
      {copied ? (
        <Span>Copied</Span>
      ) : (
        <Button onClick={onClick} type="button">
          <FontAwesomeIcon icon={faClone} />
          <span className="sr-only">Copy</span>
        </Button>
      )}
    </div>
  );
};

export default CopyToClipboard;
