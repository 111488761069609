import styled from "@emotion/styled";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect } from "react";

const Container = styled.div`
  font-size: 0.75rem;
`;

const Rule = styled.p<{ valid: boolean }>`
  opacity: ${({ valid }) => (valid ? 1 : 0.5)};
  transition: all 0.1s;

  ${({ valid }) => valid && "color: var(--csa-blue);"}
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 0.25rem;
`;

export interface PasswordHelperProps {
  className?: string;
  password: string;
  setIsValid: (isValid: boolean) => void;
}

type Validators = {
  characters: (v: string) => boolean;
  length: (v: string) => boolean;
  number: (v: string) => boolean;
  specialCharacter: (v: string) => boolean;
};

const validators: Validators = {
  characters: (v: string): boolean =>
    v ? /[a-z]/.test(v) && /[A-Z]/.test(v) : false,
  length: (v: string): boolean => (v ? v.length >= 8 : false),
  number: (v: string): boolean => (v ? /[0-9]/.test(v) : false),
  specialCharacter: (v: string): boolean =>
    v ? /[!@#$%^&*\-_]/.test(v) : false,
};

const PasswordHelper: FC<PasswordHelperProps> = ({
  className,
  password,
  setIsValid,
}) => {
  useEffect(() => {
    const isValid = Object.keys(validators).reduce((isValidAcc, key) => {
      if (isValidAcc) {
        const valid = validators[key as keyof Validators](password);

        return valid;
      }

      return isValidAcc;
    }, true);

    setIsValid(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return (
    <Container className={className}>
      <Rule valid={validators.length(password)}>
        <Icon icon={faCheck} />
        At least 8 characters
      </Rule>
      <Rule valid={validators.characters(password)}>
        <Icon icon={faCheck} />
        Contains a lowercase and uppercase letter
      </Rule>
      <Rule valid={validators.number(password)}>
        <Icon icon={faCheck} />
        Contains a number
      </Rule>
      <Rule valid={validators.specialCharacter(password)}>
        <Icon icon={faCheck} />
        Contains a special character (!@#$%^&*-_)
      </Rule>
    </Container>
  );
};

export default PasswordHelper;
