import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import CSAInput from "../../components/CSAInput";
import DeleteButton from "../../components/DeleteButton";
import EditControls from "../../components/EditControls";
import Modal from "../../components/Modal";
import useCancelOnEsc from "../../hooks/use-cancel-on-esc";
import { Task } from "../../types";
import TaskForm from "./TaskForm";

const Header = styled.header`
  position: relative;
`;

const Heading = styled.h2`
  display: flex;
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const ContentContainer = styled.div`
  border-radius: 0.3125rem;
  min-height: 20rem;
`;

export interface ColumnProps {
  canDelete: boolean;
  editable: boolean;
  id: string;
  index: number;
  onAdd?: (task: Task) => Promise<void>;
  onDeleteColumn?: () => void;
  onEditColumnTitle?: (title: string) => Promise<void>;
  readOnly?: boolean;
  title: string;
}

const Column: FC<ColumnProps> = ({
  canDelete,
  children,
  editable,
  id,
  index,
  onAdd,
  onDeleteColumn,
  onEditColumnTitle,
  readOnly,
  title,
}) => {
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(false);
  const { errors, handleSubmit, register } = useForm<{ title: string }>();

  useCancelOnEsc({ onCancel: () => setEditing(false) });

  return (
    <>
      <Draggable draggableId={id} index={index} isDragDisabled={readOnly}>
        {({ dragHandleProps, draggableProps, innerRef: draggableInnerRef }) => (
          <Droppable droppableId={id} type="task">
            {({ droppableProps, innerRef, placeholder }) => (
              <div
                ref={draggableInnerRef}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...draggableProps}
              >
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <ul ref={innerRef} {...droppableProps}>
                  <Header
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...dragHandleProps}
                  >
                    <Heading>
                      {canDelete && editing && (
                        <DeleteButton
                          onClick={() => {
                            if (onDeleteColumn) {
                              onDeleteColumn();
                            }
                            setEditing(false);
                          }}
                          type="button"
                        />
                      )}
                      {editing ? (
                        <form
                          onSubmit={handleSubmit(
                            async ({ title: newTitle }) => {
                              if (onEditColumnTitle) {
                                await onEditColumnTitle(newTitle);
                              }
                              setEditing(false);
                            },
                          )}
                        >
                          <CSAInput
                            ref={register({ required: true })}
                            defaultValue={title}
                            error={!!errors.title}
                            maxLength={40}
                            name="title"
                            padding="0.25rem"
                          />
                        </form>
                      ) : (
                        title
                      )}
                    </Heading>
                    {editable && !readOnly && (
                      <EditControls
                        adding={adding}
                        editing={editing}
                        setAdding={setAdding}
                        setEditing={setEditing}
                      />
                    )}
                  </Header>
                  <ContentContainer>{children}</ContentContainer>
                  {placeholder}
                </ul>
              </div>
            )}
          </Droppable>
        )}
      </Draggable>

      <Modal onClose={() => setAdding(false)} show={adding}>
        <TaskForm
          deleteable={false}
          onSubmit={async (task) => {
            if (onAdd) {
              await onAdd(task);
            }
            setAdding(false);
          }}
          submitButtonMsg="Add Task"
        />
      </Modal>
    </>
  );
};

export default Column;
