import styled from "@emotion/styled";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import CSAButton from "../../components/CSAButton";
import CSAInput from "../../components/CSAInput";
import useCancelOnEsc from "../../hooks/use-cancel-on-esc";
import useClickOff from "../../hooks/use-click-off";

const OptionButton = styled(CSAButton)`
  width: 100%;
  margin-bottom: 0.5rem;
`;

const YearSelectButton = styled.button`
  apperance: none;
  background: none;
  border-radius: 0.25rem;
  border: 0.0625rem solid var(--csa-darkgrey);
  margin: 0.25rem;
  outline: none;
  padding: 0.75rem 1rem;

  &:hover {
    background: var(--csa-blue-2);
  }
`;

export interface AddColumnProps {
  availableYears: number[];
  onAdd: (title: string) => void;
  onCopyYear: (year: number) => void;
  readOnly?: boolean;
}

const AddColumn: FC<AddColumnProps> = ({
  availableYears,
  onAdd,
  onCopyYear,
  readOnly,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const yearSelectorRef = useRef<HTMLUListElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isInputting, setIsInputting] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const { handleSubmit, register } = useForm<{ title: string }>();

  useClickOff({ onClick: () => setIsOpen(false), ref });
  useClickOff({ onClick: () => setIsCopying(false), ref: yearSelectorRef });
  useCancelOnEsc({ onCancel: () => setIsInputting(false) });

  const handleCopy = async (year: number) => {
    onCopyYear(year);
    setIsCopying(false);
  };

  if (isInputting) {
    return (
      <div>
        <form
          onSubmit={handleSubmit(({ title }) => {
            onAdd(title);
            setIsInputting(false);
          })}
        >
          <CSAInput
            ref={register({ required: true })}
            maxLength={40}
            name="title"
            placeholder="Column Name"
          />
        </form>
      </div>
    );
  }

  if (isCopying) {
    return (
      <ul ref={yearSelectorRef}>
        {availableYears.map((year) => (
          <YearSelectButton
            key={year}
            onClick={() => handleCopy(year)}
            type="button"
          >
            {year}
          </YearSelectButton>
        ))}
      </ul>
    );
  }

  return (
    <div ref={ref}>
      {!isOpen && (
        <CSAButton
          disabled={readOnly}
          onClick={() => setIsOpen(!isOpen)}
          type="button"
        >
          <FontAwesomeIcon icon={faPlus} />
          &nbsp;Add Column
        </CSAButton>
      )}

      {isOpen && (
        <>
          <OptionButton onClick={() => setIsInputting(true)} type="button">
            New
          </OptionButton>
          {!!availableYears.length && (
            <OptionButton
              onClick={() => setIsCopying(true)}
              type="button"
              variant="tertiary"
            >
              Copy from previous year
            </OptionButton>
          )}
        </>
      )}
    </div>
  );
};

export default AddColumn;
