export default (d: string): Date | null => {
  if (!d) {
    return null;
  }

  // TODO: Remove this once all dates in editorial_content have been converted //
  if (d.length === 9) {
    const parts = d.split("-");

    if (parts.length !== 3) {
      return null;
    }

    return new Date(
      parseInt(d.split("-")[0], 10),
      parseInt(d.split("-")[1], 10) - 1,
      parseInt(d.split("-")[2], 10),
    );
  }

  return new Date(d);
};
