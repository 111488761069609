import { Views } from "./Checklist";

type Item = { children?: Item[]; key: string; label: string };

const values: Record<Views, Item[]> = {
  destination: [
    {
      key: "trackingLeadingIndicators",
      label: "I am tracking our leading indicators",
    },
    {
      key: "trackingTrailingIndicators",
      label: "I am tracking our trailing indicators",
    },
    {
      key: "haveYearlyMarketingPlan",
      label: "I have a yearly marketing plan",
    },
    {
      key: "haveQuarterlyMarketingPlan",
      label: "I have a quarterly marketing plan",
    },
    {
      key: "haveMonthlyMarketingPlan",
      label: "I have a monthly marketing plan and editorial calendar",
    },
    {
      key: "haveMarketingBudget",
      label: "I have a clear marketing budget",
    },
    {
      key: "haveOptimized",
      label: "I have optimized aspects of my marketing at its fullest",
    },
    {
      key: "haveAutomated",
      label: "I have automated aspects of my marketing at its fullest",
    },
    {
      key: "haveDelegated",
      label: "I have delegated aspects of my marketing that I can",
    },
  ],
  fuel: [
    {
      key: "dialedInAudience",
      label: "I am dialed in on my clearly defined audience/niche",
    },
    {
      key: "fillsNeed",
      label: "My content marketing fills a need",
    },
    {
      key: "consistent",
      label: "I am consistent with my content marketing",
    },
    {
      key: "human",
      label: "I am human in my content marketing",
    },
    {
      key: "pointOfView",
      label: "My content has a point of view",
    },
    {
      key: "avoidSalesSpeak",
      label: "I avoid sales speak in my content marketing",
    },
    {
      key: "bestOfBreed",
      label: "I believe my Content is Best in Breed in my community",
    },
    {
      key: "wellDevelopedNewPatientMarketingStrategy",
      label: "I have a well-developed New Patient Marketing Strategy",
    },
    {
      key: "wellDevelopedPatientExperienceStrategy",
      label: "I have a well-developed Patient Experience Strategy",
    },
    {
      key: "wellDevelopedInactivePatientMarketingStrategy",
      label: "I have a well-developed Inactive Patient Marketing Strategy",
    },
    {
      key: "consistentContent",
      label: "I am creating consistent content",
    },
    {
      key: "curatingContent",
      label: "I am consistently curating content",
    },
    {
      key: "repurposingContent",
      label: "I am repurposing content I create and curate",
    },
    {
      key: "ownWebsite",
      label: "I have my own website",
    },
    {
      key: "emailList",
      label: "I have an email list",
    },
    {
      key: "physicalAddressList",
      label: "I have a physical address list",
    },
  ],
  gps: [
    {
      key: "clearlyDefinedMessage",
      label:
        "I have developed my clearly defined message that matches my clearly defined audience",
    },
    {
      key: "oneLiner",
      label: "I have developed a one-liner",
    },
    {
      key: "addressConflicts",
      label:
        "I fully understand how to address my patients and community’s external, internal, and philosophical conflicts.",
    },
    {
      key: "sevenCharacteristics",
      label: "I know the 7 characteristics of story.",
    },
    {
      children: [
        {
          key: "utilizingStoryWebsite",
          label: "Website",
        },
        {
          key: "utilizingStoryOneLiner",
          label: "One-Liner",
        },
        {
          key: "utilizingStoryMessaging",
          label: "Messaging",
        },
        {
          key: "utilizingStoryBlogs",
          label: "Blogs",
        },
        {
          key: "utilizingStoryVideo",
          label: "Video",
        },
        {
          key: "utilizingStoryPatientCommunication",
          label: "Patient Communication",
        },
        {
          key: "utilizingStoryPublicSpeaking",
          label: "Public Speaking",
        },
      ],
      key: "utilizingStory",
      label: "I am utilizing Story in the following aspects",
    },
  ],
  vehicle: [
    {
      key: "contentPlatformWebsite",
      label: "I have a website that is a content platform",
    },
    {
      key: "clearMessagingOnWebsite",
      label: "I have implemented clear messaging for my website",
    },
    {
      key: "SEO",
      label: "My Website is Search Engine Optimized",
    },
    {
      key: "googleMyBusiness",
      label: "I have a fully developed Google My Business page",
    },
    {
      key: "enoughGoogleReviews",
      label: "I have at least 30 Google Reviews",
    },
    {
      key: "googleAds",
      label: "I utilize paid Google Ads",
    },
    {
      key: "claimedOnlineListings",
      label:
        "I have claimed all of my online listings and made sure the information is correct",
    },
    {
      key: "yelp",
      label: "I have a developed Yelp page",
    },
    {
      key: "facebookBusiness",
      label: "I have a fully developed Facebook business page",
    },
    {
      key: "facebookAds",
      label: "I run Facebook Ads",
    },
    {
      key: "boostFacebookPage",
      label: "I strategically boost posts from my FB business page",
    },
    {
      key: "involvedInLocalFaceBookGroups",
      label: "I am actively involved in local FB Groups",
    },
    {
      key: "postOnFacebook",
      label: "I post on my FB business page consistently",
    },
    {
      key: "postOnInstagram",
      label: "I have and post actively on Instagram",
    },
    {
      key: "youtubeChannel",
      label: "I have a fully developed YouTube channel",
    },
    {
      key: "producingRegularVideos",
      label: "I am producing regular videos",
    },
    {
      key: "postingVideosOnFaceebook",
      label: "I am posting videos directly onto Facebook",
    },
    {
      key: "postingVideosWithSEOOnYoutube",
      label: "I am posting videos with optimal SEO on YouTube",
    },
    {
      key: "emailRegularly",
      label: "I email my patients 2-4 times per month with valuable content",
    },
    {
      key: "emailAutoresponderForNewPatients",
      label:
        "I have an email autoresponder sequence for new patients added to my email list",
    },
    {
      key: "publicSpeaking",
      label: "I perform public speaking in my community often",
    },
    {
      key: "communityOutreachProgram",
      label:
        "My practice has a detailed and consistent community outreach plan",
    },
    {
      key: "sendWelcomeLetters",
      label: "We send welcome letters by mail to all new patients",
    },
    {
      key: "sendThankYouRefererLetterse",
      label: "We send thank you letters to patients that refer us new patients",
    },
  ],
};

export default values;
