import styled from "@emotion/styled";

const GraphBar = styled.div<{
  color: "blue" | "green" | "red";
  height: string;
}>`
  align-items: flex-end;
  background: ${({ color }) => `var(--csa-${color}-2)`};
  border-top: 0.5rem solid ${({ color }) => `var(--csa-${color})`};
  display: flex;
  height: calc(${({ height }) => height} - 0.5rem);
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export default GraphBar;
