import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import CSAButton from "../../components/CSAButton";
import Menu from "../../components/Menu";
import ModalFormInput from "../../components/ModalFormInput";
import ModalFormLabel from "../../components/ModalFormLabel";
import ModalFormTitleInput from "../../components/ModalFormTitleInput";
import parseDate from "../../helpers/parse-date";
import { Content } from "./types";
import "react-datepicker/dist/react-datepicker.css";

const Form = styled.form`
  font-size: 0.875rem;
  min-width: 36rem;
  position: relative;
`;

const Textarea = styled(ModalFormInput.withComponent("textarea"))`
  font-size: 0.75rem;
`;

const Submit = styled(CSAButton)`
  margin-bottom: 0.5rem;
  width: 8rem;
`;

const ColorPicker = styled.div`
  margin-bottom: 1rem;
`;

const ColorButton = styled.button<{ color: string; selected: boolean }>`
  appearance: none;
  background: ${({ color }) => color};
  border-radius: 1rem;
  border: ${({ selected }) =>
    selected
      ? "0.125rem solid var(--csa-border)"
      : "0.0625rem solid var(--csa-border)"};
  box-sizing: border-box;
  display: inline-block;
  height: 2rem;
  margin-right: 0.5rem;
  padding: 0;
  width: 2rem;

  &:focus {
    outline: none;
  }
`;

const colors = [
  "#fff",
  "var(--csa-blue-2)",
  "var(--csa-green-2)",
  "var(--csa-orange-2)",
  "var(--csa-red-2)",
  "var(--csa-bg-secondary)",
];

export interface EditContentFormProps {
  content?: Content;
  onDelete?: () => void;
  onSubmit: (variables: Content) => void;
}

const EditContentForm: FC<EditContentFormProps> = ({
  content,
  onDelete,
  onSubmit,
}) => {
  const [color, setColor] = useState(content?.color ?? colors[0]);
  const [dueDate, setDueDate] = useState(content?.date ?? "");
  const [publishDate, setPublishDate] = useState(content?.publishDate ?? "");
  const { errors, handleSubmit, register } = useForm<Content>();

  return (
    <Form
      onSubmit={handleSubmit((variables) =>
        onSubmit({
          ...variables,
          color,
          date: dueDate,
          publishDate,
        }),
      )}
    >
      {onDelete && (
        <Menu ellipsisOrientation="horizontal" onDelete={onDelete} />
      )}

      <ModalFormTitleInput
        ref={register({ required: true })}
        defaultValue={content?.headline}
        error={!!errors.headline}
        name="headline"
        placeholder="Headline"
        type="text"
      />

      <ModalFormLabel htmlFor="type">
        Content Type
        <ModalFormInput
          ref={register}
          defaultValue={content?.type}
          error={!!errors.type}
          maxLength={20}
          name="type"
          placeholder="No Content Type"
          type="text"
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="persona">
        Audience Persona
        <ModalFormInput
          ref={register}
          defaultValue={content?.persona}
          error={!!errors.persona}
          maxLength={80}
          name="persona"
          placeholder="No Audience Persona"
          type="text"
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="creator">
        Content Creator
        <ModalFormInput
          ref={register}
          defaultValue={content?.creator}
          error={!!errors.creator}
          maxLength={40}
          name="creator"
          placeholder="No Creator"
          type="text"
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="date">
        Due Date
        <DatePicker
          customInput={<ModalFormInput error={false} />}
          dateFormat="MM-dd-yyyy"
          isClearable
          onChange={(d) => {
            if (d) {
              setDueDate(d.toISOString());
              return;
            }

            setDueDate("");
          }}
          placeholderText="No Due Date"
          selected={parseDate(dueDate)}
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="editor">
        Editor of Content
        <ModalFormInput
          ref={register}
          defaultValue={content?.editor}
          error={!!errors.editor}
          maxLength={40}
          name="editor"
          placeholder="No Editor"
          type="text"
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="channels">
        Channels this will be published
        <ModalFormInput
          ref={register}
          defaultValue={content?.channels}
          error={!!errors.channels}
          maxLength={80}
          name="channels"
          placeholder="No Channels"
          type="text"
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="publishDate">
        Publish Date
        <DatePicker
          customInput={<ModalFormInput error={false} />}
          dateFormat="MM-dd-yyyy"
          isClearable
          onChange={(d) => {
            if (d) {
              setPublishDate(d.toISOString());
              return;
            }

            setPublishDate("");
          }}
          placeholderText="No Publish Date"
          selected={parseDate(publishDate)}
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="status">
        Status
        <ModalFormInput
          ref={register}
          defaultValue={content?.status}
          error={!!errors.status}
          maxLength={20}
          name="status"
          placeholder="No Status"
          type="text"
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="metrics">
        Key Metrics
        <ModalFormInput
          ref={register}
          defaultValue={content?.metrics}
          error={!!errors.metrics}
          maxLength={40}
          name="metrics"
          placeholder="No Key Metrics"
          type="text"
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="cta">
        Call to Action
        <ModalFormInput
          ref={register}
          defaultValue={content?.cta}
          error={!!errors.cta}
          maxLength={80}
          name="cta"
          placeholder="No Call to Action"
          type="text"
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="notes">
        Notes
        <Textarea
          ref={register}
          defaultValue={content?.notes}
          error={!!errors.notes}
          maxLength={500}
          name="notes"
          placeholder="Notes for content"
        />
      </ModalFormLabel>
      <ColorPicker>
        {colors.map((c) => (
          <ColorButton
            key={c}
            color={c}
            onClick={() => setColor(c)}
            selected={color === c}
            type="button"
          />
        ))}
      </ColorPicker>
      <Submit type="submit">Save</Submit>
    </Form>
  );
};

export default EditContentForm;
