import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { gql } from "apollo-boost";
import React, { FC, useEffect } from "react";
import { CurrentUser } from "../queries";
import { Response } from "../types";

const Select = styled.select`
  margin-top: 0.5rem;
`;

interface PracticesData {
  practices?: {
    id?: string;
    ownerId?: string;
    practiceName?: string;
  }[];
}

const GET_PRACTICES = gql`
  query GetPractices {
    practices {
      id
      ownerId
      practiceName
    }
  }
`;

interface RefreshTokensVariables {
  ownerId: string;
}

const REFRESH_TOKENS = gql`
  mutation RefreshTokens($ownerId: ID) {
    refreshTokens(ownerId: $ownerId) {
      code
      message
    }
  }
`;

export interface PracticeSelectorProps {
  currentUser?: CurrentUser;
}

const PracticeSelector: FC<PracticeSelectorProps> = ({ currentUser }) => {
  const { data } = useQuery<PracticesData>(GET_PRACTICES, {
    fetchPolicy: "cache-and-network",
  });

  const [refreshTokens, { client }] = useMutation<
    Response,
    RefreshTokensVariables
  >(REFRESH_TOKENS);

  useEffect(() => {
    if (currentUser?.id) {
      localStorage.setItem("last-practice-id", `${currentUser.id}`);
    }
  }, [currentUser]);

  if (!currentUser || !data?.practices?.length || data.practices.length < 2) {
    return null;
  }

  return (
    <Select
      onChange={async (e) => {
        await refreshTokens({ variables: { ownerId: e.target.value } });
        await client?.resetStore();
      }}
      value={currentUser?.id}
    >
      {data?.practices?.map(({ id, ownerId, practiceName: name }) => (
        <option key={id} value={ownerId}>
          {name}
        </option>
      ))}
    </Select>
  );
};

export default PracticeSelector;
