import styled from "@emotion/styled";
import React, { FC } from "react";
import Nav from "../components/Nav";
import ResendEmailVerification from "../components/ResendEmailVerification";
import { CurrentUserData } from "../queries";

export type LoggedInSidebarProps = CurrentUserData;

const Container = styled.div`
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 12.5rem 1fr;
    margin: auto;
    max-width: 90rem;
    padding: 2rem 1.5rem;
  }
`;

const Main = styled.main`
  margin: 1rem 2rem;
`;

const FullHeader = styled.header`
  background-color: var(--csa-red);
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100vw;
`;

const PartialHeader = styled.header`
  background-color: var(--csa-bg-tertiary);
  border-radius: 0.125rem;
  left: 50%;
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translateX(-50%);
`;

const LoggedInSidebarNav = styled(Nav)`
  margin: 1rem 2rem;

  @media (min-width: 992px) {
    margin: unset;
  }
`;

const LoggedInSidebar: FC<LoggedInSidebarProps> = ({
  children,
  currentUser,
  isAdmin,
}) => {
  const header = () => {
    switch (true) {
      case !currentUser?.verified:
        return (
          <FullHeader>
            <ResendEmailVerification />
          </FullHeader>
        );

      case !currentUser?.active:
        return (
          <PartialHeader>
            <p>Subscription has expired. Read only.</p>
          </PartialHeader>
        );

      default:
        return null;
    }
  };

  return (
    <Container>
      {header()}
      <LoggedInSidebarNav
        currentUser={currentUser}
        isAdmin={isAdmin}
        logoUrl={currentUser?.logoUrl}
        practiceName={currentUser?.practiceName}
      />
      <Main>{children}</Main>
    </Container>
  );
};

export default LoggedInSidebar;
