import styled from "@emotion/styled";

const MinimalInput = styled.input<{ error: boolean }>`
  background: transparent;
  border-bottom: 0.0625rem solid var(--csa-blue);
  border-left: none;
  border-right: none;
  border-top: none;
  color: var(--csa-blue);
  display: block;
  padding: 0;
  text-align: inherit;
  width: 100%;

  ${({ error }) => (error ? "border-bottom: 0.125rem solid red;" : "")}
`;

export default MinimalInput;
