import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloLink, InMemoryCache } from "apollo-boost";
import { ApolloClient } from "apollo-client";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import React, { FC, useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import NetworkErrorToaster from "./components/NetworkErrorToaster";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

// eslint-disable-next-line import/prefer-default-export
export const GRAPHQL_API_URI =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000/graphql"
    : "https://api.csadigitaldashboard.com/graphql";

const uploadLink = createUploadLink({
  credentials: "include",
  uri: GRAPHQL_API_URI,
});

const ApolloProviderWithErrorHandler: FC = ({ children }) => {
  const [errorTimestamp, setErrorTimestamp] = useState(0);

  const onErrorLink = onError(({ networkError }) => {
    if (networkError && Date.now() - errorTimestamp > 3000) {
      setErrorTimestamp(Date.now());
    }
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([onErrorLink, uploadLink]),
  });

  return (
    <ApolloProvider client={client}>
      {children}
      <NetworkErrorToaster showTimestamp={errorTimestamp} />
    </ApolloProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ApolloProviderWithErrorHandler>
      <App />
    </ApolloProviderWithErrorHandler>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
