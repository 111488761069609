import { useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import { gql } from "apollo-boost";
import React, { FC, useState } from "react";
import { useHistory } from "react-router";
import BlockContainer from "../../components/BlockContainer";
import BlockHeading from "../../components/BlockHeading";
import GraphBar from "../../components/GraphBar";
import YearlyMarketingExpenses from "../../components/YearlyMarketingExpenses";
import currencyFormatter from "../../helpers/currency-formatter";
import formatMonth from "../../helpers/format-month";
import toFullCalendarEvent from "../../helpers/to-full-calendar-event";
import { CurrentUser } from "../../queries";
import { CalendarEvent } from "../../types";
import PracticeLogo from "./PracticeLogo";

// eslint-disable-next-line import/order
import listPlugin from "@fullcalendar/list";
// eslint-disable-next-line import/order
import rrulePlugin from "@fullcalendar/rrule";

const DASHBOARD_DATA = gql`
  query DashboardData(
    $start: String!
    $startTimestamp: String!
    $end: String!
    $endTimestamp: String!
    $year: Int!
  ) {
    analytics(aggregateStart: $start, aggregateEnd: $end, month: $end) {
      aggregates {
        months {
          id
          newPatients
          officeVisits
          patientCollections
        }
      }
    }

    calendarEvents(start: $startTimestamp, end: $endTimestamp) {
      id
      allDay
      duration
      exdate
      repeatFreq
      repeatUntil
      startDate
      title
    }

    marketingBudget(year: $year) {
      year {
        budgeted
        total
      }
    }
  }
`;

interface DashboardData {
  analytics?: {
    aggregates?: {
      months?: Array<{
        id?: string;
        newPatients?: number;
        officeVisits?: number;
        patientCollections?: number;
      }>;
    };
  };
  calendarEvents?: Array<CalendarEvent>;
  marketingBudget?: {
    year?: {
      budgeted?: number;
      total?: number;
    };
  };
}

interface DashboardVariables {
  end: string;
  endTimestamp: string;
  start: string;
  startTimestamp: string;
  year: number;
}

const H1 = styled.h1`
  line-height: 1.5;
`;

const Span = styled.span`
  font-weight: normal;
`;

const Hr = styled.hr`
  margin: 3rem 0;
`;

const Content = styled.section`
  margin-bottom: 1.5rem;

  @media (min-width: 576px) {
    display: grid;
    grid-column-gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Block = styled(BlockContainer)`
  display: flex;
  flex-direction: column;
  height: 20rem;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

const Chart = styled.div`
  align-items: flex-end;
  display: flex;
  height: 100%;
  margin: 1rem -0.5rem 0;
  text-align: center;
`;

const BarContainer = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 50%;
  flex-flow: column;
  height: 100%;
  justify-content: flex-end;
  margin: 0 0.5rem;
`;

const Bar = styled(GraphBar)<{ smallFont?: boolean }>`
  font-size: ${({ smallFont }) => (smallFont ? "1.125rem" : "2.5rem")};
  font-weight: lighter;
  margin-bottom: 0.5rem;
  max-width: 10rem;
  padding-bottom: 0.5rem;
`;

const BarLabel = styled.span`
  font-size: 0.75rem;
  font-weight: bold;
`;

export interface DashboardProps {
  currentUser?: CurrentUser;
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Dashboard: FC<DashboardProps> = ({ currentUser }) => {
  const history = useHistory();
  const [today] = useState<Date>(
    new Date(new Date(Date.now()).setHours(0, 0, 0, 0)),
  );
  const [oneMonthFromToday] = useState<Date>(
    new Date(new Date(Date.now()).setMonth(today.getMonth() + 1)),
  );
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  const twoMonthsAgoDate = new Date(today.getFullYear(), today.getMonth() - 2);
  const lastMonthDate = new Date(
    twoMonthsAgoDate.getFullYear(),
    twoMonthsAgoDate.getMonth() + 1,
  );

  const { data } = useQuery<DashboardData, DashboardVariables>(DASHBOARD_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      end: `${today.getFullYear()}-${formatMonth(today)}`,
      endTimestamp: oneMonthFromToday.toISOString(),
      start: `${twoMonthsAgoDate.getFullYear()}-${formatMonth(
        twoMonthsAgoDate,
      )}`,
      startTimestamp: today.toISOString(),
      year,
    },
  });

  const twoMonthsAgo = data?.analytics?.aggregates?.months?.find(
    ({ id }) => id && parseInt(id, 10) === twoMonthsAgoDate.getMonth() + 1,
  );

  const lastMonth = data?.analytics?.aggregates?.months?.find(
    ({ id }) => id && parseInt(id, 10) === lastMonthDate.getMonth() + 1,
  );

  const thisMonth = data?.analytics?.aggregates?.months?.find(
    ({ id }) => id && parseInt(id, 10) === month,
  );

  const newPatientsTwoMonthsAgo = twoMonthsAgo?.newPatients ?? 0;
  const newPatientsLastMonth = lastMonth?.newPatients ?? 0;
  const newPatientsThisMonth = thisMonth?.newPatients ?? 0;
  const newPatientsMax = Math.max(
    newPatientsTwoMonthsAgo,
    newPatientsLastMonth,
    newPatientsThisMonth,
  );

  const officeVisitsTwoMonthsAgo = twoMonthsAgo?.officeVisits ?? 0;
  const officeVisitsLastMonth = lastMonth?.officeVisits ?? 0;
  const officeVisitsThisMonth = thisMonth?.officeVisits ?? 0;
  const officeVisitsMax = Math.max(
    officeVisitsTwoMonthsAgo,
    officeVisitsLastMonth,
    officeVisitsThisMonth,
  );

  const patientCollectionsTwoMonthsAgo = twoMonthsAgo?.patientCollections ?? 0;
  const patientCollectionsLastMonth = lastMonth?.patientCollections ?? 0;
  const patientCollectionsThisMonth = thisMonth?.patientCollections ?? 0;
  const patientCollectionsMax = Math.max(
    patientCollectionsTwoMonthsAgo,
    patientCollectionsLastMonth,
    patientCollectionsThisMonth,
  );

  const yearlyExpensesBudgeted = data?.marketingBudget?.year?.budgeted ?? 0;
  const yearlyExpensesTotal = data?.marketingBudget?.year?.total ?? 0;

  return (
    <>
      <H1>
        Welcome to your
        <br />
        <Span>CSA Digital Dashboard</Span>
        {currentUser?.practiceName && (
          <Span>
            &nbsp;-&nbsp;
            {currentUser.practiceName}
          </Span>
        )}
      </H1>
      <Hr />
      {currentUser?.userType === "owner" ? (
        <>
          <Content>
            <Block>
              <BlockHeading>New Patients This Month</BlockHeading>
              <Chart>
                <BarContainer>
                  <Bar
                    color="blue"
                    height={`${
                      (newPatientsTwoMonthsAgo / newPatientsMax) * 100
                    }%`}
                  >
                    {newPatientsTwoMonthsAgo.toFixed(0)}
                  </Bar>
                  <BarLabel>{months[twoMonthsAgoDate.getMonth()]}</BarLabel>
                </BarContainer>
                <BarContainer>
                  <Bar
                    color="blue"
                    height={`${(newPatientsLastMonth / newPatientsMax) * 100}%`}
                  >
                    {newPatientsLastMonth.toFixed(0)}
                  </Bar>
                  <BarLabel>{months[lastMonthDate.getMonth()]}</BarLabel>
                </BarContainer>
                <BarContainer>
                  <Bar
                    color={
                      newPatientsThisMonth < newPatientsLastMonth
                        ? "red"
                        : "green"
                    }
                    height={`${(newPatientsThisMonth / newPatientsMax) * 100}%`}
                  >
                    {newPatientsThisMonth.toFixed(0)}
                  </Bar>
                  <BarLabel>{months[today.getMonth()]}</BarLabel>
                </BarContainer>
              </Chart>
            </Block>
            <Block>
              <BlockHeading>Office Visits This Month</BlockHeading>

              <Chart>
                <BarContainer>
                  <Bar
                    color="blue"
                    height={`${
                      (officeVisitsTwoMonthsAgo / officeVisitsMax) * 100
                    }%`}
                  >
                    {officeVisitsTwoMonthsAgo.toFixed(0)}
                  </Bar>
                  <BarLabel>{months[twoMonthsAgoDate.getMonth()]}</BarLabel>
                </BarContainer>
                <BarContainer>
                  <Bar
                    color="blue"
                    height={`${
                      (officeVisitsLastMonth / officeVisitsMax) * 100
                    }%`}
                  >
                    {officeVisitsLastMonth.toFixed(0)}
                  </Bar>
                  <BarLabel>{months[lastMonthDate.getMonth()]}</BarLabel>
                </BarContainer>
                <BarContainer>
                  <Bar
                    color={
                      officeVisitsThisMonth < officeVisitsLastMonth
                        ? "red"
                        : "green"
                    }
                    height={`${
                      (officeVisitsThisMonth / officeVisitsMax) * 100
                    }%`}
                  >
                    {officeVisitsThisMonth.toFixed(0)}
                  </Bar>
                  <BarLabel>{months[today.getMonth()]}</BarLabel>
                </BarContainer>
              </Chart>
            </Block>
            <Block>
              <BlockHeading>Revenue This Month</BlockHeading>

              <Chart>
                <BarContainer>
                  <Bar
                    color="blue"
                    height={`${
                      (patientCollectionsTwoMonthsAgo / patientCollectionsMax) *
                      100
                    }%`}
                    smallFont
                  >
                    {currencyFormatter(patientCollectionsTwoMonthsAgo)}
                  </Bar>
                  <BarLabel>{months[twoMonthsAgoDate.getMonth()]}</BarLabel>
                </BarContainer>
                <BarContainer>
                  <Bar
                    color="blue"
                    height={`${
                      (patientCollectionsLastMonth / patientCollectionsMax) *
                      100
                    }%`}
                    smallFont
                  >
                    {currencyFormatter(patientCollectionsLastMonth)}
                  </Bar>
                  <BarLabel>{months[lastMonthDate.getMonth()]}</BarLabel>
                </BarContainer>
                <BarContainer>
                  <Bar
                    color={
                      patientCollectionsThisMonth < patientCollectionsLastMonth
                        ? "red"
                        : "green"
                    }
                    height={`${
                      (patientCollectionsThisMonth / patientCollectionsMax) *
                      100
                    }%`}
                    smallFont
                  >
                    {currencyFormatter(patientCollectionsThisMonth)}
                  </Bar>
                  <BarLabel>{months[today.getMonth()]}</BarLabel>
                </BarContainer>
              </Chart>
            </Block>
            <YearlyMarketingExpenses
              budgeted={currencyFormatter(yearlyExpensesBudgeted / 12)}
              editableBudget={false}
              graphHeight="15.5rem"
              title="Monthly Marketing Expenses"
              total={currencyFormatter(yearlyExpensesTotal / 12)}
            />
          </Content>
          <section>
            <h2>Upcoming Events</h2>
            <FullCalendar
              eventClick={() => history.push("/events-calendar")}
              events={data?.calendarEvents?.map(toFullCalendarEvent) ?? []}
              headerToolbar={false}
              height="auto"
              initialView="listYear"
              plugins={[listPlugin, rrulePlugin]}
              validRange={{
                end: oneMonthFromToday.toISOString(),
                start: today.toISOString(),
              }}
            />
          </section>
        </>
      ) : (
        <PracticeLogo currentUser={currentUser} />
      )}
    </>
  );
};

export default Dashboard;
