import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import CSAButton from "../../components/CSAButton";
import CopyToClipboard from "../../components/CopyToClipboard";
import ModalFormInput from "../../components/ModalFormInput";
import ModalFormLabel from "../../components/ModalFormLabel";
import ModalFormTitleInput from "../../components/ModalFormTitleInput";
import parseDate from "../../helpers/parse-date";
import { Network, NetworkList } from "./types";

import "react-datepicker/dist/react-datepicker.css";

const Form = styled.form<{ textAlign?: string }>`
  font-size: 0.875rem;
  min-width: 30rem;
  position: relative;
  text-align: ${({ textAlign }) => textAlign};
`;

const Textarea = styled(ModalFormInput.withComponent("textarea"))`
  font-size: 0.75rem;
`;

const P = styled.p`
  font-size: 1.25rem;
  margin-top: 2rem;
`;

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-top: 10rem;
`;

const Submit = styled(CSAButton)`
  width: 50%;
`;

const ActionButton = styled(CSAButton)`
  width: 50%;
`;

export interface NetworkFormProps {
  network?: (Network & { list: NetworkList }) | { list: NetworkList };
  onDelete: () => void;
  onSubmit: (variables: Network) => void;
  readOnly?: boolean;
}

const NetworkForm: FC<NetworkFormProps> = ({
  network,
  onDelete,
  onSubmit,
  readOnly,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [lastCheckIn, setLastCheckIn] = useState(network?.lastCheckIn ?? "");
  const { errors, handleSubmit, register, watch } = useForm<Network>();

  if (showConfirmation) {
    return (
      <Form textAlign="center">
        <h1>Are you sure you want to delete?</h1>
        <P>This action cannot be undone.</P>
        <ButtonsContainer>
          <ActionButton
            onClick={() => setShowConfirmation(false)}
            type="button"
            variant="tertiary"
          >
            Cancel
          </ActionButton>
          <ActionButton onClick={onDelete} type="button" variant="danger">
            Delete
          </ActionButton>
        </ButtonsContainer>
      </Form>
    );
  }

  return (
    <Form
      onSubmit={handleSubmit((variables) =>
        onSubmit({
          ...variables,
          lastCheckIn,
        }),
      )}
    >
      <ModalFormTitleInput
        ref={register({ required: true })}
        defaultValue={network?.name}
        disabled={readOnly}
        error={!!errors.name}
        name="name"
        placeholder="Name"
        type="text"
      />

      <ModalFormLabel htmlFor="profession">
        Profession
        <ModalFormInput
          ref={register()}
          defaultValue={network?.profession}
          disabled={readOnly}
          error={!!errors.profession}
          maxLength={120}
          name="profession"
          placeholder="No profession"
          type="text"
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="contact">
        Contact Info
        <CopyToClipboard text={watch("contact")}>
          <ModalFormInput
            ref={register}
            defaultValue={network?.contact}
            disabled={readOnly}
            error={!!errors.contact}
            maxLength={120}
            name="contact"
            placeholder="No contact info"
            type="text"
          />
        </CopyToClipboard>
      </ModalFormLabel>

      <ModalFormLabel htmlFor="notes">
        Notes
        <Textarea
          ref={register}
          defaultValue={network?.notes}
          disabled={readOnly}
          error={!!errors.notes}
          maxLength={500}
          name="notes"
          placeholder="Notes"
        />
      </ModalFormLabel>

      <ModalFormLabel htmlFor="lastCheckIn">
        Last Check-in
        <DatePicker
          customInput={<ModalFormInput error={false} />}
          dateFormat="MM-dd-yyyy"
          disabled={readOnly}
          isClearable
          onChange={(d) => {
            if (d) {
              setLastCheckIn(d.toISOString());
              return;
            }

            setLastCheckIn("");
          }}
          placeholderText="No date"
          selected={parseDate(lastCheckIn)}
        />
      </ModalFormLabel>

      {!readOnly && (
        <ButtonsContainer>
          <Submit type="submit">Save</Submit>
          {network?.name && (
            <ActionButton
              onClick={() => setShowConfirmation(true)}
              type="button"
              variant="danger"
            >
              Delete
            </ActionButton>
          )}
        </ButtonsContainer>
      )}
    </Form>
  );
};

export default NetworkForm;
