import { gql } from "apollo-boost";
import { CalendarEvent } from "../../types";

export const GOOGLE_OAUTH = gql`
  query GoogleOAuth {
    googleOAuth {
      authorized
    }
  }
`;

export interface GoogleOAuthData {
  googleOAuth: {
    authorized?: boolean;
  };
}

export const GOOGLE_SIGN_OUT = gql`
  mutation GoogleSignOut {
    googleSignOut {
      code
      message
    }
  }
`;

export const GENERATE_GOOGLE_AUTH_URL = gql`
  mutation GenerateGoogleAuthUrl {
    generateGoogleAuthUrl {
      url
    }
  }
`;

export interface GenerateGoogleAuthUrlData {
  generateGoogleAuthUrl: {
    url?: string;
  };
}

export const ADD_CALENDAR_EVENT = gql`
  mutation AddCalendarEvent(
    $allDay: Boolean!
    $details: String!
    $duration: Float!
    $exdate: [String]!
    $pointPerson: String!
    $repeatFreq: CalendarEventRepeatFreq!
    $repeatUntil: String!
    $startDate: String!
    $title: String!
  ) {
    addCalendarEvent(
      allDay: $allDay
      details: $details
      duration: $duration
      exdate: $exdate
      pointPerson: $pointPerson
      repeatFreq: $repeatFreq
      repeatUntil: $repeatUntil
      startDate: $startDate
      title: $title
    ) {
      code
      message
    }
  }
`;

export interface AddCalendarEventVariables {
  allDay: boolean;
  details: string;
  duration: number;
  exdate: string[];
  pointPerson: string;
  repeatFreq: string;
  repeatUntil: string;
  startDate: string;
  title: string;
}

export const EDIT_CALENDAR_EVENT = gql`
  mutation EditCalendarEvent(
    $allDay: Boolean!
    $details: String!
    $duration: Float!
    $id: ID!
    $pointPerson: String!
    $repeatFreq: CalendarEventRepeatFreq!
    $repeatUntil: String!
    $startDate: String!
    $title: String!
  ) {
    editCalendarEvent(
      allDay: $allDay
      details: $details
      duration: $duration
      id: $id
      pointPerson: $pointPerson
      repeatFreq: $repeatFreq
      repeatUntil: $repeatUntil
      startDate: $startDate
      title: $title
    ) {
      code
      message
    }
  }
`;

export interface EditCalendarEventVariables extends AddCalendarEventVariables {
  id: string;
}

export const EDIT_RECURRING_CALENDAR_EVENT = gql`
  mutation EditRecurringCalendarEvent(
    $allDay: Boolean!
    $details: String!
    $duration: Float!
    $id: ID!
    $pointPerson: String!
    $repeatFreq: CalendarEventRepeatFreq!
    $repeatUntil: String!
    $startDate: String!
    $targetStartDate: String!
    $title: String!
    $updateOnlyThisEvent: Boolean!
  ) {
    editRecurringCalendarEvent(
      allDay: $allDay
      details: $details
      duration: $duration
      id: $id
      pointPerson: $pointPerson
      repeatFreq: $repeatFreq
      repeatUntil: $repeatUntil
      startDate: $startDate
      targetStartDate: $targetStartDate
      title: $title
      updateOnlyThisEvent: $updateOnlyThisEvent
    ) {
      code
      message
    }
  }
`;

export interface EditRecurringCalendarEventVariables
  extends EditCalendarEventVariables {
  targetStartDate: string;
  updateOnlyThisEvent: boolean;
}

export const DELETE_CALENDAR_EVENT = gql`
  mutation DeleteCalendarEvent($id: ID!) {
    deleteCalendarEvent(id: $id) {
      code
      message
    }
  }
`;

export interface DeleteCalendarEventVariables {
  id: string;
}

export const DELETE_RECURRING_CALENDAR_EVENT = gql`
  mutation DeleteRecurringCalendarEvent(
    $id: ID!
    $targetStartDate: String!
    $updateOnlyThisEvent: Boolean!
  ) {
    deleteRecurringCalendarEvent(
      id: $id
      targetStartDate: $targetStartDate
      updateOnlyThisEvent: $updateOnlyThisEvent
    ) {
      code
      message
    }
  }
`;

export interface DeleteRecurringCalendarEventVariables {
  id: string;
  targetStartDate: string;
  updateOnlyThisEvent: boolean;
}

export interface GetCalendarEvents {
  data: {
    calendarEvents: CalendarEvent[];
  };
}
