import styled from "@emotion/styled";

const Tab = styled.li<{ selected: boolean }>`
  ${({ selected }) =>
    selected && "border-bottom: 0.125rem solid var(--csa-blue);"}
  margin-bottom: -0.0625rem;
  padding-bottom: 0.5625rem;
`;

export default Tab;
