import styled from "@emotion/styled";

const StatusOption = styled.option`
  &::before {
    content: "\f111";
    font-family: FontAwesome;
  }
`;

export default StatusOption;
