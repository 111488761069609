import styled from "@emotion/styled";

const ModalFormLabel = styled.label`
  display: grid;
  font-weight: bold;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 2rem;
`;

export default ModalFormLabel;
