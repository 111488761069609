import styled from "@emotion/styled";
import React, { FC } from "react";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import BlockContainer from "../../components/BlockContainer";

const Container = styled(BlockContainer)`
  margin-bottom: 1rem;
  padding: 2rem 3rem;
`;

const P = styled.p`
  margin: 0;
`;

export interface BlockProps {
  className?: string;
  loading: boolean;
  title: string;
}

const Block: FC<BlockProps> = ({ className, children, loading, title }) => (
  <Container className={className}>
    <P>{title}</P>

    <ReactPlaceholder
      delay={300}
      ready={!loading}
      showLoadingAnimation
      style={{ height: 150, marginTop: 16 }}
      type="rect"
    >
      {children}
    </ReactPlaceholder>
  </Container>
);

export default Block;
