import styled from "@emotion/styled";

const IconButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  opacity: 0.5;
  transition: all 0.1s;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

export default IconButton;
