import styled from "@emotion/styled";

const DataCell = styled.td<{ editable?: boolean; oneline?: boolean }>`
  font-size: 0.75em;
  padding: 0.5rem 0;

  ${({ oneline }) =>
    oneline &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;

export default DataCell;
