import styled from "@emotion/styled";

const Value = styled.p`
  background: var(--csa-green);
  border-radius: 0.25rem;
  color: #fff;
  font-size: 2rem;
  font-weight: normal;
  margin: 2rem 0 0;
  overflow: scroll;
  padding: 1.5rem 3rem;
`;

export default Value;
