import styled from "@emotion/styled";

const Tabs = styled.ul<{ numberOfTabs: number }>`
  border-bottom: 0.0625rem solid var(--csa-grey);
  display: flex;
  justify-content: space-between;
  max-width: ${({ numberOfTabs }) => `${numberOfTabs * 12}rem`};
`;

export default Tabs;
