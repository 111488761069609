import { useEffect, useRef } from "react";

export default (
  storageKey: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: { [key: string]: any },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setters: { [key: string]: (v: any) => void },
): void => {
  const didMountRef = useRef(false);
  const keys = Object.keys(values);

  useEffect(() => {
    try {
      const ls = localStorage.getItem(storageKey);

      if (ls) {
        const stored = JSON.parse(ls);

        keys.forEach((k) => {
          if (stored[k] !== undefined) {
            setters[`set${k.charAt(0).toUpperCase() + k.slice(1)}`](stored[k]);
          }
        });
      }
    } finally {
      didMountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      localStorage.setItem(storageKey, JSON.stringify(values));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
};
