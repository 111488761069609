import styled from "@emotion/styled";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import IconButton from "./IconButton";

const Background = styled.div`
  align-items: center;
  background: rgba(54, 56, 64, 0.6);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
`;

const Container = styled.div`
  background: #fff;
  border-radius: 0.5rem;
  max-height: 80%;
  overflow: scroll;
  padding: 2rem;
  position: relative;
  z-index: 11;
`;

const Close = styled(IconButton)`
  float: right;
  font-size: 1.5rem;
  position: relative;
  z-index: 12;
`;

export interface ModalProps {
  show: boolean;
  onClose: () => void;
}

const Modal: FC<ModalProps> = ({ children, onClose, show }) => {
  if (!show) {
    return null;
  }

  return (
    <Background onClick={onClose}>
      <Container onClick={(e) => e.stopPropagation()}>
        <Close onClick={onClose} type="button">
          <FontAwesomeIcon icon={faTimes} />
        </Close>
        {children}
      </Container>
    </Background>
  );
};

export default Modal;
