import styled from "@emotion/styled";
import React, { FC } from "react";

const Td = styled.td`
  font-size: 0.75rem;
  font-weight: bold;
`;

export interface TotalProps {
  colSpan: number;
  total?: string;
}

const Total: FC<TotalProps> = ({ colSpan, total }) => (
  <Td colSpan={colSpan}>
    Total&nbsp;
    {total}
  </Td>
);

export default Total;
