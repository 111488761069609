import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import CSAButton from "../../components/CSAButton";
import CSAInput from "../../components/CSAInput";
import Modal from "../../components/Modal";
import PasswordHelper from "../../components/PasswordHelper";
import { ChangePasswordVariables } from "./graphql";

const Form = styled.form`
  margin-bottom: 2rem;
  padding: 3rem 5rem;
  width: 25rem;
`;

const FormHeading = styled.h2`
  font-size: 1rem;
  margin-bottom: 2rem;
`;

const Input = styled(CSAInput)`
  margin-bottom: 2rem;
`;

const Button = styled(CSAButton)`
  margin-left: 1rem;
  float: right;
  width: 6rem;
`;

const PasswordHelperFormItem = styled(PasswordHelper)`
  margin: -1rem 0 1.5rem 0;
`;

export interface ChangePasswordModalProps {
  onClose: () => void;
  onSubmit: (variables: ChangePasswordVariables) => Promise<void>;
  show: boolean;
}

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
  onClose,
  onSubmit,
  show,
}) => {
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const { errors, getValues, handleSubmit, register, watch } = useForm<
    ChangePasswordVariables & { newPasswordConfirmation: string }
  >({
    mode: "onBlur",
  });

  return (
    <Modal onClose={onClose} show={show}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormHeading>Password</FormHeading>
        <Input
          ref={register({ required: "Current password is required" })}
          error={errors.currentPassword?.message?.toString()}
          label="Current Password"
          maxLength={40}
          name="currentPassword"
          placeholder="********"
          required
          type="password"
        />
        <Input
          ref={register({
            required: "New password is required",
            validate: () =>
              isPasswordValid || "Password needs to match the rules below",
          })}
          error={errors.newPassword?.message?.toString()}
          label="New Password"
          maxLength={40}
          name="newPassword"
          placeholder="********"
          required
          type="password"
        />

        <PasswordHelperFormItem
          password={watch("newPassword")}
          setIsValid={setIsPasswordValid}
        />

        <Input
          ref={register({
            required: "Please confirm your password",
            validate: (v) =>
              getValues("newPassword") === v || "Passwords need to match",
          })}
          error={errors.newPasswordConfirmation?.message?.toString()}
          label="Confirm New Password"
          maxLength={40}
          name="newPasswordConfirmation"
          placeholder="********"
          required
          type="password"
        />
        <Button type="submit" variant="tertiary">
          Save
        </Button>
        <Button onClick={onClose} type="reset" variant="tertiary">
          Cancel
        </Button>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
