import styled from "@emotion/styled";
import { faEllipsisH, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import CSAButton from "../../components/CSAButton";
import CSALink from "../../components/CSALink";
import DeleteButton from "../../components/DeleteButton";
import IconButton from "../../components/IconButton";
import EditChecklistNameForm from "./EditChecklistNameForm";
import H2 from "./H2";
import {
  CreateMCMRoadmapChecklistVariables,
  DeleteMCMRoadmapChecklistVariables,
  GetMcmRoadmapChecklistsData,
  UpdateMCMRoadmapChecklistVariables,
} from ".";

const Section = styled.section`
  position: relative;
`;

const CreateButton = styled(CSAButton)`
  width: 100%;

  @media (min-width: 576px) {
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;

const Li = styled.li<{ editing?: boolean }>`
  border-radius: 0.25rem;
  display: flex;
  margin-bottom: 0.5rem;
  width: 25rem;

  ${({ editing }) =>
    !editing &&
    `
  border: 0.0625rem solid var(--csa-border);
  padding: 1rem;

    `}
`;

const Link = styled(CSALink)`
  display: block;
  width: 100%;
`;

export interface ListProps {
  createChecklist: (
    variables: CreateMCMRoadmapChecklistVariables,
  ) => Promise<void>;
  data?: GetMcmRoadmapChecklistsData;
  onDelete: (variables: DeleteMCMRoadmapChecklistVariables) => Promise<void>;
  onUpdate: (variables: UpdateMCMRoadmapChecklistVariables) => Promise<void>;
  readOnly?: boolean;
}

const List: FC<ListProps> = ({
  createChecklist,
  data,
  onDelete,
  onUpdate,
  readOnly,
}) => {
  const match = useRouteMatch();
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(false);

  return (
    <Section>
      <h1>Marketing Roadmap</h1>

      <CreateButton
        disabled={readOnly}
        onClick={() => setAdding(!adding)}
        type="button"
      >
        Create Checklist
      </CreateButton>

      <ul>
        <H2>
          Checklists&nbsp;
          <IconButton
            disabled={readOnly}
            onClick={() => setEditing(!editing)}
            type="button"
          >
            {editing ? (
              <FontAwesomeIcon icon={faTimes} />
            ) : (
              <FontAwesomeIcon icon={faEllipsisH} />
            )}
          </IconButton>
        </H2>
        {data?.mcmRoadmapChecklists?.map((cl) => (
          <Li key={cl.id} editing={editing}>
            {editing ? (
              <>
                <DeleteButton onClick={() => onDelete({ id: cl.id })} />
                <EditChecklistNameForm
                  checklist={cl}
                  onCancel={() => setEditing(false)}
                  onSubmit={async (variables) => {
                    await onUpdate({ ...variables, data: cl.data, id: cl.id });
                    setEditing(false);
                  }}
                />
              </>
            ) : (
              <Link to={`${match.url}/${cl.id}`}>
                {cl.name || `Checklist ${cl.id}`}
              </Link>
            )}
          </Li>
        ))}
        {adding && (
          <li>
            <EditChecklistNameForm
              onCancel={() => setAdding(false)}
              onSubmit={async (variables) => {
                await createChecklist(variables);
                setAdding(false);
              }}
            />
          </li>
        )}
      </ul>
    </Section>
  );
};

export default List;
