import styled from "@emotion/styled";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import useClickOff from "../hooks/use-click-off";

const List = styled.ul`
  background: #fff;
  border: 0.0625rem solid var(--csa-darkgrey);
  border-radius: 0 0 0.25rem 0.25rem;
  top: 100%;
  left: -1px;
  margin: 0;
  position: absolute;
  width: 100%;
`;

const Container = styled.div<{ opened: boolean }>`
  background: #fff;
  border-radius: ${({ opened }) =>
    opened ? "0.25rem 0.25rem 0 0" : "0.25rem"};
  border: 0.0625rem solid var(--csa-darkgrey);
  position: relative;
  text-align: center;
  width: 8rem;
  z-index: 1;
`;

const Button = styled.button`
  apperance: none;
  background: none;
  border: 0;
  font-size: 0.75rem;
  height: 100%;
  outline: none;
  padding: 0.75rem 1rem;
  width: 100%;
`;

const ItemSelectButton = styled(Button)`
  width: 100%;
  &:hover {
    background: var(--csa-blue-2);
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 1rem;
`;

export interface CSASelectorProps {
  className?: string;
  items: { id: string | number; value: string }[];
  label: string;
  onSelect: (params: { id: string | number; value: string }) => void;
  value: string | number;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}

const CSASelector: FC<CSASelectorProps> = ({
  children,
  className,
  items,
  label,
  onSelect,
  open,
  setOpen,
  value,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [localOpen, setLocalOpen] = useState(false);

  useEffect(() => {
    if (typeof open !== "undefined" && open !== localOpen) {
      setLocalOpen(open);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOpenChange = (newOpen: boolean) => {
    setLocalOpen(newOpen);

    if (setOpen) {
      setOpen(newOpen);
    }
  };

  useClickOff({ onClick: () => handleOpenChange(false), ref });

  return (
    <Container ref={ref} className={className} opened={localOpen}>
      <Button onClick={() => handleOpenChange(!open)} type="button">
        {`${label} ${value}`}
        <Icon icon={faChevronDown} />
      </Button>

      {localOpen && (
        <List>
          {items.map((v) => (
            <li key={v.id}>
              <ItemSelectButton
                onClick={() => {
                  onSelect(v);
                  handleOpenChange(false);
                }}
                type="button"
              >
                {v.value}
              </ItemSelectButton>
            </li>
          ))}
          {children}
        </List>
      )}
    </Container>
  );
};

export default CSASelector;
