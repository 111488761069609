import styled from "@emotion/styled";

const StatusLabel = styled.label`
  border-radius: 0.25rem;
  border: 0.0625rem solid var(--csa-border);
  left: 0;
  padding: 0.5rem 1rem;
  position: absolute;
  top: 0;
`;

export default StatusLabel;
