import React, { Dispatch, FC, SetStateAction } from "react";
import CSALink from "../../components/CSALink";
import Tab from "../../components/Tab";
import Tabs from "../../components/Tabs";

export type Views = "year" | "q1" | "q2" | "q3" | "q4";

export interface NavProps {
  className?: string;
  setView: Dispatch<SetStateAction<Views>>;
  view: Views;
}

const Nav: FC<NavProps> = ({ className, setView, view }) => (
  <nav className={className}>
    <Tabs numberOfTabs={5}>
      <Tab selected={view === "year"}>
        <CSALink
          onClick={() => setView("year")}
          selected={view === "year"}
          to="/marketing-planner"
        >
          Year Plan
        </CSALink>
      </Tab>
      <Tab selected={view === "q1"}>
        <CSALink
          onClick={() => setView("q1")}
          selected={view === "q1"}
          to="/marketing-planner"
        >
          Q1 (Jan-Mar)
        </CSALink>
      </Tab>
      <Tab selected={view === "q2"}>
        <CSALink
          onClick={() => setView("q2")}
          selected={view === "q2"}
          to="/marketing-planner"
        >
          Q2 (Apr-Jun)
        </CSALink>
      </Tab>
      <Tab selected={view === "q3"}>
        <CSALink
          onClick={() => setView("q3")}
          selected={view === "q3"}
          to="/marketing-planner"
        >
          Q3 (Jul-Sep)
        </CSALink>
      </Tab>
      <Tab selected={view === "q4"}>
        <CSALink
          onClick={() => setView("q4")}
          selected={view === "q4"}
          to="/marketing-planner"
        >
          Q4 (Oct-Dec)
        </CSALink>
      </Tab>
    </Tabs>
  </nav>
);

export default Nav;
