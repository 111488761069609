import styled from "@emotion/styled";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import stringToNumber from "../../helpers/string-to-number";
import Value from "./Value";

const Container = styled(Value.withComponent("div"))`
  background: var(--csa-blue);
  position: relative;
`;

const Input = styled.input`
  background: none;
  border-bottom: ;
  border-color: #fff;
  border-style: solid;
  border-width: 0 0 0.0625rem 0;
  color: #fff;
  font-size: 2rem;
  height: 100%;
  margin-bottom: 0.5rem;
  text-align: center;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  background: none;
  border: 0;
  color: #fff;
  position: absolute;
  top: 2.5rem;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

const LeftButton = styled(Button)`
  left: 1rem;
`;

const RightButton = styled(Button)`
  right: 1rem;
`;

export interface MonthInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  formatter: (value: string) => string;
  onUpdate: (value: string) => Promise<void>;
  readOnly?: boolean;
}

const MonthInput: FC<MonthInputProps> = ({
  defaultValue,
  formatter: propFormatter,
  onUpdate,
  readOnly,
}) => {
  const { handleSubmit, register } = useForm();
  const [value, setValue] = useState<string>(
    `${defaultValue === undefined ? "" : defaultValue}`,
  );

  const formatter = (v: string): string => {
    const formattedValue = stringToNumber(v);

    if (formattedValue < 0) {
      return propFormatter("0");
    }

    if (formattedValue > 1000000) {
      return propFormatter("1000000");
    }

    return propFormatter(`${formattedValue}`);
  };

  useEffect(() => {
    setValue(formatter(`${defaultValue === undefined ? "" : defaultValue}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <Container>
      <LeftButton
        disabled={readOnly}
        onClick={() => {
          if (readOnly) return;

          const formattedValue = formatter(
            `${stringToNumber(value || "0") - 1}`,
          );
          setValue(formattedValue);
          onUpdate(formattedValue);
        }}
      >
        <FontAwesomeIcon icon={faMinusCircle} />
      </LeftButton>
      <form
        onSubmit={handleSubmit(() => {
          const formattedValue = formatter(value);
          setValue(formattedValue);
          onUpdate(formattedValue);
        })}
      >
        <Input
          ref={register}
          name="value"
          onBlur={() => {
            if (readOnly) return;

            const formattedValue = formatter(value);
            setValue(formattedValue);
            onUpdate(formattedValue);
          }}
          onChange={(e) => {
            if (readOnly) return;
            setValue(e.target.value);
          }}
          readOnly={readOnly}
          type="text"
          value={value}
        />
        <input hidden type="submit" />
      </form>
      <RightButton
        disabled={readOnly}
        onClick={() => {
          if (readOnly) return;

          const formattedValue = formatter(
            `${stringToNumber(value || "0") + 1}`,
          );
          setValue(formattedValue);
          onUpdate(formattedValue);
        }}
      >
        <FontAwesomeIcon icon={faPlusCircle} />
      </RightButton>
    </Container>
  );
};

export default MonthInput;
