import styled from "@emotion/styled";
import React, { FC, useEffect, useState } from "react";
import { Transition } from "react-transition-group";

export interface NotificationProps {
  children?: React.ReactNode;
  className?: string;
  fadeOut?: boolean;
  float?: boolean;
  type?: "error" | "success" | "warning";
  width?: string;
  duration?: number;
}

const ANIMATION_DURATION = 300;
const DEFAULT_DURATION = 2000;

type TransitionStatus =
  | "entering"
  | "entered"
  | "exiting"
  | "exited"
  | "unmounted";

const exitStates: TransitionStatus[] = ["exiting", "exited"];

const Container = styled.div<NotificationProps & { state: TransitionStatus }>`
  background-color: ${({ type }): string => {
    switch (type) {
      case "error":
        return "var(--csa-red)";
      case "warning":
        return "var(--csa-orange)";
      default:
        return "var(--csa-green)";
    }
  }};
  box-sizing: border-box;
  left: 50%;
  max-height: ${({ state }): number | string =>
    state === "exited" ? 0 : "none"};
  opacity: ${({ state }): number => (exitStates.includes(state) ? 0 : 1)};
  padding: 0.0625rem 1rem;
  position: ${({ float }): string => (float ? "fixed" : "static")};
  text-align: center;
  top: 0;
  transform: ${({ float }): string => (float ? "translateX(-50%)" : "")};
  transition: opacity ${ANIMATION_DURATION}ms ease-in-out;
  width: ${({ width }): string | undefined => width};
  z-index: ${({ state }): number => (state === "exited" ? -1 : 1)};
`;

const Notification: FC<NotificationProps> = ({
  children,
  className,
  fadeOut,
  float,
  type,
  width,
  duration = DEFAULT_DURATION,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const shouldShow = !!children;

    setShow(shouldShow);

    if (shouldShow && fadeOut) {
      setTimeout(() => setShow(false), duration);
    }
  }, [children, fadeOut, duration]);

  return (
    <Transition in={show} timeout={ANIMATION_DURATION}>
      {(state): React.ReactNode => (
        <Container
          className={className}
          float={float}
          state={state}
          type={type}
          width={width}
        >
          {children}
        </Container>
      )}
    </Transition>
  );
};

export default Notification;
