import styled from "@emotion/styled";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApolloError } from "apollo-boost";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useParams, useRouteMatch } from "react-router-dom";
import CSAButton from "../../components/CSAButton";
import CSAInput from "../../components/CSAInput";
import CSALink from "../../components/CSALink";
import Tab from "../../components/Tab";
import Tabs from "../../components/Tabs";
import H2 from "./H2";
import checklistQuestions from "./checklist-questions";
import {
  GetMcmRoadmapChecklistsData,
  UpdateMCMRoadmapChecklistVariables,
} from ".";

const Line = styled.li`
  align-items: center;
  display: flex;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

const Input = styled(CSAInput)`
  margin: 0;
  margin-right: 1rem;

  input {
    margin: 0;
    width: auto;
  }
`;

const Children = styled.ul`
  margin-left: 2rem;
`;

const NotesLabel = styled.label`
  display: block;
  font-weight: 600;
  margin-top: 1.5rem;
`;

const NotesTextArea = styled.textarea`
  display: block;
  margin-top: 1rem;
  width: 90%;
`;

const SaveButton = styled(CSAButton)`
  margin-top: 1rem;
  width: 8rem;
`;

export type Views = "destination" | "fuel" | "gps" | "vehicle";

const getTabTitle = (view: Views) => {
  switch (view) {
    case "destination":
      return <H2>The Destination: Positioning, Planning, and Producing</H2>;

    case "fuel":
      return <H2>The Fuel: Content Marketing Strategy</H2>;

    case "gps":
      return <H2>The GPS: Messaging and Storytelling</H2>;

    case "vehicle":
      return <H2>The Vehicle: Content Distribution</H2>;

    default:
      return <H2>The Destination: Positioning, Planning, and Producing</H2>;
  }
};

export interface ChecklistProps {
  data?: GetMcmRoadmapChecklistsData;
  error?: ApolloError;
  loading: boolean;
  onUpdate: (variables: UpdateMCMRoadmapChecklistVariables) => Promise<void>;
  readOnly?: boolean;
}

const Checklist: FC<ChecklistProps> = ({
  data,
  error,
  onUpdate,
  loading,
  readOnly,
}) => {
  const params = useParams<{ checklistId?: string }>();
  const match = useRouteMatch();
  const [view, setView] = useState<Views>("destination");
  const { register, handleSubmit } = useForm<{ notes: string }>();
  const [lastError, setLastError] = useState(Date.now());

  useEffect(() => {
    if (error) {
      setLastError(Date.now());
    }
  }, [error]);

  if (loading) {
    return null;
  }

  const checklist = data?.mcmRoadmapChecklists?.find(
    (cl) => cl.id === params?.checklistId,
  );

  if (!checklist) {
    return <Redirect to="/mcm-roadmap-checklists" />;
  }

  return (
    <section key={lastError}>
      <h1>{checklist.name || `Checklist ${checklist.id}`}</h1>

      <CSALink to="/mcm-roadmap-checklists">
        <FontAwesomeIcon icon={faChevronLeft} />
        &nbsp;Back to List
      </CSALink>

      <nav>
        <Tabs numberOfTabs={4}>
          <Tab selected={view === "destination"}>
            <CSALink
              onClick={(e) => {
                e.preventDefault();
                setView("destination");
              }}
              selected={view === "destination"}
              to={match.url}
            >
              Destination
            </CSALink>
          </Tab>
          <Tab selected={view === "fuel"}>
            <CSALink
              onClick={(e) => {
                e.preventDefault();
                setView("fuel");
              }}
              selected={view === "fuel"}
              to={match.url}
            >
              Fuel
            </CSALink>
          </Tab>
          <Tab selected={view === "gps"}>
            <CSALink
              onClick={(e) => {
                e.preventDefault();
                setView("gps");
              }}
              selected={view === "gps"}
              to={match.url}
            >
              GPS
            </CSALink>
          </Tab>
          <Tab selected={view === "vehicle"}>
            <CSALink
              onClick={(e) => {
                e.preventDefault();
                setView("vehicle");
              }}
              selected={view === "vehicle"}
              to={match.url}
            >
              Vehicle
            </CSALink>
          </Tab>
        </Tabs>
      </nav>

      {getTabTitle(view)}

      <ul>
        {checklistQuestions[view].map(({ children, key, label }) => (
          <React.Fragment key={key}>
            <Line>
              <Input
                defaultChecked={(checklist?.data[key] as boolean) ?? false}
                disabled={readOnly}
                onChange={async (e) => {
                  await onUpdate({
                    ...checklist,
                    data: {
                      ...checklist.data,
                      [key]: e.target.checked,
                    },
                  });
                }}
                type="checkbox"
              />
              {label}
            </Line>

            {children && children.length > 0 && (
              <Line>
                <Children>
                  {children?.map((child) => (
                    <Line key={child.key}>
                      <Input
                        defaultChecked={
                          (checklist?.data[child.key] as boolean) ?? false
                        }
                        disabled={readOnly}
                        onChange={async (e) => {
                          await onUpdate({
                            ...checklist,
                            data: {
                              ...checklist.data,
                              [child.key]: e.target.checked,
                            },
                          });
                        }}
                        type="checkbox"
                      />
                      {child.label}
                    </Line>
                  ))}
                </Children>
              </Line>
            )}
          </React.Fragment>
        ))}
      </ul>

      <form
        onSubmit={handleSubmit(async ({ notes }) => {
          await onUpdate({
            ...checklist,
            data: {
              ...checklist.data,
              [`${view}-notes`]: notes,
            },
          });
        })}
      >
        <NotesLabel htmlFor="notes">
          Notes
          <NotesTextArea
            key={view}
            ref={register}
            defaultValue={checklist?.data[`${view}-notes`] as string}
            name="notes"
            readOnly={readOnly}
            rows={8}
          />
        </NotesLabel>

        <SaveButton disabled={readOnly} type="submit">
          Save
        </SaveButton>
      </form>
    </section>
  );
};

export default Checklist;
