import styled from "@emotion/styled";
import React, { FC, useEffect, useState } from "react";
import EditControls from "../../components/EditControls";
import IndicatorAdd from "./IndicatorAdd";
import IndicatorRow from "./IndicatorRow";
import Td from "./Td";
import { Indicator as IndicatorType } from "./indicator-scorecard";

const Caption = styled.caption`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
`;

const StyledTable = styled.table`
  position: relative;
  border-collapse: collapse;
  font-size: 0.875rem;
  margin-bottom: 2rem;
  width: 100%;
`;

const Col = styled.col<{ alternate?: boolean }>`
  ${({ alternate }) => alternate && "background: var(--csa-bg-tertiary);"}
`;

const Th = styled(Td.withComponent("th"))<{ width?: string }>`
  ${({ width }) => width && `width: ${width};`}
`;

const EditTh = styled.th`
  width: 2rem;
`;

const colCountForMonth = (weeks: Date[], month: number) => {
  let colCount = 0;
  let monthId = 0;
  let previousMonth = weeks[0]?.getMonth() ?? 0;

  weeks.forEach((week) => {
    if (previousMonth !== week.getMonth()) {
      monthId += 1;
      previousMonth = week.getMonth();
    }

    if (monthId === month) {
      colCount += 1;
    }
  });

  return colCount;
};

const toMonth = (month: number) =>
  [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ][month];

export interface TableProps {
  caption: string;
  indicators: IndicatorType[];
  onAdd: (indicator: { goals: string; title: string; who: string }) => void;
  onDelete: (variables: { id: string }) => void;
  onUpdate: () => void;
  onUpdateError: (reason?: string) => void;
  readOnly?: boolean;
  weeks: Date[];
}

const Table: FC<TableProps> = ({
  caption,
  indicators,
  onAdd,
  onDelete,
  onUpdate,
  onUpdateError,
  readOnly,
  weeks,
}) => {
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (editing && !indicators.length) {
      setEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicators]);

  return (
    <StyledTable>
      <Caption>
        {caption}
        {!readOnly && (
          <EditControls
            adding={adding}
            editing={editing}
            setAdding={setAdding}
            setEditing={setEditing}
          />
        )}
      </Caption>
      <colgroup>
        <Col span={editing ? 4 : 3} />
        <Col alternate span={colCountForMonth(weeks, 0)} />
        <Col span={colCountForMonth(weeks, 1)} />
        <Col alternate span={colCountForMonth(weeks, 2)} />
      </colgroup>

      <thead>
        <tr>
          {editing && <EditTh />}
          <Th width="8rem">Who</Th>
          <Th width="8rem">Indicators</Th>
          <Th width="4rem">Goals</Th>
          {weeks.map((date) => (
            <Th key={`${date.getMonth()}-${date.getDate()}`}>
              {toMonth(date.getMonth())}
              &nbsp;
              {date.getDate()}
            </Th>
          ))}
        </tr>
      </thead>

      <tbody>
        {indicators.map((indicator) => (
          <IndicatorRow
            key={indicator.id}
            editing={editing}
            indicator={indicator}
            onDelete={() => onDelete({ id: indicator.id ?? "" })}
            onUpdate={onUpdate}
            onUpdateError={onUpdateError}
            readOnly={readOnly}
            weeks={weeks}
          />
        ))}
        {adding && (
          <tr>
            <td aria-label="Add" colSpan={3}>
              <IndicatorAdd
                onAdd={(values) => {
                  onAdd(values);
                  setAdding(false);
                }}
                onCancel={() => setAdding(false)}
              />
            </td>
          </tr>
        )}
      </tbody>
    </StyledTable>
  );
};

export default Table;
