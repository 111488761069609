import styled from "@emotion/styled";
import React, { FC, Suspense, lazy, useState } from "react";
import CollapseToggleButton from "../../components/CollapseToggleButton";
import useRecallLastView from "../../hooks/use-recall-last-view";
import Block from "./Block";

const Container = styled(Block)<{ show: boolean }>`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto 10rem;
  grid-template-rows: ${({ show }) => (show ? "auto 12rem" : "auto")};
  padding-bottom: ${({ show }) => (show ? "4rem" : "2rem")};
  position: relative;
`;

const MinimizeButton = styled(CollapseToggleButton)`
  position: absolute;
  right: 2.5rem;
  top: 2rem;
`;

const Chart = styled(lazy(() => import("react-apexcharts")))`
  grid-row: 2 / 3;
`;

const MonthlyAverage = styled.div`
  align-self: center;
  background: var(--csa-green);
  border-radius: 0.25rem;
  color: #fff;
  grid-row: 2 / 3;
  margin-top: 2rem;
  padding: 3rem 1rem;
  text-align: left;
`;

const MonthlyAverageLabel = styled.dt`
  font-weight: normal;
  font-size: 0.75rem;
  margin-bottom: 1rem;
`;

const MonthlyAverageValue = styled.dd`
  margin: 0;
`;

const toMonth = (id: number) =>
  [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ][id - 1];

export interface BarChartProps {
  className?: string;
  id: string;
  monthlyAverage: string;
  months: Array<{ id?: string; value?: number }>;
  title: string;
  yaxisLabelFormatter?: (value: number) => string;
}

const BarChart: FC<BarChartProps> = ({
  className,
  id,
  monthlyAverage,
  months,
  title,
  yaxisLabelFormatter,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const categories = months.map(({ id: monthId }) =>
    monthId ? toMonth(parseInt(monthId, 10)) : "",
  );
  const data = months.map(({ value }) => value || 0);

  useRecallLastView(id, { isCollapsed }, { setIsCollapsed });

  return (
    <Container
      className={className}
      loading={false}
      show={!isCollapsed}
      title={title}
    >
      <MinimizeButton
        isCollapsed={isCollapsed}
        onClick={() => setIsCollapsed(!isCollapsed)}
      />
      {!isCollapsed && (
        <>
          <Suspense fallback={<div />}>
            <Chart
              height="100%"
              options={{
                chart: { id },
                legend: { show: false },
                xaxis: { categories },
                yaxis: { labels: { formatter: yaxisLabelFormatter } },
              }}
              series={[{ data, name: title, type: "bar" }]}
            />
          </Suspense>
          <MonthlyAverage>
            <MonthlyAverageLabel>
              Monthly Average&nbsp;
              {title}
            </MonthlyAverageLabel>
            <MonthlyAverageValue>{monthlyAverage}</MonthlyAverageValue>
          </MonthlyAverage>
        </>
      )}
    </Container>
  );
};

export default BarChart;
