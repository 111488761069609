import styled from "@emotion/styled";

const BlockHeading = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
`;

export default BlockHeading;
