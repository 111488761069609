import { useEffect } from "react";

export default ({ onCancel }: { onCancel: () => void }): void => {
  useEffect(() => {
    function stopEditingOnEsc(e: KeyboardEvent): void {
      if (e.keyCode === 27) {
        onCancel();
      }
    }

    document.addEventListener("keydown", stopEditingOnEsc, false);

    return (): void => {
      document.removeEventListener("keydown", stopEditingOnEsc, false);
    };
  }, [onCancel]);
};
