import styled from "@emotion/styled";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import CSASelector from "./CSASelector";

const border = "0.0625rem solid var(--csa-darkgrey)";
const padding = "1rem";

const Input = styled.input`
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: ${border};
  box-sizing: border-box;
  display: block;
  font-size: 0.75rem;
  margin: 0;
  padding: ${padding};
  width: 100%;
`;

export interface YearSelectorProps {
  availableYears?: number[];
  className?: string;
  readonly?: boolean;
  setYear: Dispatch<SetStateAction<number>>;
  year: number;
}

const MAX_YEAR = 9999;
const MIN_YEAR = 1900;

const YearSelector: FC<YearSelectorProps> = ({
  availableYears = [],
  className,
  readonly,
  setYear,
  year,
}) => {
  const currentYear = new Date().getFullYear();

  const { handleSubmit, register } = useForm<{ year: number }>();
  const [open, setOpen] = useState(false);

  const years = availableYears.find((y) => y === currentYear)
    ? availableYears.sort()
    : [...availableYears, currentYear].sort();

  return (
    <CSASelector
      className={className}
      items={years.map((y) => ({ id: `${y}`, value: `${y}` }))}
      label="Year:"
      onSelect={(y) => setYear(parseInt(y.value, 10))}
      open={open}
      setOpen={setOpen}
      value={year}
    >
      {!readonly && (
        <li>
          <form
            onSubmit={handleSubmit(({ year: newYear }) => {
              const formattedNewYear = parseInt(`${newYear}`, 10);
              if (
                formattedNewYear &&
                formattedNewYear >= MIN_YEAR &&
                formattedNewYear <= MAX_YEAR
              ) {
                setYear(formattedNewYear);
              }
              setOpen(false);
            })}
          >
            <Input
              ref={register}
              max={MAX_YEAR}
              min={MIN_YEAR}
              name="year"
              placeholder="Enter year"
              type="number"
            />
          </form>
        </li>
      )}
    </CSASelector>
  );
};

export default YearSelector;
