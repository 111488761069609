import { gql } from "apollo-boost";
import { Content } from "./types";

export const ADD_EDITORIAL_CONTENT = gql`
  mutation AddEditorialContent(
    $channels: String
    $color: String
    $creator: String
    $cta: String
    $date: String
    $editor: String
    $headline: String
    $metrics: String
    $month: Int!
    $notes: String
    $persona: String
    $publishDate: String
    $status: String
    $type: String
    $year: Int!
  ) {
    addEditorialContent(
      channels: $channels
      color: $color
      creator: $creator
      cta: $cta
      date: $date
      editor: $editor
      headline: $headline
      metrics: $metrics
      month: $month
      notes: $notes
      persona: $persona
      publishDate: $publishDate
      status: $status
      type: $type
      year: $year
    ) {
      code
      message
    }
  }
`;

export interface AddEditorialContentVariables {
  channels?: string;
  color?: string;
  creator?: string;
  cta?: string;
  date?: string;
  editor?: string;
  headline?: string;
  metrics?: string;
  month: number;
  notes?: string;
  persona?: string;
  publishDate?: string;
  status?: string;
  type?: string;
  year: number;
}

export const DELETE_EDITORIAL_CONTENT = gql`
  mutation DeleteEditorialContent($id: ID!, $month: Int!, $year: Int!) {
    deleteEditorialContent(id: $id, month: $month, year: $year) {
      code
      message
    }
  }
`;

export interface DeleteEditorialContentVariables {
  id: string;
  month: number;
  year: number;
}

export const GET_EDITORIAL_CALENDAR = gql`
  query GetEditorialCalendar($month: Int!, $year: Int!) {
    editorialCalendar(month: $month, year: $year) {
      availableYears
      id
      content {
        channels
        color
        creator
        cta
        date
        editor
        headline
        id
        metrics
        notes
        persona
        publishDate
        status
        type
      }
    }
  }
`;

export interface GetEditorialCalendarData {
  editorialCalendar?: {
    availableYears?: number[];
    id?: string;
    content?: Content[];
  };
}

export interface GetEditorialCalendarVariables {
  month: number;
  year: number;
}

export const EDIT_EDITORIAL_CONTENT = gql`
  mutation EditEditorialContent(
    $channels: String
    $color: String
    $creator: String
    $cta: String
    $date: String
    $editor: String
    $headline: String
    $id: ID!
    $metrics: String
    $notes: String
    $persona: String
    $publishDate: String
    $status: String
    $type: String
  ) {
    editEditorialContent(
      channels: $channels
      color: $color
      creator: $creator
      cta: $cta
      date: $date
      editor: $editor
      headline: $headline
      id: $id
      metrics: $metrics
      notes: $notes
      persona: $persona
      publishDate: $publishDate
      status: $status
      type: $type
    ) {
      code
      message
    }
  }
`;

export interface EditEditorialContentVariables {
  channels?: string;
  color?: string;
  creator?: string;
  cta?: string;
  date?: string;
  editor?: string;
  headline?: string;
  id: string;
  metrics?: string;
  notes?: string;
  persona?: string;
  publishDate?: string;
  status?: string;
  type?: string;
}

export const REORDER_EDITORIAL_CONTENT = gql`
  mutation ReorderEditorialContent(
    $destination: ReorderInput!
    $id: ID!
    $month: Int!
    $source: ReorderInput!
    $year: Int!
  ) {
    reorderEditorialContent(
      destination: $destination
      id: $id
      month: $month
      source: $source
      year: $year
    ) {
      code
      message
    }
  }
`;

export interface ReorderContentVariables {
  destination: {
    index: number;
  };
  id: string;
  month: number;
  source: {
    index: number;
  };
  year: number;
}
