import styled from "@emotion/styled";

const HeaderCell = styled.th`
  color: var(--csa-darkgrey);
  font-size: 0.75em;
  font-weight: normal;
  padding-bottom: 1rem;
  text-align: left;
`;

export default HeaderCell;
