import styled from "@emotion/styled";

const CSAButton = styled.button<{
  variant?: "primary" | "secondary" | "tertiary" | "danger";
}>`
  background: var(--csa-blue);
  border-radius: 0.25rem;
  border: none;
  color: #fff;
  cursor: pointer;
  outline: none;
  padding: 1rem;
  transition: all 0.1s;

  &:hover {
    color: #fff;
    opacity: 0.9;
  }

  ${({ variant }) => {
    switch (variant) {
      case "tertiary":
        return `
    background: none;
    border: 0.0625rem solid var(--csa-blue);
    color: var(--csa-blue);

    &:hover {
      background: var(--csa-blue-2);
    }
    `;

      case "danger":
        return `
    background: none;
    border: 0.0625rem solid var(--csa-red);
    color: var(--csa-red);

    &:hover {
      background: var(--csa-red-2);
    }
    `;

      default:
        return "";
    }
  }}
`;

export default CSAButton;
