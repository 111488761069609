import { gql } from "apollo-boost";
import { Task, TaskRepeat, TaskStatus } from "../../types";

export const GET_MARKETING_YEAR_PLAN = gql`
  query GetMarketingPlan($year: Int!) {
    marketingYearPlan(year: $year) {
      availableYears
      columnOrder
      columns
      months
      tasks
    }
  }
`;

export interface YearPlanTask {
  description?: string;
  dueDate?: string;
  id?: string;
  repeat?: TaskRepeat;
  status: TaskStatus;
  title?: string;
}

export interface GetMarketingYearPlanData {
  marketingYearPlan?: {
    availableYears?: number[];
    columnOrder?: string[];
    columns?: {
      [key: string]: {
        id?: string;
        taskOrder?: string[];
        title?: string;
      };
    };
    months?: {
      [key: string]: {
        tasks: YearPlanTask[];
      };
    };
    tasks?: {
      [key: string]: YearPlanTask;
    };
  };
}

export interface GetMarketingYearPlanVariables {
  year: number;
}

export const ADD_COLUMN = gql`
  mutation AddColumn($title: String!, $year: Int!) {
    addMarketingYearPlanColumn(title: $title, year: $year) {
      code
      message
    }
  }
`;

export interface AddColumnVariables {
  title: string;
  year: number;
}

export const COPY_COLUMNS = gql`
  mutation CopyColumns($copyYear: Int!, $year: Int!) {
    copyMarketingYearColumns(copyYear: $copyYear, year: $year) {
      code
      message
    }
  }
`;

export interface CopyColumnsVariables {
  copyYear: number;
  year: number;
}

export const DELETE_COLUMN = gql`
  mutation DeleteColumn($id: ID!, $year: Int!) {
    deleteMarketingYearPlanColumn(id: $id, year: $year) {
      code
      message
    }
  }
`;

export interface DeleteColumnVariables {
  id: string;
  year: number;
}

export const EDIT_COLUMN_TITLE = gql`
  mutation EditColumnTitle($id: ID!, $title: String!) {
    editMarketingYearPlanColumnTitle(id: $id, title: $title) {
      code
      message
    }
  }
`;

export interface EditColumnTitleVariables {
  id: string;
  title: string;
}

export const ADD_TASK = gql`
  mutation AddMarketingPlanTask(
    $colId: ID!
    $description: String
    $dueDate: String
    $repeat: MarketingPlanTaskRepeat!
    $status: MarketingPlanTaskStatus!
    $title: String!
  ) {
    addMarketingYearPlanTask(
      colId: $colId
      description: $description
      dueDate: $dueDate
      repeat: $repeat
      status: $status
      title: $title
    ) {
      code
      message
    }
  }
`;

export interface AddTaskVariables extends Task {
  colId: string;
}

export const EDIT_TASK = gql`
  mutation EditMarketingPlanTask(
    $id: ID!
    $description: String
    $dueDate: String
    $repeat: MarketingPlanTaskRepeat!
    $status: MarketingPlanTaskStatus!
    $title: String!
  ) {
    editMarketingYearPlanTask(
      id: $id
      description: $description
      dueDate: $dueDate
      repeat: $repeat
      status: $status
      title: $title
    ) {
      code
      message
    }
  }
`;

export interface EditTaskVariables extends Task {
  id: string;
}

export const MOVE_TASK = gql`
  mutation MoveMarketingPlanTask(
    $destination: MoveTaskInput!
    $id: ID!
    $source: MoveTaskInput!
  ) {
    moveMarketingPlanTask(destination: $destination, id: $id, source: $source) {
      code
      message
    }
  }
`;

export interface MoveTaskVariables {
  destination: {
    colId: string;
    index: number;
  };
  id: string;
  source: {
    colId: string;
    index: number;
  };
}

export const DELETE_TASK = gql`
  mutation DeleteMarkingPlanTask($id: ID!, $month: Int, $year: Int!) {
    deleteMarketingYearPlanTask(id: $id, month: $month, year: $year) {
      code
      message
    }
  }
`;

export interface DeleteTaskVariables {
  id: string;
  month?: number;
  year: number;
}

export const UPDATE_YEAR_PLAN = gql`
  mutation UpdateYearPlan($columnOrder: [ID!]!, $months: JSON!, $year: Int!) {
    updateMarketingYearPlan(
      columnOrder: $columnOrder
      months: $months
      year: $year
    ) {
      code
      message
    }
  }
`;

export interface UpdateYearPlanVariables {
  columnOrder: string[];
  months: {
    [key: string]: {
      tasks: YearPlanTask[];
    };
  };
  year: number;
}
