import styled from "@emotion/styled";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useState } from "react";
import Modal from "../../components/Modal";
import formatDate from "../../helpers/format-date";
import parseDate from "../../helpers/parse-date";
import EditPatientForm from "./EditPatientForm";
import {
  DeleteNewPatientVariables,
  EditNewPatientVariables,
  NewPatient,
  NewPatientStatus,
} from ".";

const Container = styled.li<{ status: NewPatientStatus }>`
  background: ${({ status }) => {
    switch (status) {
      case "NONE":
        return "var(--csa-bg-secondary)";

      case "SUCCESS":
        return "var(--csa-green-2)";

      case "DID_NOT_FOLLOW_PLAN":
        return "var(--csa-red-2)";

      case "REFERRED_OUT":
        return "var(--csa-blue-2)";

      case "AS_NEEDED":
        return "var(--csa-orange-2)";

      default:
        return "var(--csa-bg-secondary)";
    }
  }};
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  position: relative;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const CheckList = styled.span`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 0.75rem;
  justify-content: space-between;
  text-align: center;

  @media (min-width: 576px) {
    flex-direction: row;
    flex-shrink: 1;
    margin-left: 1.5rem;
  }
`;

const Check = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-bottom: 0.5rem;

  @media (min-width: 576px) {
    margin-right: 1rem;
  }
`;

const EditButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  color: var(--csa-blue);
  font-size: 0.875rem;
  padding: 0.25rem;
`;

const Icon = styled(FontAwesomeIcon)<{ checked: boolean }>`
  opacity: ${({ checked }) => (checked ? 1 : 0.2)};
  transition: all 0.1s;
  margin-right: 0.25rem;
  ${({ checked }) => checked && "color: var(--csa-blue);"}
`;

export interface NewPatientLineItemProps {
  onDelete: (variables: DeleteNewPatientVariables) => void;
  onEdit: (variables: EditNewPatientVariables) => Promise<void>;
  patient: NewPatient;
  readOnly: boolean;
  referralTypeOptions: string[];
}

const NewPatientLineItem: FC<NewPatientLineItemProps> = ({
  onDelete,
  onEdit,
  patient,
  readOnly,
  referralTypeOptions,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Container key={patient.id} status={patient.status}>
      <Content>
        <div>
          <p>
            <span>{patient.name}</span>
            {patient.date &&
              ` | Visit: ${formatDate(parseDate(patient.date), {
                showDate: true,
                showMonth: true,
                showYear: true,
              })}`}
            {patient.doctor && ` | ${patient.doctor}`}
          </p>

          <p>
            Referral:&nbsp;
            {patient.referralType ? patient.referralType : "None"}
            {patient.referralSource && ` - ${patient.referralSource}`}
          </p>
        </div>
        <CheckList>
          <Check>
            <p>Welcome Letter Sent</p>
            <Icon checked={patient.welcomeLetterSent} icon={faCheck} />
          </Check>
          <Check>
            <p>Initial Letter Sent</p>
            <Icon
              checked={patient.primaryDoctorInitialLetterSent}
              icon={faCheck}
            />
          </Check>
          <Check>
            <p>Final Letter Sent</p>
            <Icon
              checked={patient.primaryDoctorFinalLetterSent}
              icon={faCheck}
            />
          </Check>
          <Check>
            <p>Referer Thank You Sent</p>
            <Icon checked={patient.referrerThankYouSent} icon={faCheck} />
          </Check>
          <Check>
            <p>Google Review Request Sent</p>
            <Icon checked={patient.googleReviewRequestSent} icon={faCheck} />
          </Check>

          <EditButton onClick={() => setIsEditing(true)}>Details</EditButton>
        </CheckList>
      </Content>

      <Modal onClose={() => setIsEditing(false)} show={isEditing}>
        <EditPatientForm
          onDelete={() => onDelete({ id: patient.id ?? "" })}
          onSubmit={async (variables) => {
            await onEdit({ ...variables, id: patient.id ?? "" });
            setIsEditing(false);
          }}
          patient={patient}
          readOnly={readOnly}
          referralTypeOptions={referralTypeOptions}
        />
      </Modal>
    </Container>
  );
};

export default NewPatientLineItem;
