import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { gql } from "apollo-boost";
import React, { FC, useEffect, useState } from "react";
import CSALink from "../../components/CSALink";
import Notification, { NotificationProps } from "../../components/Notification";
import Tab from "../../components/Tab";
import Tabs from "../../components/Tabs";
import YearSelector from "../../components/YearSelector";
import { graphQLErrorsToParagraphs } from "../../helpers/graphql";
import useRecallLastView from "../../hooks/use-recall-last-view";
import { CurrentUser } from "../../queries";
import { Response } from "../../types";
import Table from "./Table";
import { Indicator } from "./indicator-scorecard";

type Quarter = "q1" | "q2" | "q3" | "q4";

const Section = styled.section`
  position: relative;
`;

const H1 = styled.h1`
  margin-bottom: 8rem;
`;

const Nav = styled.nav`
  align-items: start;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 3rem;
  width: 100%;
`;

const NavTabs = styled(Tabs)`
  flex: 1 0 auto;
`;

const IndicatorScorecardYearSelector = styled(YearSelector)`
  margin-left: 5rem;
`;

const Container = styled.div``;

const GET_INDICATOR_SCORECARD = gql`
  query GetIndicatorScorecard($year: Int!) {
    indicatorScorecard(year: $year) {
      availableYears
      id
      leadingIndicators {
        goals
        id
        scores
        title
        who
      }
      trailingIndicators {
        goals
        id
        scores
        title
        who
      }
    }
  }
`;

interface GetIndicatorScorecardData {
  indicatorScorecard?: {
    availableYears?: number[];
    id?: string;
    leadingIndicators?: Indicator[];
    trailingIndicators?: Indicator[];
  };
}

interface GetIndicatorScorecardVariables {
  year: number;
}

const ADD_INDICATOR = gql`
  mutation AddIndicator(
    $goals: String!
    $title: String!
    $type: IndicatorType!
    $who: String!
    $year: Int!
  ) {
    addIndicator(
      goals: $goals
      title: $title
      type: $type
      who: $who
      year: $year
    ) {
      code
      message
    }
  }
`;

interface AddIndicatorVariables {
  goals: string;
  title: string;
  type: "LEADING_INDICATOR" | "TRAILING_INDICATOR";
  who: string;
  year: number;
}

const DELETE_INDICATOR = gql`
  mutation deleteIndicator($id: ID!) {
    deleteIndicator(id: $id) {
      code
      message
    }
  }
`;

interface DeleteIndicatorVariables {
  id: string;
}

const getWeeks = (year: number) => {
  const dates = [];
  const today = new Date(year, 0);

  const day = today.getDay();

  const diff =
    day === 1 ? 1 : today.getDate() + 7 - (day - (day === 0 ? -6 : 1)); // Adjustment for Sunday;
  const d = new Date(today.setDate(diff));

  while (d.getFullYear() === year) {
    dates.push(new Date(d));
    d.setDate(d.getDate() + 7);
  }

  return dates;
};

const inThisQuarter = (quarter: Quarter) => (date: Date) => {
  switch (quarter) {
    case "q1":
      return date.getMonth() >= 0 && date.getMonth() <= 2;
    case "q2":
      return date.getMonth() >= 3 && date.getMonth() <= 5;
    case "q3":
      return date.getMonth() >= 6 && date.getMonth() <= 8;
    case "q4":
      return date.getMonth() >= 9 && date.getMonth() <= 11;
    default:
      return false;
  }
};

export interface IndicatorScorecardProps {
  currentUser?: CurrentUser;
}

const IndicatorScorecard: FC<IndicatorScorecardProps> = ({ currentUser }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [notification, setNotification] = useState<NotificationProps>({});
  const [quarter, setQuarter] = useState<Quarter>("q1");
  const { data, refetch } = useQuery<
    GetIndicatorScorecardData,
    GetIndicatorScorecardVariables
  >(GET_INDICATOR_SCORECARD, {
    fetchPolicy: "cache-and-network",
    variables: { year },
  });

  const [addIndicator] = useMutation<Response, AddIndicatorVariables>(
    ADD_INDICATOR,
    {
      onCompleted: () =>
        setNotification({
          children: <p>Indicator added</p>,
          type: "success",
        }),
      onError: (e) =>
        setNotification({
          children: graphQLErrorsToParagraphs(e.graphQLErrors),
          type: "error",
        }),
      refetchQueries: [{ query: GET_INDICATOR_SCORECARD, variables: { year } }],
    },
  );

  const [deleteIndicator] = useMutation<Response, DeleteIndicatorVariables>(
    DELETE_INDICATOR,
    {
      onCompleted: () =>
        setNotification({
          children: <p>Indicator deleted</p>,
          type: "success",
        }),
      onError: (e) =>
        setNotification({
          children: graphQLErrorsToParagraphs(e.graphQLErrors),
          type: "error",
        }),
      refetchQueries: [{ query: GET_INDICATOR_SCORECARD, variables: { year } }],
    },
  );

  useRecallLastView(
    "indidcator-scorecard-view",
    { quarter, year },
    { setQuarter, setYear },
  );

  useEffect(() => {
    if (year) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  const weeks = getWeeks(year).filter(inThisQuarter(quarter));

  const onUpdate = () => {
    setNotification({
      children: <p>Indicator updated</p>,
      type: "success",
    });

    refetch();
  };

  const onUpdateError = (reason?: string) => {
    setNotification({
      children: <p>{reason ?? "Sorry something went wrong"}</p>,
      type: "error",
    });

    refetch();
  };

  return (
    <>
      <Notification fadeOut float type={notification.type} width="100%">
        {notification.children}
      </Notification>

      <Section>
        <H1>Indicator Scorecard</H1>

        <Nav>
          <NavTabs numberOfTabs={4}>
            <Tab selected={quarter === "q1"}>
              <CSALink
                onClick={() => setQuarter("q1")}
                selected={quarter === "q1"}
                to="/indicator-scorecard"
              >
                Q1 (Jan-Mar)
              </CSALink>
            </Tab>
            <Tab selected={quarter === "q2"}>
              <CSALink
                onClick={() => setQuarter("q2")}
                selected={quarter === "q2"}
                to="/indicator-scorecard"
              >
                Q2 (Apr-Jun)
              </CSALink>
            </Tab>
            <Tab selected={quarter === "q3"}>
              <CSALink
                onClick={() => setQuarter("q3")}
                selected={quarter === "q3"}
                to="/indicator-scorecard"
              >
                Q3 (Jul-Sep)
              </CSALink>
            </Tab>
            <Tab selected={quarter === "q4"}>
              <CSALink
                onClick={() => setQuarter("q4")}
                selected={quarter === "q4"}
                to="/indicator-scorecard"
              >
                Q4 (Oct-Dec)
              </CSALink>
            </Tab>
          </NavTabs>

          <IndicatorScorecardYearSelector
            availableYears={data?.indicatorScorecard?.availableYears}
            setYear={setYear}
            year={year}
          />
        </Nav>

        <Container>
          <Table
            caption="Leading Indicators"
            indicators={data?.indicatorScorecard?.leadingIndicators ?? []}
            onAdd={(variables) =>
              addIndicator({
                variables: { ...variables, type: "LEADING_INDICATOR", year },
              })
            }
            onDelete={(variables) => deleteIndicator({ variables })}
            onUpdate={onUpdate}
            onUpdateError={onUpdateError}
            readOnly={!currentUser?.active}
            weeks={weeks}
          />

          <Table
            caption="Trailing Indicators"
            indicators={data?.indicatorScorecard?.trailingIndicators ?? []}
            onAdd={(variables) =>
              addIndicator({
                variables: { ...variables, type: "TRAILING_INDICATOR", year },
              })
            }
            onDelete={(variables) => deleteIndicator({ variables })}
            onUpdate={onUpdate}
            onUpdateError={onUpdateError}
            readOnly={!currentUser?.active}
            weeks={weeks}
          />
        </Container>
      </Section>
    </>
  );
};

export default IndicatorScorecard;
