import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import CSAButton from "../components/CSAButton";
import CSALink from "../components/CSALink";
import CenteredContainer from "../components/CenteredContainer";
import FormItem from "../components/FormItem";
import HomeImageLink from "../components/HomeImageLink";
import PasswordHelper from "../components/PasswordHelper";
import { isGraphQLErrorCode } from "../helpers/graphql";
import { Response } from "../types";

const ACTIVATE_PARTICIPANT = gql`
  mutation ActivateParticipant(
    $email: String!
    $password: String!
    $token: String!
  ) {
    activateParticipant(email: $email, password: $password, token: $token) {
      code
      message
    }
  }
`;

interface ActivateParticipantData {
  activatePartcipant: Response;
}

interface ActivateParticipantVariables {
  email: string;
  password: string;
  token: string;
}

const LinkButton = CSAButton.withComponent(CSALink);

const ExpiredBody: FC = () => (
  <CenteredContainer>
    <HomeImageLink />
    <h1>Sorry your invite has expired</h1>
    <p>Please contact your inviter to receive an updated invite</p>
    <LinkButton to="/">Home</LinkButton>
  </CenteredContainer>
);

const FailureBody: FC = () => (
  <CenteredContainer>
    <HomeImageLink />
    <h1>Sorry we couldn&apos;t activate your account</h1>
    <p>Please contact your inviter to receive a new invite</p>
    <LinkButton to="/">Home</LinkButton>
  </CenteredContainer>
);

const Submit = FormItem.withComponent(CSAButton);

interface FormValues {
  password: string;
  "password-confirmation": string;
}

const ActivateParticipant: FC = () => {
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const { errors, getValues, handleSubmit, register, watch } =
    useForm<FormValues>({
      mode: "onBlur",
    });
  const history = useHistory();
  const [activatePartcipant, { error }] = useMutation<
    ActivateParticipantData,
    ActivateParticipantVariables
  >(ACTIVATE_PARTICIPANT);

  const params = new URLSearchParams(useLocation().search);
  const email = decodeURIComponent(params.get("email") ?? "");
  const token = params.get("token") ?? "";

  const onSubmit = handleSubmit(async ({ password }) => {
    await activatePartcipant({ variables: { email, password, token } });

    history.push("/login", { passwordReset: true });
  });

  if (error) {
    if (isGraphQLErrorCode(error, "EXPIRED_TOKEN")) {
      return <ExpiredBody />;
    }

    return <FailureBody />;
  }

  return (
    <CenteredContainer>
      <HomeImageLink />
      <h1>Activate Account</h1>
      <form onSubmit={onSubmit}>
        <FormItem
          ref={register({
            required: "Please enter a password",
            validate: () =>
              isPasswordValid || "Password needs to match the rules below",
          })}
          error={errors.password?.message?.toString()}
          label="Password"
          maxLength={40}
          name="password"
          placeholder="********"
          required
          type="password"
        />

        <PasswordHelper
          password={watch("password")}
          setIsValid={setIsPasswordValid}
        />

        <FormItem
          ref={register({
            required: "Please confirm your password",
            validate: (v) =>
              getValues("password") === v || "Passwords need to match",
          })}
          error={errors["password-confirmation"]?.message?.toString()}
          label="Password Confirmation"
          maxLength={40}
          name="password-confirmation"
          placeholder="********"
          required
          type="password"
        />

        <Submit type="submit">Activate</Submit>
      </form>
    </CenteredContainer>
  );
};

export default ActivateParticipant;
