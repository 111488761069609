import { EventInput } from "@fullcalendar/react";
import { CalendarEvent } from "../types";

export default ({
  duration,
  exdate,
  repeatFreq,
  repeatUntil,
  startDate: sd,
  ...props
}: CalendarEvent): EventInput => ({
  ...props,
  duration: repeatFreq !== "NONE" ? { ms: duration } : undefined,
  end: new Date(new Date(sd).getTime() + duration),
  exdate,
  exdateRef: exdate, // For easier reference
  repeatFreq, // Added to reference later
  repeatUntil, // Added to reference later
  rrule:
    repeatFreq !== "NONE"
      ? {
          dtstart: sd,
          freq: repeatFreq.toLowerCase(),
          until: repeatUntil,
        }
      : undefined,
  start: new Date(sd),
  startDateRef: sd,
});
