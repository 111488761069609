import styled from "@emotion/styled";
import CSAButton from "../../components/CSAButton";

const ConfirmButton = styled(CSAButton)<{
  marginBottom?: string;
}>`
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  width: 100%;
`;

export default ConfirmButton;
