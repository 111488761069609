import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { gql } from "apollo-boost";
import React, { FC, Suspense, lazy, useState } from "react";
import CSAButton from "../../components/CSAButton";
import Modal from "../../components/Modal";
import Notification, { NotificationProps } from "../../components/Notification";
import YearSelector from "../../components/YearSelector";
import useRecallLastView from "../../hooks/use-recall-last-view";
import { CurrentUser } from "../../queries";
import { Response } from "../../types";
import EditPatientForm from "./EditPatientForm";
import NewPatientLineItem from "./NewPatientLineItem";

const Chart = styled(lazy(() => import("react-apexcharts")))``;

const H1 = styled.h1``;

const NewPatientLogYearSelector = styled(YearSelector)`
  margin-top: 1.25rem;
`;

const AddNewPatientButton = styled(CSAButton)`
  margin-bottom: 1rem;
`;

const Flex = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Section = styled.section`
  position: relative;
  margin-bottom: 4rem;
`;

const ChartsSection = styled.section`
  display: flex;
  gap: 1rem;
  justiy-content: space-between;
  flex-flow: column;

  @media (min-width: 768px) {
    flex-flow: row;
  }
`;

const ChartContainer = styled.div`
  width: 100%;
`;

const Ul = styled.ul`
  padding-top: 5rem;
`;

export type NewPatientStatus =
  | "NONE"
  | "SUCCESS"
  | "DID_NOT_FOLLOW_PLAN"
  | "REFERRED_OUT"
  | "AS_NEEDED";

const newPatientStatusMapper = (status: NewPatientStatus): string =>
  ({
    AS_NEEDED: "As Needed",
    DID_NOT_FOLLOW_PLAN: "Did not follow treatment plan",
    NONE: "None",
    REFERRED_OUT: "Referred out",
    SUCCESS: "Success",
  }[status]);

const ADD_NEW_PATIENT = gql`
  mutation AddNewPatient(
    $date: String
    $dateOfBirth: String
    $doctor: String
    $googleReviewRequestSent: Boolean!
    $name: String
    $patientEmail: String
    $primaryDoctor: String
    $primaryDoctorFinalLetterSent: Boolean!
    $primaryDoctorInitialLetterSent: Boolean!
    $referralSource: String
    $referralType: String
    $referrerThankYouSent: Boolean!
    $specialNotes: String
    $status: NewPatientStatus!
    $welcomeLetterSent: Boolean!
  ) {
    addNewPatient(
      date: $date
      dateOfBirth: $dateOfBirth
      doctor: $doctor
      googleReviewRequestSent: $googleReviewRequestSent
      name: $name
      patientEmail: $patientEmail
      primaryDoctor: $primaryDoctor
      primaryDoctorFinalLetterSent: $primaryDoctorFinalLetterSent
      primaryDoctorInitialLetterSent: $primaryDoctorInitialLetterSent
      referralSource: $referralSource
      referralType: $referralType
      referrerThankYouSent: $referrerThankYouSent
      specialNotes: $specialNotes
      status: $status
      welcomeLetterSent: $welcomeLetterSent
    ) {
      code
      message
    }
  }
`;

export interface AddNewPatientVariables {
  date?: string;
  dateOfBirth?: string;
  doctor?: string;
  googleReviewRequestSent: boolean;
  name?: string;
  patientEmail?: string;
  primaryDoctor?: string;
  primaryDoctorFinalLetterSent: boolean;
  primaryDoctorInitialLetterSent: boolean;
  referralSource?: string;
  referralType: string;
  referrerThankYouSent: boolean;
  specialNotes?: string;
  status: NewPatientStatus;
  welcomeLetterSent: boolean;
}

export const DELETE_NEW_PATIENT = gql`
  mutation DeleteNewPatient($id: ID!) {
    deleteNewPatient(id: $id) {
      code
      message
    }
  }
`;

export interface DeleteNewPatientVariables {
  id: string;
}

const EDIT_NEW_PATIENT = gql`
  mutation EditNewPatient(
    $date: String
    $dateOfBirth: String
    $doctor: String
    $googleReviewRequestSent: Boolean!
    $id: ID!
    $name: String
    $patientEmail: String
    $primaryDoctor: String
    $primaryDoctorFinalLetterSent: Boolean!
    $primaryDoctorInitialLetterSent: Boolean!
    $referralSource: String
    $referralType: String
    $referrerThankYouSent: Boolean!
    $specialNotes: String
    $status: NewPatientStatus!
    $welcomeLetterSent: Boolean!
  ) {
    editNewPatient(
      date: $date
      dateOfBirth: $dateOfBirth
      doctor: $doctor
      googleReviewRequestSent: $googleReviewRequestSent
      id: $id
      name: $name
      patientEmail: $patientEmail
      primaryDoctor: $primaryDoctor
      primaryDoctorFinalLetterSent: $primaryDoctorFinalLetterSent
      primaryDoctorInitialLetterSent: $primaryDoctorInitialLetterSent
      referralSource: $referralSource
      referralType: $referralType
      referrerThankYouSent: $referrerThankYouSent
      specialNotes: $specialNotes
      status: $status
      welcomeLetterSent: $welcomeLetterSent
    ) {
      code
      message
    }
  }
`;

export interface EditNewPatientVariables {
  date?: string;
  dateOfBirth?: string;
  doctor?: string;
  googleReviewRequestSent: boolean;
  id: string;
  name?: string;
  patientEmail?: string;
  primaryDoctor?: string;
  primaryDoctorFinalLetterSent: boolean;
  primaryDoctorInitialLetterSent: boolean;
  referralSource?: string;
  referralType: string;
  referrerThankYouSent: boolean;
  specialNotes?: string;
  status: NewPatientStatus;
  welcomeLetterSent: boolean;
}

const GET_NEW_PATIENT_LOG = gql`
  query GetNewPatientLog($year: Int!) {
    newPatientLog(year: $year) {
      availableYears
      newPatients {
        date
        dateOfBirth
        doctor
        googleReviewRequestSent
        id
        name
        patientEmail
        primaryDoctor
        primaryDoctorFinalLetterSent
        primaryDoctorInitialLetterSent
        referralSource
        referralType
        referrerThankYouSent
        specialNotes
        status
        welcomeLetterSent
      }
      referralTypes {
        count
        name
      }
      statuses {
        count
        name
      }
    }
  }
`;

export interface NewPatient {
  date: string;
  dateOfBirth: string;
  doctor: string;
  googleReviewRequestSent: boolean;
  id: string;
  name: string;
  patientEmail: string;
  primaryDoctor: string;
  primaryDoctorFinalLetterSent: boolean;
  primaryDoctorInitialLetterSent: boolean;
  referralSource: string;
  referralType: string;
  referrerThankYouSent: boolean;
  specialNotes: string;
  status: NewPatientStatus;
  welcomeLetterSent: boolean;
}

export interface GetNewPatientLogData {
  newPatientLog?: {
    availableYears?: number[];
    newPatients?: NewPatient[];
    referralTypes?: { count: number; name: string }[];
    statuses?: { count: number; name: string }[];
  };
}

export interface GetNewPatientLogVariables {
  year: number;
}

export interface NewPatientLogProps {
  currentUser?: CurrentUser;
}

const NewPatientLog: FC<NewPatientLogProps> = ({ currentUser }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [isAdding, setIsAdding] = useState(false);
  const [notification, setNotification] = useState<NotificationProps>({});

  const [editNewPatient] = useMutation<Response, EditNewPatientVariables>(
    EDIT_NEW_PATIENT,
    {
      onCompleted: () =>
        setNotification({
          children: <p>Updated successfully</p>,
          type: "success",
        }),
      onError: () =>
        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        }),
      refetchQueries: [{ query: GET_NEW_PATIENT_LOG, variables: { year } }],
    },
  );

  const [addNewPatient] = useMutation<Response, AddNewPatientVariables>(
    ADD_NEW_PATIENT,
    {
      onCompleted: () =>
        setNotification({
          children: <p>Added successfully</p>,
          type: "success",
        }),
      onError: () =>
        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        }),
      refetchQueries: [{ query: GET_NEW_PATIENT_LOG, variables: { year } }],
    },
  );

  const [deleteNewPatient] = useMutation<Response, DeleteNewPatientVariables>(
    DELETE_NEW_PATIENT,
    {
      onCompleted: () =>
        setNotification({
          children: <p>Deleted successfully</p>,
          type: "success",
        }),
      onError: () =>
        setNotification({
          children: <p>Sorry something went wrong</p>,
          type: "error",
        }),
      refetchQueries: [{ query: GET_NEW_PATIENT_LOG, variables: { year } }],
    },
  );

  const { data } = useQuery<GetNewPatientLogData, GetNewPatientLogVariables>(
    GET_NEW_PATIENT_LOG,
    {
      fetchPolicy: "cache-and-network",
      variables: { year },
    },
  );

  useRecallLastView("new-patient-log", { year }, { setYear });

  const filteredReferralTypes =
    data?.newPatientLog?.referralTypes
      ?.filter(({ count }) => count > 0)
      .map(({ name, ...type }) => ({
        ...type,
        name: name === "" ? "None" : name,
      })) ?? [];

  const statuses =
    data?.newPatientLog?.statuses?.map(({ name, ...type }) => ({
      ...type,
      name: newPatientStatusMapper(name as NewPatientStatus),
    })) ?? [];

  return (
    <>
      <Notification fadeOut float type={notification.type} width="100%">
        {notification.children}
      </Notification>

      <Section>
        <Flex>
          <H1>New Patient Log</H1>
          <NewPatientLogYearSelector
            availableYears={data?.newPatientLog?.availableYears}
            readonly
            setYear={setYear}
            year={year}
          />
        </Flex>
        <Ul>
          <AddNewPatientButton
            disabled={!currentUser?.active}
            onClick={() => setIsAdding(true)}
            type="button"
          >
            Add New Patient
          </AddNewPatientButton>
          {data?.newPatientLog?.newPatients?.map((patient) => (
            <NewPatientLineItem
              key={patient.id}
              onDelete={async (variables) => deleteNewPatient({ variables })}
              onEdit={async (variables) => {
                await editNewPatient({ variables });
              }}
              patient={patient}
              readOnly={!currentUser?.active}
              referralTypeOptions={
                data?.newPatientLog?.referralTypes?.map(({ name }) => name) ??
                []
              }
            />
          ))}
        </Ul>
      </Section>

      <ChartsSection>
        <ChartContainer>
          <h4>Referral Types</h4>
          <Suspense fallback={<div />}>
            <Chart
              key={JSON.stringify(filteredReferralTypes)}
              options={{
                labels: filteredReferralTypes.map(({ name }) => name),
                legend: {
                  formatter: (
                    seriesName: string,
                    { seriesIndex }: { seriesIndex: number },
                  ) =>
                    `${seriesName} (${filteredReferralTypes[seriesIndex].count})`,
                  width: 150,
                },
              }}
              series={filteredReferralTypes.map(({ count }) => count)}
              type="pie"
            />
          </Suspense>
        </ChartContainer>

        <ChartContainer>
          <h4>Status</h4>
          <Suspense fallback={<div />}>
            <Chart
              key={JSON.stringify(statuses)}
              options={{
                labels: statuses.map(({ name }) => name),
                legend: {
                  formatter: (
                    seriesName: string,
                    { seriesIndex }: { seriesIndex: number },
                  ) => `${seriesName} (${statuses[seriesIndex].count})`,
                  width: 150,
                },
                theme: { palette: "palette4" },
              }}
              series={statuses.map(({ count }) => count)}
              type="pie"
            />
          </Suspense>
        </ChartContainer>
      </ChartsSection>

      <Modal onClose={() => setIsAdding(false)} show={isAdding}>
        <EditPatientForm
          onSubmit={async (variables) => {
            await addNewPatient({ variables });
            setIsAdding(false);
          }}
          readOnly={!currentUser?.active}
          referralTypeOptions={
            data?.newPatientLog?.referralTypes?.map(({ name }) => name) ?? []
          }
        />
      </Modal>
    </>
  );
};

export default NewPatientLog;
