import styled from "@emotion/styled";
import React, { FC } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import CSAButton from "./CSAButton";
import CSAInput from "./CSAInput";

export interface ImageUploadProps {
  className?: string;
  disabled?: boolean;
  imgUrl?: string;
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
}

const borderWidth = "0.0625rem";

const Input = styled(CSAInput)`
  box-sizing: border-box;
  height: fit-content;
  width: 25rem;
`;

const UploadArea = styled.div<{ isDragActive?: boolean }>`
  align-items: center;
  border: solid ${borderWidth} var(--csa-border);
  display: flex;
  height: 4.5rem;
  justify-content: center;
  margin-top: 0.25rem;
  padding: 0.5rem;
  border: ${({ isDragActive }): string =>
    isDragActive
      ? `dashed ${borderWidth} black`
      : `solid ${borderWidth} var(--csa-border)`};

  background-color: ${({ isDragActive }): string =>
    isDragActive ? "lightgrey" : "transparent"};
`;

const Preview = styled.img`
  display: block;
  height: 100%;
  width: auto;
`;

const UpdateButton = styled(CSAButton)`
  margin-top: -${borderWidth};
  width: 100%;
`;

const ImageUpload: FC<ImageUploadProps> = ({
  className,
  disabled,
  onDrop,
  imgUrl,
}) => {
  const { getInputProps, getRootProps, isDragActive, open } = useDropzone({
    accept: ["image/*"],
    maxSize: 4000000,
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  return (
    <Input
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getInputProps()}
      className={className}
      disabled={disabled}
      label="Company Logo"
      labelProps={getRootProps()}
    >
      <UploadArea isDragActive={isDragActive}>
        {imgUrl ? <Preview alt="Logo Upload" src={imgUrl} /> : "Your Logo"}
      </UploadArea>

      <UpdateButton onClick={open} type="button">
        Change Logo
      </UpdateButton>
    </Input>
  );
};

export default ImageUpload;
