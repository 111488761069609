import styled from "@emotion/styled";
import { faEllipsisH, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, FC, useState } from "react";

const Container = styled.div`
  position: absolute;
  right: 2.5rem;
  top: 0.25rem;
`;

const Button = styled.button`
  appearance: none;
  background: none;
  border: 0;
  margin: 0;
  opacity: 0.5;
  padding: 0;
  transition: all 0.1s;
  position: absolute;
  top: 0.25rem;
  right: 0;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const ItemList = styled.ul`
  border-radius: 0.25rem;
  border: 0.0625rem solid var(--csa-border);
  box-shadow: 0 2px 8px 0 rgba(12, 12, 13, 0.1);
  position: absolute;
  top: 1.25rem;
  right: 0;
`;

const Item = styled.li`
  &:hover {
    background: var(--csa-blue-2);
  }

  & > * {
    padding: 0.25rem 1rem;
  }
`;

const DeleteButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  color: var(--csa-red);
  margin: 0;

  &:hover {
    cursor: pointer;
  }
`;

export interface MenuProps {
  ellipsisOrientation: "horizontal" | "vertical";
  onDelete?: () => void;
  style?: CSSProperties;
}

const Menu: FC<MenuProps> = ({ ellipsisOrientation, onDelete, style }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container style={style}>
      <Button onClick={() => setIsOpen(!isOpen)} type="button">
        {ellipsisOrientation === "horizontal" && (
          <FontAwesomeIcon icon={faEllipsisH} />
        )}
        {ellipsisOrientation === "vertical" && (
          <FontAwesomeIcon icon={faEllipsisV} />
        )}
      </Button>
      {isOpen && (
        <ItemList>
          {onDelete && (
            <Item>
              <DeleteButton onClick={onDelete} type="button">
                Delete
              </DeleteButton>
            </Item>
          )}
        </ItemList>
      )}
    </Container>
  );
};

export default Menu;
