import styled from "@emotion/styled";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import CSAButton from "../../components/CSAButton";
import Menu from "../../components/Menu";
import ModalFormInput from "../../components/ModalFormInput";
import ModalFormLabel from "../../components/ModalFormLabel";
import ModalFormTitleInput from "../../components/ModalFormTitleInput";
import StatusLabel from "../../components/StatusLabel";
import StatusOption from "../../components/StatusOption";
import StatusSelect from "../../components/StatusSelect";
import { Task } from "../../types";

const Container = styled.div`
  position: relative;
`;

const Form = styled.form`
  padding: 7rem;
`;

const Select = ModalFormInput.withComponent("select");
const Textarea = ModalFormInput.withComponent("textarea");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StatusIcon = styled<any>(FontAwesomeIcon)`
  color: ${({ status }) => {
    switch (status) {
      case "NEW":
        return "var(--csa-grey)";

      case "PROGRESS":
        return "var(--csa-blue)";

      case "COMPLETED":
        return "var(--csa-green)";

      case "HOLD":
        return "var(--csa-orange)";

      case "CANCELLED":
        return "var(--csa-red)";

      default:
        return "var(--csa-grey)";
    }
  }};
`;

const NullOption = styled.option`
  opacity: 0.6;
`;

const Submit = styled(CSAButton)`
  display: block;
`;

export interface TaskFormProps {
  deleteable: boolean;
  onDelete?: () => Promise<void>;
  onSubmit: (task: Task) => Promise<void>;
  submitButtonMsg: string;
  task?: Task;
}

const TaskForm: FC<TaskFormProps> = ({
  deleteable,
  onDelete,
  onSubmit,
  submitButtonMsg,
  task,
}) => {
  const { errors, handleSubmit, register, watch } = useForm<Task>({
    defaultValues: { ...task },
  });

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {deleteable && (
          <Menu
            ellipsisOrientation="horizontal"
            onDelete={() => {
              if (onDelete) {
                onDelete();
              }
            }}
          />
        )}
        <StatusLabel htmlFor="status">
          Status:&nbsp;
          <StatusIcon icon={faCircle} status={watch("status")} />
          <StatusSelect ref={register} name="status">
            <StatusOption value="NEW">New</StatusOption>
            <StatusOption value="PROGRESS">In Progress</StatusOption>
            <StatusOption value="COMPLETED">Completed</StatusOption>
            <StatusOption value="HOLD">On hold</StatusOption>
            <StatusOption value="CANCELLED">Cancelled</StatusOption>
          </StatusSelect>
        </StatusLabel>
        <ModalFormTitleInput
          ref={register({ required: true })}
          error={!!errors.title}
          name="title"
          placeholder="Enter title of task"
          type="text"
        />
        <ModalFormLabel htmlFor="dueDate">
          Due Date
          <ModalFormInput
            ref={register}
            error={!!errors.dueDate}
            maxLength={20}
            name="dueDate"
            placeholder="No due date"
            type="text"
          />
        </ModalFormLabel>
        <ModalFormLabel htmlFor="repeat">
          Repeat
          <Select
            ref={register}
            error={!!errors.repeat}
            name="repeat"
            unselected={watch("repeat") === "NONE"}
          >
            <NullOption value="NONE">Does this task repeat?</NullOption>
            <option value="DAILY">Daily</option>
            <option value="WEEKLY">Weekly</option>
            <option value="MONTHLY">Monthly</option>
          </Select>
        </ModalFormLabel>
        <ModalFormLabel htmlFor="description">
          Description
          <Textarea
            ref={register}
            error={!!errors.description}
            maxLength={250}
            name="description"
            placeholder="Add details to the task"
          />
        </ModalFormLabel>
        <Submit type="submit">{submitButtonMsg}</Submit>
      </Form>
    </Container>
  );
};

export default TaskForm;
