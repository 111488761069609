import { gql } from "apollo-boost";

export const GET_BUDGET = gql`
  query MarketingBudget($year: Int!) {
    marketingBudget(year: $year) {
      availableYears
      id
      month {
        items {
          amount
          name
        }
        monthlyMarketingExpenses {
          amount
          name
        }
        total
      }
      year {
        budgeted
        total
      }
      yearlyOneOffExpenses {
        items {
          amount
          date
          name
        }
        total
      }
      marketingExpenses {
        items {
          amount
          name
        }
        monthlySinkingFund {
          amount
          name
        }
        total
      }
      marketingSinkingFund {
        months {
          id
          project
        }
        monthlyBudget
      }
    }
  }
`;

export interface GetBudgetData {
  marketingBudget?: {
    availableYears?: number[];
    month?: {
      items?: Array<{
        amount: number;
        name: string;
      }>;
      monthlyMarketingExpenses?: {
        amount: number;
        name: string;
      };
      total?: number;
    };
    id?: string;
    marketingExpenses?: {
      items?: Array<{
        amount: number;
        name: string;
      }>;
      monthlySinkingFund?: {
        amount: number;
        name: string;
      };
      total?: number;
    };
    marketingSinkingFund?: {
      months?: Array<{
        id: number;
        project: string;
      }>;
      monthlyBudget?: number;
    };
    year?: {
      budgeted?: number;
      total?: number;
    };
    yearlyOneOffExpenses?: {
      items?: Array<{
        amount: number;
        date: string;
        name: string;
      }>;
      total?: number;
    };
  };
}

export interface GetBudgetVariables {
  year: number;
}
