import { gql } from "apollo-boost";

export const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      active
      firstName
      id
      lastName
      logoUrl
      practiceName
      userType
      verified
    }
    isAdmin
  }
`;

export interface CurrentUser {
  active?: boolean;
  firstName?: string;
  id?: number;
  lastName?: string;
  logoUrl?: string;
  practiceName?: string;
  userType?: string;
  verified?: boolean;
}

export interface CurrentUserData {
  currentUser?: CurrentUser;
  isAdmin?: boolean;
}

export const LOGOUT = gql`
  mutation Logout {
    logout {
      code
      message
    }
  }
`;
