import styled from "@emotion/styled";

const ContentContainer = styled.div`
  display: flex;
  margin-top: 8rem;
  overflow: scroll;
  height: 100%;
  max-width: 75rem;

  & > * {
    width: 20rem;
    flex: 0 0 auto;
    margin-right: 1.5rem;
  }
`;

export default ContentContainer;
