import { gql } from "apollo-boost";
import { Network, NetworkList } from "./types";

export const CREATE_NETWORK_LIST = gql`
  mutation CreateNetworkList($name: String!) {
    createNetworkList(name: $name) {
      code
      message
    }
  }
`;

export interface CreateNetworkListVariables {
  name: string;
}

export const ADD_NETWORK_ENTRY = gql`
  mutation AddNetworkEntry(
    $contact: String
    $lastCheckIn: String
    $list: NetworkList!
    $myNetworkListId: ID!
    $name: String!
    $notes: String
    $profession: String
  ) {
    addNetworkEntry(
      contact: $contact
      lastCheckIn: $lastCheckIn
      list: $list
      myNetworkListId: $myNetworkListId
      name: $name
      notes: $notes
      profession: $profession
    ) {
      code
      message
    }
  }
`;

export interface AddNetworkEntryVariables {
  contact?: string;
  lastCheckIn?: string;
  list: NetworkList;
  myNetworkListId: string;
  name: string;
  notes?: string;
  profession?: string;
}

export const DELETE_NETWORK_ENTRY = gql`
  mutation DeleteNetworkEntry($id: ID!, $myNetworkListId: ID!) {
    deleteNetworkEntry(id: $id, myNetworkListId: $myNetworkListId) {
      code
      message
    }
  }
`;

export interface DeleteNetworkEntryVariables {
  id: string;
  myNetworkListId: string;
}

export const GET_MY_NETWORK = gql`
  query GetMyNetwork {
    myNetwork {
      id
      name
      potentialReferring {
        contact
        id
        lastCheckIn
        name
        notes
        profession
      }

      referring {
        contact
        id
        lastCheckIn
        name
        notes
        profession
      }
    }
  }
`;

export interface GetMyNetworkData {
  myNetwork?: {
    id?: string;
    name?: string;
    potentialReferring?: Network[];
    referring?: Network[];
  }[];
}

export const EDIT_NETWORK_ENTRY = gql`
  mutation EditNetworkEntry(
    $contact: String
    $id: ID!
    $lastCheckIn: String
    $name: String!
    $notes: String
    $profession: String
  ) {
    editNetworkEntry(
      contact: $contact
      id: $id
      lastCheckIn: $lastCheckIn
      name: $name
      notes: $notes
      profession: $profession
    ) {
      code
      message
    }
  }
`;

export interface EditNetworkEntryVariables {
  contact?: string;
  id: string;
  lastCheckIn?: string;
  name: string;
  notes?: string;
  profession?: string;
}

export const REORDER_NETWORK = gql`
  mutation ReorderNetwork(
    $destination: MyNetworkReorderInput!
    $id: ID!
    $myNetworkListId: ID!
    $source: MyNetworkReorderInput!
  ) {
    reorderMyNetwork(
      destination: $destination
      id: $id
      myNetworkListId: $myNetworkListId
      source: $source
    ) {
      code
      message
    }
  }
`;

export interface ReorderNetworkVariables {
  destination: { index: number; droppableId: NetworkList };
  id: string;
  myNetworkListId: string;
  source: { index: number; droppableId: NetworkList };
}
