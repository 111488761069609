import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import IconButton from "./IconButton";

const DeleteButton: FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <IconButton aria-label="Delete" {...props}>
    <FontAwesomeIcon icon={faMinusCircle} />
  </IconButton>
);

export default DeleteButton;
