import React, { FC } from "react";
import CSADescription from "../components/CSADescription";
import CSALink from "../components/CSALink";
import CenteredContainer from "../components/CenteredContainer";
import HomeImageLink from "../components/HomeImageLink";

const About: FC = () => (
  <CenteredContainer>
    <h1>About</h1>

    <HomeImageLink />

    <h2>Google OAuth2</h2>
    <p>
      <b>What will this app do with user data?:</b>&nbsp;CSA Digital Dashboard
      will not use data from Google. It will only add events to user&apos;s
      google calendar if they choose to link their calendar.
    </p>

    <p>
      <b>How does this app enhance user functionality?</b>&nbsp;Users will be
      able to have events added in the app automatically sync with their google
      calendar so they will not have to create duplicate events across multiple
      calendars.
    </p>

    <p>
      <CSALink to="/privacy-policy">Privacy Policy</CSALink>
    </p>

    <h2>About the Dashboard</h2>
    <CSADescription />
  </CenteredContainer>
);

export default About;
