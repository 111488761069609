import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { FC, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import CSAButton from "../components/CSAButton";
import CSALink from "../components/CSALink";
import CenteredContainer from "../components/CenteredContainer";
import HomeImageLink from "../components/HomeImageLink";
import { Response } from "../types";

const GET_GOOGLE_OAUTH_TOKENS = gql`
  mutation GetGoogleOAuthTokens($code: String!) {
    getGoogleOAuthTokens(code: $code) {
      code
      message
    }
  }
`;

interface GetGoogleOAuthTokensData {
  getGoogleOAuthTokens: Response;
}

interface GetGoogleOAuthTokensVariables {
  code: string;
}

const ContinueButton = CSAButton.withComponent(CSALink);

const FailureBody: FC = () => (
  <CenteredContainer>
    <HomeImageLink />
    <h1>Sorry we couldn&apos;t link your Google Calendar account</h1>
    <p>Please try again later</p>
    <ContinueButton to="/events-calendar"> Back to Calendar</ContinueButton>
  </CenteredContainer>
);

const GoogleOAuthCallback: FC = () => {
  const history = useHistory();

  const [getGoogleOAuthTokens, { error, loading }] = useMutation<
    GetGoogleOAuthTokensData,
    GetGoogleOAuthTokensVariables
  >(GET_GOOGLE_OAUTH_TOKENS, {
    onCompleted: () =>
      history.replace("/events-calendar", { googleAuthorized: true }),
  });

  const params = new URLSearchParams(useLocation().search);
  const code = decodeURIComponent(params.get("code") ?? "");

  useEffect(() => {
    if (getGoogleOAuthTokens) {
      getGoogleOAuthTokens({ variables: { code } });
    }
  }, [getGoogleOAuthTokens, code]);

  if (loading) {
    return null;
  }

  if (error) {
    return <FailureBody />;
  }

  return null;
};

export default GoogleOAuthCallback;
