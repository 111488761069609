import styled from "@emotion/styled";
import React, { FC } from "react";

const Aside = styled.aside`
  max-width: 48rem;
  text-align: center;
`;

export interface DescriptionProps {
  className?: string;
}

const Description: FC<DescriptionProps> = ({ className }) => (
  <Aside className={className}>
    <p>
      Imagine having a single place to track and manage your progress as a DC.
      The CSA Digital Dashboard provides you with all of the variables to make
      accurate decisions to grow your practice while all located in a secure
      online portal that is branded for your practice.
    </p>

    <ul>
      <p>
        The Following Dashboard Tools will help you grow and manage your
        practice
      </p>
      <li>Practice Analytics Tracker</li>
      <li>Marketing Planner</li>
      <li>Editorial Calendar</li>
      <li>Events Calendar</li>
      <li>Marketing Budgeter</li>
      <li>Indicator Scorecard</li>
      <li>The Referral Directory</li>
      <li>The New Patient Log</li>
    </ul>
  </Aside>
);

export default Description;
